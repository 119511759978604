import {
  Button,
  Flex,
  Grid,
  Icon,
  IconButton,
  Image,
  Text,
} from '@chakra-ui/react';

import { BackLink, CloseIcon, useResponsive } from '@arena-labs/strive2-ui';
import { StrivewareContext } from '@strive/wearable';

import { useUltimatelyReadyToSync } from '../../device-content';
import { useSetupActor } from './hooks';

export function SetupSuccess({ onClose }: { onClose: () => void }) {
  const setupActor = useSetupActor();
  const strivewareActor = StrivewareContext.useActorRef();
  const rs = useResponsive();
  const [hasViewedSuccess, setHasViewedSuccess] = useUltimatelyReadyToSync();

  const handleSubmit = () => {
    setHasViewedSuccess(true);
    setTimeout(() => {
      strivewareActor.send({ type: 'Sync' });
    }, 330);
  };

  return (
    <Flex direction="column" gap="6" h="full" align="center" pt={4}>
      <Grid
        templateColumns="auto 1fr auto"
        alignItems="center"
        gap="4"
        w="full"
        px={4}
      >
        <BackLink
          href="/"
          color="neutral.800"
          onClick={(event) => {
            event.preventDefault();
            setupActor.send({ type: 'Back' });
          }}
        />
        <Text textStyle={'copy_bold'} textAlign={'center'}>
          Pair StriveWare
        </Text>
        <IconButton
          aria-label="Close"
          icon={<Icon as={CloseIcon} boxSize={6} />}
          onClick={() => onClose()}
        />
      </Grid>
      <Image
        src="/images/striveware/black-ring-grey-light.webp"
        w={rs({ base: '250px', xs: '180px' })}
        alt="Biometric Ring"
        my="auto"
      />

      <Flex
        direction="column"
        layerStyle="4dp"
        zIndex="1"
        gap={rs({ base: '6', xs: '3' })}
        bg="white"
        color="black"
        w="full"
        rounded={'card'}
        px="6"
        pt="4"
        pb="6"
        // mt="auto"
        mb={-2}
        align="center"
      >
        <Image
          src="/images/striveware/rocket.gif"
          alt=""
          w="60px"
          transform="rotate(38deg)"
        />
        <Text textStyle="h3">All Set!</Text>

        <Text
          px={6}
          textStyle="copy"
          textAlign={'center'}
          w={'full'}
          color="neutral.800"
        >
          Now, make sure you sleep with your StriveWare to generate insights.
        </Text>

        <Button
          variant="primary"
          w="full"
          onClick={handleSubmit}
          isLoading={hasViewedSuccess}
          loadingText="Preparing to Sync..."
          mb={4}
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  );
}
