import { createStandaloneToast } from '@chakra-ui/toast';

import { theme } from '../theme/theme';

const { toast, ToastContainer } = createStandaloneToast({
  theme,
  defaultOptions: {
    variant: 'toast',
    isClosable: true,
    containerStyle: {
      zIndex: 100000, // Set z-index higher than SupportHub's 9999
      width: '90vw',
      marginBottom: '40px',
    },
  },
});

export { toast, ToastContainer };
