import {
  Box,
  Flex,
  Grid,
  Icon,
  IconButton,
  Image,
  RadioProps,
  Text,
  useRadioGroup,
} from '@chakra-ui/react';
import { useSelector } from '@xstate5/react';

import {
  BackLink,
  CloseIcon,
  RadioButton,
  useResponsive,
  ZStack,
} from '@arena-labs/strive2-ui';

import { useDiscoveredDevices, useSetupActor } from './hooks';
import { SetupNoDevices } from './setup-no-devices';

export function SetupMultipleDevices({ onClose }: { onClose: () => void }) {
  const devices = useDiscoveredDevices();
  const rs = useResponsive();
  const setupActor = useSetupActor();
  const isPairing = useSelector(setupActor, (state) => state.context.isPairing);

  const { getRadioProps } = useRadioGroup({
    name: 'deviceId',
    onChange: async (deviceId) => {
      const device = devices.get(deviceId);
      if (!device) {
        console.error('MULTDEVICES ❌ Device not found:', deviceId);
        return;
      }

      setupActor.send({ type: 'Pair', device });
    },
  });

  if (devices.size === 0) {
    return <SetupNoDevices onClose={onClose} />;
  }

  return (
    <Flex direction="column" gap="6" h="full" align="center">
      <Grid
        templateColumns="auto 1fr auto"
        alignItems="center"
        gap="4"
        w="full"
        px={4}
      >
        <BackLink
          href="/"
          color="neutral.800"
          onClick={(event) => {
            event.preventDefault();
            setupActor.send({ type: 'Back' });
          }}
        />
        <Text textStyle={'copy_bold'} textAlign={'center'}>
          Pair StriveWare
        </Text>
        <IconButton
          aria-label="Close"
          icon={<Icon as={CloseIcon} boxSize={6} />}
          onClick={() => onClose()}
        />
      </Grid>
      <ZStack w={'full'} h={'full'}>
        <Image
          src="/images/striveware/black-ring-grey-light.webp"
          w={rs({ base: '250px', xs: '180px' })}
          alt="Biometric Ring"
          mt="25%"
          mx={'auto'}
        />

        <Flex
          direction="column"
          layerStyle="4dp"
          zIndex="1"
          gap={rs({ base: '6', xs: '3' })}
          bg="white"
          color="black"
          w="full"
          rounded={'card'}
          px="6"
          pt="4"
          pb="6"
          mb={-2}
          align="center"
          mt="auto"
          sx={{
            label: {
              width: '100%',
            },
          }}
        >
          <Image
            src="/images/striveware/rocket.gif"
            alt=""
            w="60px"
            transform="rotate(38deg)"
          />
          <Box mx={'auto'} w={'auto'} textAlign="center" width="100%">
            <Text textStyle="h3">Multiple StriveWare Found!</Text>
          </Box>
          {/* //new boxes */}
          <Box mx={'auto'} w={'auto'} textAlign="center" width="100%">
            <Text textStyle="copy">Select one to continue.</Text>
          </Box>

          <Flex w={'full'} flexDirection={'column'} gap={2} pb={6}>
            {Array.from(devices).map(([deviceId, device]) => (
              <RadioButton
                key={deviceId}
                variant="pairing"
                w="full"
                opacity={isPairing ? 0.5 : 1}
                pointerEvents={isPairing ? 'none' : 'auto'}
                {...(getRadioProps({ value: deviceId }) as RadioProps)}
              >
                Device Name: {device.name}
              </RadioButton>
            ))}
          </Flex>
        </Flex>
      </ZStack>
    </Flex>
  );
}
