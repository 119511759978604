import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  IconButton,
  Image,
  Text,
} from '@chakra-ui/react';
import { useSelector } from '@xstate5/react';

import { BackLink, CloseIcon, useResponsive } from '@arena-labs/strive2-ui';

import { useDiscoveredDevices, useSetupActor } from './hooks';
import { SetupNoDevices } from './setup-no-devices';

export function SetupSingleDevice({ onClose }: { onClose: () => void }) {
  const rs = useResponsive();
  const devices = useDiscoveredDevices();
  const setupActor = useSetupActor();
  const isPairing = useSelector(setupActor, (state) => state.context.isPairing);

  const canShowAll = useSelector(setupActor, (state) =>
    state.can({ type: 'Show all' }),
  );

  const firstDevice = devices.get([...devices.keys()][0] ?? '');

  if (!firstDevice) return <SetupNoDevices onClose={onClose} />;

  const handleSubmit = () => {
    setupActor.send({ type: 'Pair', device: firstDevice });
  };

  return (
    <Flex direction="column" gap="6" h="full" align="center" pt={4}>
      <Grid
        templateColumns="auto 1fr auto"
        alignItems="center"
        gap="4"
        w="full"
        px={4}
      >
        <BackLink
          href="/"
          color="neutral.800"
          onClick={(event) => {
            event.preventDefault();
            setupActor.send({ type: 'Back' });
          }}
        />
        <Text textStyle={'copy_bold'} textAlign={'center'}>
          Pair StriveWare
        </Text>
        <IconButton
          aria-label="Close"
          icon={<Icon as={CloseIcon} boxSize={6} />}
          onClick={() => onClose()}
        />
      </Grid>

      <Image
        src="/images/striveware/black-ring-grey-light.webp"
        w={rs({ base: '250px', xs: '180px' })}
        alt="Biometric Ring"
        my="auto"
      />

      <Flex
        direction="column"
        layerStyle="4dp"
        zIndex="1"
        gap={rs({ base: '6', xs: '3' })}
        bg="white"
        color="black"
        w="full"
        rounded={'card'}
        px="6"
        pt="4"
        pb="6"
        mb={-2}
        align="center"
      >
        <Image
          src="/images/striveware/rocket.gif"
          alt=""
          w="60px"
          transform="rotate(38deg)"
        />
        <Text textStyle="h3">StriveWare Located!</Text>
        <Box textAlign={'left'} w={'full'}>
          <Text
            textStyle="copy"
            textAlign={'center'}
            w={'full'}
            color="neutral.800"
            mb={'2'}
            mr={'auto'}
          >
            Device Name{' '}
            <Text as="span" textStyle="copy" color="neutral.600">
              &nbsp; {firstDevice?.name}
            </Text>
          </Text>
        </Box>
        {canShowAll ? (
          <Button
            variant="link"
            disabled={isPairing}
            onClick={() => setupActor.send({ type: 'Show all' })}
          >
            Show all devices
          </Button>
        ) : null}

        <Button variant="primary" w="full" onClick={handleSubmit} mb={4}>
          {isPairing ? 'Pairing...' : 'Continue'}
        </Button>
      </Flex>
    </Flex>
  );
}
