import { makeApi, ZodiosResponseByAlias } from '@zodios/core';
import { z } from 'zod';

import {
  Depaginate,
  paginated,
  paginationParams,
  zIsoDateTime,
} from '../lib/schema-utils';
import {
  aciReflectionResponseSchema,
  // aciReflectionResponseSchema,
  ACIScoreSchema,
  ACIValueSchema,
  AutomatedMessagesResponseSchema,
  biometricHistoryEntrySchema,
  coachNoteCreateSchema,
  coachNoteSchema,
  contentProgressSchema,
  evaluationSchema,
  hrvHistorySchema,
  // hrvHistorySchema,
  intakeSchema,
  professionalFulfillmentSchema,
  sensorDataSchema,
  sleepConsistencyHistorySchema,
  // sleepConsistencyHistoryItemSchema,
  toolCompletionHistoryResponseSchema,
} from '../schemas/coach-dashboard-types';
import { learningStateSchema, streakSchema } from './user-profile';

export type CoachUserResult = Depaginate<
  ZodiosResponseByAlias<typeof coachDashboardApi, 'searchUsers'>
>;

export const coachDashboardApi = makeApi([
  {
    alias: 'getProfileSummary',
    description: 'Get profile summary',
    method: 'get',
    path: '/coach/profile-summary/:id/',
    parameters: [
      {
        type: 'Path',
        name: 'id',
        schema: z.number().or(z.string()),
        description: 'Profile id, or stream channel name',
      },
    ],
    response: z.object({
      id: z.number(),
      stream_username: z.string().nullable(),
      channel_name_with_coach: z.string(),
    }),
  },
  //Filter/search Routes
  {
    alias: 'getTeams',
    description: 'Get all teams',
    method: 'get',
    path: '/coach/teams/',
    parameters: [...paginationParams],
    response: paginated(
      z.object({
        id: z.number(),
        name: z.string(),
      }),
    ),
  },
  {
    alias: 'searchUsers',
    description: 'Search for users',
    method: 'get',
    path: '/coach/search/',
    parameters: [
      {
        type: 'Query',
        name: 'query',
        schema: z.string().optional().nullable(),
      },
      { type: 'Query', name: 'team', schema: z.string().optional().nullable() },
      ...paginationParams,
    ],
    response: paginated(
      z.object({
        id: z.number().nullable(),
        stream_username: z.string().nullable(),
        channel_name_with_coach: z.string().nullable(),
        channel_name_with_launchpad: z.string().nullable(),
        learning_state: learningStateSchema,
        last_login: zIsoDateTime.nullable(),
      }),
    ),
  },

  //Launchpad Routes
  {
    alias: 'getProfileLaunchpadSummary',
    description: 'Get profile launchpad summary',
    method: 'get',
    path: '/launchpad-guide/:id/',
    parameters: [
      {
        type: 'Path',
        name: 'id',
        schema: z.number().or(z.string()),
        description: 'Profile id, or stream channel name',
      },
    ],
    response: z.object({
      first_name: z.string(),
      last_name: z.string(),
      cohort_launch_at: zIsoDateTime,
      launch_events: z.string(),
      team_name: z.string(),
      team_dashboard: z.object({
        pfi_complete_count: z.number(),
        wearable_synced_count: z.number(),
        ring_size_entered_count: z.number(),
        ring_on_order_count: z.number(),
        playlist_watched_count: z.number(),
        coach_expectations_complete_count: z.number(),
      }),
      checklist: z.array(
        z.object({
          slug: z.string(),
          status: z.enum(['locked', 'active', 'skipped', 'completed']),
          label: z.string(),
          completed_at: zIsoDateTime.nullable(),
          children: z
            .array(
              z.object({
                slug: z.string(),
                status: z.enum(['locked', 'active', 'skipped', 'completed']),
                label: z.string(),
                completed_at: zIsoDateTime.nullable(),
              }),
            )
            .nullable()
            .catch(null),
        }),
      ),
    }),
  },

  // Leader Routes
  {
    alias: 'getLeaderProfiles',
    description: 'Get profiles list for leader',
    method: 'get',
    path: '/coach/leadership/',
    parameters: [
      {
        type: 'Query',
        name: 'limit',
        schema: z.number().optional().nullable(),
      },
      {
        type: 'Query',
        name: 'offset',
        schema: z.number().optional().nullable(),
      },
    ],
    response: paginated(
      z.object({
        biometric: z.object({
          baseline: z.object({
            hours_of_sleep: z
              .number()
              .transform((number) => number / (1000 * 60 * 60)),
            hrv: z.number(),
            rhr: z.number(),
          }),
          hours_of_sleep: z.array(biometricHistoryEntrySchema),
          hrv: z.array(biometricHistoryEntrySchema),
          rhr: z.array(biometricHistoryEntrySchema),
        }),
        aci: z
          .object({
            aggregate: z.object({
              score: ACIValueSchema,
              energy: ACIValueSchema,
              stress: ACIValueSchema,
              focus: ACIValueSchema,
            }),
            history: z.array(ACIScoreSchema),
          })
          .nullable()
          .catch(null),
        id: z.number(),
        completed_percentage: z.number(),
        learning_journey_start: zIsoDateTime.nullable(),
        last_login: zIsoDateTime.nullable(),
        end_date: zIsoDateTime.nullable(),
        goal: z.number().nullable(),
        goal_text: z.string().nullable(),
        score: z.number(),
        last_practice: z.string().nullable(),
        last_learning: z.string().nullable(),
        sleep: z
          .object({
            hours_of_sleep: sensorDataSchema,
            efficiency: sensorDataSchema,
            rem: sensorDataSchema,
            sws: sensorDataSchema,
          })
          .nullable()
          .catch(null),
        hrv: sensorDataSchema.nullable(),
        rhr: sensorDataSchema.nullable(),
        professional_fulfillment: professionalFulfillmentSchema.nullable(),
        burnout_propensity: evaluationSchema.nullable(),
        self_valuation: evaluationSchema.nullable(),
        streak: streakSchema,
        strive_data: z
          .object({
            date: zIsoDateTime,
            awareness: z.number(),
            regeneration: z.number(),
            adaptation: z.number(),
            avg_awareness: evaluationSchema.nullable(),
            avg_regeneration: evaluationSchema.nullable(),
            avg_adaptation: evaluationSchema.nullable(),
          })
          .nullable()
          .catch(null),
      }),
    ),
  },

  //Automated Messages Routes
  {
    alias: 'sendAutomatedMessages',
    description: `Run automated messages script`,
    method: 'post',
    path: '/coach/message/automated/',
    parameters: [
      {
        type: 'Body',
        name: 'Data',
        schema: z.object({
          type: z.enum(['launchpad', 'coachportal']),
        }),
      },
    ],
    response: AutomatedMessagesResponseSchema,
  },

  //Coach Portal User Data Routes
  {
    alias: 'getIntakeDetails',
    description: 'Get profile details for a specific user',
    method: 'get',
    path: '/coach/profile-summary-new/:id/details/',
    parameters: [
      {
        type: 'Path',
        name: 'id',
        schema: z.number().or(z.string()),
        description: 'Profile id',
      },
    ],
    response: intakeSchema,
  },
  {
    alias: 'getContentProgress',
    description: 'Get content progress for a specific user',
    method: 'get',
    path: '/coach/profile-summary-new/:id/progress/',
    parameters: [
      {
        type: 'Path',
        name: 'id',
        schema: z.number().or(z.string()),
        description: 'Profile id',
      },
    ],
    response: contentProgressSchema,
  },
  {
    alias: 'getToolCompletionHistory',
    description: 'Get tool completion history for a specific user',
    method: 'get',
    path: '/coach/profile-summary-new/:id/practices/',
    parameters: [
      {
        type: 'Path',
        name: 'id',
        schema: z.number().or(z.string()),
        description: 'Profile id',
      },
      ...paginationParams,
    ],
    response: toolCompletionHistoryResponseSchema,
  },
  {
    alias: 'getACIReflections',
    description: 'Get ACI reflections history for a specific user',
    method: 'get',
    path: '/coach/profile-summary-new/:id/aci2/',
    parameters: [
      {
        type: 'Path',
        name: 'id',
        schema: z.number().or(z.string()),
        description: 'Profile id',
      },
      ...paginationParams,
    ],
    response: aciReflectionResponseSchema,
  },
  {
    alias: 'getHRVHistory',
    description: 'Get HRV history data for a profile',
    method: 'get',
    path: '/coach/profile-summary-new/:id/hrv/',
    parameters: [
      {
        type: 'Path',
        name: 'id',
        schema: z.number().or(z.string()),
        description: 'Profile id, or stream channel name',
      },
      ...paginationParams,
    ],
    response: hrvHistorySchema,
  },

  {
    alias: 'getSleepConsistencyHistory',
    description: 'Get sleep consistency history data for a profile',
    method: 'get',
    path: '/coach/profile-summary-new/:id/sleep/',
    parameters: [
      {
        type: 'Path',
        name: 'id',
        schema: z.number().or(z.string()),
        description: 'Profile id, or stream channel name',
      },
      ...paginationParams,
    ],
    response: sleepConsistencyHistorySchema,
  },

  // Coach Portal Notes Routes
  {
    alias: 'getCoachNotes',
    description: 'Get notes for a specific profile',
    method: 'get',
    path: '/coach/notes/',
    parameters: [
      {
        type: 'Query',
        name: 'profile_id',
        schema: z.number(),
        description: 'Profile ID to get notes for',
      },
    ],
    response: paginated(coachNoteSchema),
  },
  {
    alias: 'createCoachNote',
    description: 'Create a new note for a profile',
    method: 'post',
    path: '/coach/notes/',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: coachNoteCreateSchema,
      },
    ],
    response: z.any(),
  },
  {
    alias: 'getCoachNote',
    description: 'Get a specific note',
    method: 'get',
    path: '/coach/notes/:id/',
    parameters: [
      {
        type: 'Path',
        name: 'id',
        schema: z.number(),
        description: 'Note ID',
      },
    ],
    response: coachNoteSchema,
  },
  {
    alias: 'updateCoachNote',
    description: 'Update a specific note',
    method: 'put',
    path: '/coach/notes/:id/',
    parameters: [
      {
        type: 'Path',
        name: 'id',
        schema: z.number(),
        description: 'Note ID',
      },
      {
        type: 'Body',
        name: 'data',
        schema: z.object({
          message: z.string(),
          tags: z.array(z.string()),
        }),
      },
    ],
    response: z.any(),
  },
  {
    alias: 'deleteCoachNote',
    description: 'Delete a specific note',
    method: 'delete',
    path: '/coach/notes/:id/',
    parameters: [
      {
        type: 'Path',
        name: 'id',
        schema: z.number(),
        description: 'Note ID',
      },
    ],
    response: z.any(),
  },

  {
    alias: 'getMessageSuggestions',
    description: 'Get AI generated message suggestions for a profile',
    method: 'post',
    path: '/coach/message-suggestions/',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: z.object({
          profile_id: z.number(),
        }),
      },
    ],
    response: z.any(),
  },
]);
