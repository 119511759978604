import { useEffect, useState } from 'react';
import {
  Button,
  Fade,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
} from '@chakra-ui/react';

import { GeneralTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import {
  HeadsetIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
} from '@arena-labs/strive2-ui';

import { toggleZendeskChat } from '../../../../../lib/zendesk';

export function FeedbackContent({ articleTitle }: { articleTitle: string }) {
  const [currentView, setCurrentView] = useState<
    'initial' | 'thanks' | 'questions'
  >('initial');
  const [isVisible, setIsVisible] = useState(true);

  const analytics = useAnalytics();

  // Handle view transitions with fade effect
  const changeView = (newView: 'initial' | 'thanks' | 'questions') => {
    setIsVisible(false);
    setTimeout(() => {
      setCurrentView(newView);
      setIsVisible(true);
    }, 200); // Match this with the Fade transition duration
  };

  // Ensure component is visible on first render
  useEffect(() => {
    setIsVisible(true);
  }, []);

  // Reset feedback state when article title changes
  useEffect(() => {
    setCurrentView('initial');
    setIsVisible(true);
  }, [articleTitle]);

  return (
    <Fade
      in={isVisible}
      transition={{ enter: { duration: 0.2 }, exit: { duration: 0.2 } }}
    >
      <Flex mt={8} direction="column" width="full">
        {currentView === 'thanks' ? (
          <Text textStyle="h5" color="neutral.800">
            Thanks for letting us know!
          </Text>
        ) : currentView === 'questions' ? (
          <>
            <Text textStyle="h5" color="neutral.800" mb={6}>
              More questions?
            </Text>

            <Button
              variant="primary"
              onClick={() => toggleZendeskChat(true)}
              leftIcon={<Icon as={HeadsetIcon} boxSize={5} />}
              width="full"
            >
              Chat with us
            </Button>
          </>
        ) : (
          // Initial view
          <Flex justifyContent="space-between" alignItems="center" width="full">
            <Text textStyle="copy_bold" color="neutral.800">
              Did this article help?
            </Text>

            <HStack spacing={4}>
              <IconButton
                aria-label="Yes, this article helped"
                icon={<ThumbsUpIcon />}
                onClick={() => {
                  changeView('thanks');
                  analytics.logEvent(
                    GeneralTrackingEvent.StriveSupportArticleFeedback,
                    {
                      feedback: 'positive',
                      striveSupportArticle: articleTitle,
                    },
                  );
                }}
                borderRadius="full"
                bg="neutralAlpha.50"
                size="md"
              />
              <IconButton
                aria-label="No, this article didn't help"
                icon={<ThumbsDownIcon />}
                onClick={() => {
                  changeView('questions');
                  analytics.logEvent(
                    GeneralTrackingEvent.StriveSupportArticleFeedback,
                    {
                      feedback: 'negative',
                      striveSupportArticle: articleTitle,
                    },
                  );
                }}
                borderRadius="full"
                bg="neutralAlpha.50"
                size="md"
              />
            </HStack>
          </Flex>
        )}
      </Flex>
    </Fade>
  );
}
