import { Capacitor } from '@capacitor/core';

import { UserProfile } from '@arena-labs/shared-models';

declare global {
  interface Window {
    zE?: any;
    $zopim?: any;
  }
}

// This function will be called when the script loads
const initializeZendesk = (user?: UserProfile) => {
  const script = document.createElement('script');
  script.id = 'ze-snippet';
  script.src =
    // 'https://static.zdassets.com/ekr/snippet.js?key=1f16203b-11c5-403a-9d93-3891912cb932';
    'https://static.zdassets.com/ekr/snippet.js?key=5ab282f5-bc41-4374-9be2-1761aa884fa7';
  script.async = true;

  script.onload = () => {
    if (typeof window !== 'undefined' && window.zE) {
      // Only set user data if we have a user
      if (user) {
        // Set user identity
        window.zE('messenger:set', 'zdCustomer', {
          name: `${user.first_name} ${user.last_name}`,
          email: user.email || '',
        });

        // Get platform info from Capacitor
        const platform = Capacitor.getPlatform();
        const deviceName =
          platform === 'ios'
            ? 'iPhone'
            : platform === 'android'
            ? 'Android'
            : 'Web';

        // Get wearable provider info
        const wearableProvider = user.profile.wearable[0]?.provider;

        // Set ticket-specific fields
        window.zE('messenger:set', 'conversationFields', [
          { id: '32482478649492', value: user.email }, // user_email
          { id: '32480679644436', value: String(user.id) }, // user_id
          { id: '33624674116116', value: deviceName }, // device_name
          { id: '32929521173652', value: wearableProvider }, // wearable provider
        ]);
      }
    }
  };

  document.head.appendChild(script);
};

// Initialize Zendesk if we're in the browser
if (typeof window !== 'undefined') {
  // Remove any existing Zendesk scripts to prevent duplicates
  const existingScript = document.getElementById('ze-snippet');
  if (existingScript) {
    existingScript.remove();
  }

  // We need to import and use the hook here for SSR compatibility
  const userModule = {
    initWithUser: (user?: UserProfile) => {
      initializeZendesk(user);
    },
  };

  // Export the module for use elsewhere
  (window as any).__zendeskModule = userModule;
}

export const initZendeskWithUser = (user: UserProfile) => {
  if (typeof window !== 'undefined') {
    const userModule = (window as any).__zendeskModule;
    if (userModule) {
      userModule.initWithUser(user);
    }
  }
};

export const toggleZendeskChat = (show = true) => {
  if (typeof window !== 'undefined' && window.zE) {
    if (show) {
      window.zE('messenger', 'open');

      const viewport = document.querySelector('meta[name=viewport]');
      if (viewport) {
        viewport.setAttribute(
          'content',
          'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover, shrink-to-fit=no',
        );
      }
    } else {
      window.zE('messenger', 'hide');

      // Reset viewport when manually closing chat
      const viewport = document.querySelector('meta[name=viewport]');
      if (viewport) {
        viewport.setAttribute(
          'content',
          'width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover, user-scalable=yes, maximum-scale=5',
        );
      }
    }
  }
};
