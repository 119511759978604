export async function openSettingsPage() {
  const { NativeSettings, AndroidSettings, IOSSettings } = await import(
    'capacitor-native-settings'
  );
  await NativeSettings.open({
    optionIOS: IOSSettings.App,
    optionAndroid: AndroidSettings.ApplicationDetails,
  });
}

export async function openBluetoothSettings() {
  const { NativeSettings, AndroidSettings, IOSSettings } = await import(
    'capacitor-native-settings'
  );
  await NativeSettings.open({
    optionIOS: IOSSettings.Bluetooth,
    optionAndroid: AndroidSettings.Bluetooth,
  });
}
