import { useMutation, useQueryClient } from '@tanstack/react-query';
import { clamp } from 'lodash-es';

import { authClient } from '@strive/api';

// TODO: Move all this to zodios

export function useMediaPlayMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    async ([type, slug, progress]: [
      type: 'video' | 'audio',
      slug: string,
      progress?: number,
    ]) => reportContentPlayed(type, slug, progress),
    {
      onSettled: () => {
        queryClient.invalidateQueries();
      },
      retry: 5,
    },
  );
}

function reportContentPlayed(
  type: 'video' | 'audio',
  slug: string,
  progress = 0,
): Promise<unknown> {
  if (type === 'video') {
    return authClient.post(`/content/video_view/`, {
      video: slug,
      progress: clamp(progress, 0, 100),
    });
  } else if (type === 'audio') {
    return authClient.post(`/content/audio_play/`, {
      audio: slug,
      progress: clamp(progress, 0, 100),
    });
  }
  return Promise.reject('Invalid type');
}

// "Watched" flag
export function useVideoViewMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    async ([slug, progress]: [string, number]) =>
      reportContentPlayed('video', slug, progress),
    {
      onSettled: () => {
        queryClient.invalidateQueries();
      },
    },
  );
}

export function useAudioPlayMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    ([slug, progress]: [string, number]) =>
      reportContentPlayed('audio', slug, progress),
    {
      onSettled: () => {
        queryClient.invalidateQueries();
      },
    },
  );
}
