import React, { useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  chakra,
  Flex,
  Icon,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

import {
  ArenaLogo,
  ChevronRightIcon,
  useResponsive,
} from '@arena-labs/strive2-ui';

interface UnpairDeviceProps {
  onUnpair: () => void;
  isUnpairing?: boolean;
}

export function UnpairDevice({
  onUnpair,
  isUnpairing = false,
}: UnpairDeviceProps) {
  const [isOpen, setIsOpen] = useState(false);
  const rs = useResponsive();

  return (
    <Accordion
      allowToggle
      index={isOpen ? 0 : -1}
      onChange={() => setIsOpen(!isOpen)}
    >
      <AccordionItem border="none">
        <AccordionButton
          p={0}
          textStyle={rs({ base: 'button', xs: 'copy_small' })}
          display="inline-flex"
          justifyContent="space-between"
          alignItems="center"
          fontWeight={'md'}
          _hover={{ bg: 'transparent' }}
          disabled={isUnpairing}
        >
          Unpair Device
          <ChevronRightIcon
            style={{
              transform: isOpen ? 'rotate(270deg)' : 'rotate(90deg)',
              transition: 'transform 0.2s ease-in-out',
              width: '12px',
              height: '12px',
            }}
          />
        </AccordionButton>
        <AccordionPanel pt={6} pb={0} px={0}>
          <chakra.div
            display="flex"
            flexDirection="column"
            gap="4"
            px={4}
            py={3}
            rounded={'card'}
            bg={'neutralAlpha.50'}
            justifyContent="center"
            alignItems="center"
          >
            {isUnpairing ? (
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 2.5, repeat: Infinity, ease: 'linear' }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  transformOrigin: 'center',
                  paddingTop: '25px',
                  paddingBottom: '25px',
                }}
              >
                <Icon
                  as={ArenaLogo}
                  boxSize="50px"
                  opacity={0.25}
                  color="primary.800"
                />
              </motion.div>
            ) : (
              <>
                <Text textStyle="copy" color="neutral.800">
                  Are you sure you want to unpair current device?
                </Text>
                <Flex gap="6" w="full">
                  <Button
                    w="full"
                    variant="secondary"
                    onClick={() => setIsOpen(false)}
                    isDisabled={isUnpairing}
                  >
                    Back
                  </Button>
                  <Button
                    w="full"
                    variant="primary"
                    onClick={onUnpair}
                    isDisabled={isUnpairing}
                  >
                    {isUnpairing ? (
                      <Flex gap={2} align="center">
                        <Spinner size="sm" />
                        <Text>Unpairing...</Text>
                      </Flex>
                    ) : (
                      'Confirm'
                    )}
                  </Button>
                </Flex>
              </>
            )}
          </chakra.div>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
