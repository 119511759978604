import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Icon,
  Image,
  Text,
} from '@chakra-ui/react';

import { DataTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import {
  InfoIcon,
  PracticeIcon,
  useResponsive,
  WaitForQuery,
} from '@arena-labs/strive2-ui';
import { $API } from '@strive/api';

export function PracticeReview({
  onReviewComplete,
  submissionError,
}: {
  submissionError: boolean;
  onReviewComplete: () => void;
}) {
  const analytics = useAnalytics();
  const rs = useResponsive();
  const pillarQuery = $API.useGetPillars(
    {},
    {
      staleTime: 0,
      select: (pillars) => {
        const allPractices = pillars.flatMap((pillar) => pillar.practices);
        const completedPractices = allPractices.filter(
          (p) => p.completed_yesterday,
        );
        return {
          completed: completedPractices,
        };
      },
    },
  );

  return (
    <Flex direction="column" h="full" gap={4}>
      <Text textStyle={'copy_bold'} color={'logo'}>
        3 of 3
      </Text>

      <Text textStyle={'h5'}>
        Which Strive Tools did you use to move through those challenges?
      </Text>

      <HStack spacing={2} mb={4}>
        <Icon as={InfoIcon} color={'neutral.600'} boxSize={4} />
        <Text textStyle={'copy_small'}>
          Tools you log today will appear here tomorrow.
        </Text>
      </HStack>

      <Box
        bg={'neutral.white'}
        p={4}
        rounded={'card'}
        overflow={'auto'}
        maxH={rs({ base: '450px', xs: '300px' })}
      >
        <WaitForQuery query={pillarQuery}>
          {({ completed }) => {
            if (completed.length === 0) {
              return (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  direction={'column'}
                >
                  <Image
                    src={'/images/check-in/add-practice.png'}
                    alt="No tools logged"
                    w={'96px'}
                    h={'auto'}
                    mb={6}
                  />

                  <Text
                    textStyle={'copy'}
                    textAlign={'center'}
                    color={'neutral.600'}
                    fontWeight="bold"
                    mb={4}
                  >
                    No tools logged yesterday.
                  </Text>

                  <Text
                    textStyle={'copy_small'}
                    textAlign={'center'}
                    color={'neutral.600'}
                  >
                    Don&apos;t forget to visit the Toolkit tab to log more and
                    track your progress.
                  </Text>
                </Flex>
              );
            }

            return (
              <Flex direction={'column'} gap={3}>
                {completed.map((practiceComplete) => (
                  <Grid
                    templateColumns={'40px 1fr'}
                    columnGap={4}
                    alignItems={'center'}
                    key={practiceComplete.slug}
                  >
                    <PracticeIcon slug={practiceComplete.slug} />
                    <Text textStyle={'copy_bold'}>
                      {practiceComplete.short_title}
                    </Text>
                  </Grid>
                ))}
              </Flex>
            );
          }}
        </WaitForQuery>
      </Box>

      {submissionError && (
        <Box
          px={3}
          mx={'auto'}
          rounded={'sm'}
          transition={'all 0.35s ease'}
          w="auto"
          bg={'negative.200'}
          textAlign={'center'}
          color={'neutral.300'}
        >
          An error occured, please submit again!
        </Box>
      )}

      <Button
        variant={'primary'}
        w={'full'}
        onClick={() => {
          analytics.logEvent(DataTrackingEvent.ACISubmitted);
          onReviewComplete();
        }}
        mt={'auto'}
      >
        Generate Insights
      </Button>
    </Flex>
  );
}
