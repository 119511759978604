import React from 'react';
import { Button, Flex, HStack, Icon, Text } from '@chakra-ui/react';

interface StrivewareAlertCardProps {
  icon: React.ComponentType;
  iconColor: string;
  title: string;
  description: string;
  primaryButton?: {
    text: string;
    onClick?: () => void;
  };
  secondaryButton?: {
    text: string;
    onClick?: (e: React.MouseEvent) => void;
  };
}

export function AlertCardStriveware({
  icon,
  iconColor,
  title,
  description,
  primaryButton,
  secondaryButton,
}: StrivewareAlertCardProps) {
  return (
    <Flex
      direction={'column'}
      gap={2}
      mt={4}
      w={'full'}
      bg={'neutral.50'}
      px={4}
      py={3}
      rounded={'card'}
    >
      <HStack color={iconColor}>
        <Icon as={icon} boxSize={5} />
        <Text textStyle={'copy'}>{title}</Text>
      </HStack>
      <Text textStyle={'copy_small'} mb={2}>
        {description}
      </Text>
      <HStack w={'full'} spacing={6}>
        {secondaryButton && (
          <Button
            variant={'secondary'}
            w={'full'}
            onClick={secondaryButton.onClick}
            py={2}
          >
            {secondaryButton.text}
          </Button>
        )}
        {primaryButton && (
          <Button
            variant={'primary'}
            w={'full'}
            onClick={primaryButton.onClick}
            py={2}
          >
            {primaryButton.text}
          </Button>
        )}
      </HStack>
    </Flex>
  );
}
