import { useEffect } from 'react';
import { z } from 'zod';

import { createDataStore, useDataStoreQuery } from '@strive/utils';
import { StrivewareContext } from '@strive/wearable';

const userReadyToSyncStore = createDataStore({
  key: 'DeviceView.HasViewedSuccessScreen',
  schema: z.boolean(),
  fallback: false,
});

const userReadyToPairStore = createDataStore({
  key: 'DeviceView.HasViewedPairScreen',
  schema: z.boolean(),
  fallback: true,
});

export function useUltimatelyReadyToSync() {
  const [query, mutation] = useDataStoreQuery(userReadyToSyncStore);
  return [query.data, mutation.mutate] as const;
}

export function useUltimatelyReadyToPair() {
  const [query, mutation] = useDataStoreQuery(userReadyToPairStore);
  return [query.data, mutation.mutate] as const;
}

export function DeviceContent({
  setView,
}: {
  setView: (view: 'device-details' | 'setup' | null) => void;
}) {
  const [hasViewedSuccess, setHasViewedSuccess] = useUltimatelyReadyToSync();
  const [ultimatelyReadyToPair] = useUltimatelyReadyToPair();

  const deviceView = StrivewareContext.useSelector((state) => {
    return (!state.context.pairedDevice || !ultimatelyReadyToPair) &&
      hasViewedSuccess
      ? 'device-details'
      : 'setup';
  });

  const hasDeviceAndSyncing = StrivewareContext.useSelector((state) => {
    return state.context.pairedDevice && state.hasTag('syncing');
  });

  // If we have device data and it's syncing, this must be from a previous setup
  useEffect(() => {
    if (!hasViewedSuccess && hasDeviceAndSyncing) {
      setHasViewedSuccess(true);
    }
  }, [hasViewedSuccess, hasDeviceAndSyncing, setHasViewedSuccess]);

  useEffect(() => {
    setView(deviceView);
  }, [deviceView, setView]);

  return null;
}
