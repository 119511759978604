import { Flex, Text } from '@chakra-ui/react';

export function DataCollectionContent() {
  return (
    <Flex direction="column" gap={4} w="full" pt={16}>
      <Text textStyle="h5" color="neutral.800" mb={6}>
        What data does StriveWare collect?
      </Text>

      <Text textStyle="copy_bold" color="neutral.800">
        Heart Rate Variability (HRV)
      </Text>

      <Text textStyle="button" color="neutral.700">
        HRV is the most reliable measurement of autonomic function. Increased
        HRV is associated with increased stress resilience and higher
        parasympathetic nervous system activity, whereas decreased HRV is
        representative of poorer stress recovery. StriveWare tracks HRV by
        measuring the average variability between successive heartbeats at
        structured time increments.
      </Text>

      <Text textStyle="copy_bold" color="neutral.800" mt={4}>
        Sleep Consistency
      </Text>

      <Text textStyle="button" color="neutral.700">
        Sleep consistency is a measurement of your bed-time and wake-time
        trends. Higher sleep consistency is associated with improved stress
        recovery and overall sleep quality. To find your sleep consistency
        score, StriveWare tracks your longest sleep period from the 24 hour day,
        and calculates the average of your longest single sleep periods from the
        past 7 days. This average sleep consistency score is a reflection of
        your sleep consistency trends throughout the week.
      </Text>
    </Flex>
  );
}
