import React from 'react';
import { Box } from '@chakra-ui/react';
import { useActorRef, useSelector } from '@xstate5/react';
import { match } from 'ts-pattern';

import { GeneralTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { useHomepage } from '@arena-labs/strive2-coaching';
import { JStyleDeviceConnected } from '@strive/jstyle';
import { StrivewareContext, strivewarePairingMachine } from '@strive/wearable';

import { useUpdateLaunchpadChecklist } from '../../../../components/launchpad/use-update-launchpad-checklist';
import { useUltimatelyReadyToPair } from '../../device-content';
import { useWearableHub } from '../../use-wearable-hub';
// import { StrivewareHeader } from '../striveware-header';
// import { StrivewareLayout } from '../striveware-layout';
import { StrivewareSetupContext } from './hooks';
import { SetupCharging } from './setup-charging';
import { SetupIntro } from './setup-intro';
import { SetupMultipleDevices } from './setup-multiple-devices';
import { SetupNoDevices } from './setup-no-devices';
import { SetupScanning } from './setup-scanning';
import { SetupSingleDevice } from './setup-single-device';
import { SetupSuccess } from './setup-success';

export type StrivewareSetupProps = {
  onCancel?: () => void;
  onComplete?: () => void;
};

export function StrivewareSetup(props: StrivewareSetupProps) {
  const strivewareActor = StrivewareContext.useActorRef();
  const analytics = useAnalytics();
  const [_, setUltimatelyReadyToPair] = useUltimatelyReadyToPair();

  const updateChecklist = useUpdateLaunchpadChecklist({
    onSuccess: undefined,
  });
  const [homepage] = useHomepage();

  const setupActor = useActorRef(
    strivewarePairingMachine.provide({
      actions: {
        onDevicePaired: (
          _: unknown,
          { device }: { device: JStyleDeviceConnected },
        ) => {
          const isLaunchpad = homepage?.learning_state === 'launchpad';

          if (isLaunchpad) {
            updateChecklist.mutate({
              slug: 'sync_wearable',
              status: 'completed',
            });
            updateChecklist.mutate({
              slug: 'setup_wearable',
              status: 'completed',
            });
          }

          analytics.logEvent(
            GeneralTrackingEvent.UserInitiatedStrivewarePairingStats,
            {
              pairingFailedTime: null,
              pairingSuccessTime: new Date(),
              foundNoDevices: false,
            },
          );

          setUltimatelyReadyToPair(false);

          strivewareActor.send({ type: 'Pair Device', device });

          props.onComplete?.();
        },
        onPairingFailed: () => {
          analytics.logEvent(
            GeneralTrackingEvent.UserInitiatedStrivewarePairingStats,
            {
              pairingSuccessTime: null,
              pairingFailedTime: new Date(),
              foundNoDevices: false,
            },
          );
        },
      },
    }),
  );
  const view = useSelector(setupActor, (state) =>
    state.matches('initial')
      ? 'initial'
      : state.matches('charging')
      ? 'charging'
      : state.matches({ 'Finding Devices': 'Quick Scan' }) ||
        state.matches({ 'Finding Devices': 'Extended Scan' })
      ? 'scanning'
      : state.matches({
          'Finding Devices': { Results: { 'Single Device': 'Paired' } },
        }) ||
        state.matches({
          'Finding Devices': { Results: { 'Multiple Devices': 'Paired' } },
        })
      ? 'success'
      : state.matches({ 'Finding Devices': { Results: 'Single Device' } })
      ? 'results-single'
      : state.matches({ 'Finding Devices': { Results: 'Multiple Devices' } })
      ? 'results'
      : state.matches({ 'Finding Devices': { Results: 'No Devices' } })
      ? 'no-devices'
      : 'initial',
  );

  const wearableHub = useWearableHub();

  const handleCancel = (event: React.MouseEvent) => {
    if (props.onCancel) {
      event.preventDefault();
      props.onCancel();
    }
  };

  // const returnToPairingFromNoDevices = () => {
  //   const now = new Date();
  //   if (pairingStats.data) {
  //     setPairingStats.mutate({
  //       ...pairingStats.data,
  //       startedPairingTime: now,
  //     });
  //   }
  // };

  // const returnToPairingFromMultipleDevices = () => {
  //   const now = new Date();
  //   const multipleDevicesFound = pairingStats.data?.multipleDevicesFound ?? [];
  //   if (pairingStats.data) {
  //     setPairingStats.mutate({
  //       ...pairingStats.data,
  //       multipleDevicesFound: [...multipleDevicesFound, now],
  //     });
  //   }
  // };

  return (
    <StrivewareSetupContext.Provider value={setupActor}>
      <Box
        h={'calc(100vh - env(safe-area-inset-top))'}
        pb={
          view === 'results' || view === 'results-single' || view === 'success'
            ? undefined
            : 2
        }
      >
        {match(view)
          .with('initial', () => (
            <SetupIntro onBack={handleCancel} onClose={wearableHub.onClose} />
          ))
          .with('charging', () => (
            <SetupCharging onClose={wearableHub.onClose} />
          ))
          .with('scanning', () => (
            <SetupScanning onClose={wearableHub.onClose} />
          ))
          .with('results', () => (
            <SetupMultipleDevices onClose={wearableHub.onClose} />
          ))
          .with('results-single', () => (
            <SetupSingleDevice onClose={wearableHub.onClose} />
          ))
          .with('no-devices', () => (
            <SetupNoDevices onClose={wearableHub.onClose} />
          ))
          .with('success', () => <SetupSuccess onClose={wearableHub.onClose} />)
          .exhaustive()}
      </Box>
    </StrivewareSetupContext.Provider>
  );
}
