import React, { useEffect, useState } from 'react';
import {
  Box,
  Circle,
  Flex,
  Grid,
  HStack,
  Image,
  keyframes,
  Text,
  useToken,
} from '@chakra-ui/react';

import { useResponsive, WaitForQuery, ZStack } from '@arena-labs/strive2-ui';
import { useUserProfile } from '@strive/api';
import { useDataStoreQuery } from '@strive/utils';
import {
  lastSuccessfulSyncStore,
  StrivewareContext,
  StrivewareSelector,
  useStrivewareBattery,
} from '@strive/wearable';

export function DeviceDetails() {
  const userQuery = useUserProfile();
  const batteryQuery = useStrivewareBattery();
  const [lastSuccessfulSyncQuery] = useDataStoreQuery(lastSuccessfulSyncStore);

  const [lastSyncedAt, setLastSyncedAt] = useState(
    lastSuccessfulSyncQuery.data,
  );

  useEffect(() => {
    if (
      userQuery.data?.profile.wearable[0]?.last_synced_at &&
      lastSuccessfulSyncQuery.data === null
    ) {
      setLastSyncedAt(userQuery.data?.profile.wearable[0]?.last_synced_at);
    } else if (
      lastSyncedAt &&
      lastSuccessfulSyncQuery.data &&
      lastSuccessfulSyncQuery.data > lastSyncedAt
    ) {
      setLastSyncedAt(lastSuccessfulSyncQuery.data);
    }
  }, [lastSuccessfulSyncQuery.data, lastSyncedAt, userQuery.data]);

  const deviceState = StrivewareContext.useSelector(
    StrivewareSelector.deviceState,
  );
  // const syncProgress = StrivewareContext.useSelector(
  //   (state) => state.context.syncProgress,
  // );

  const rs = useResponsive();

  return (
    <WaitForQuery query={userQuery} loading={null}>
      {(user) => {
        const deviceName =
          user.profile.preferred_name && user.profile.preferred_name !== ''
            ? user.profile.preferred_name
            : user.first_name && user.first_name !== ''
            ? user.first_name
            : 'My ' + 'StriveWare';

        return (
          <Grid
            gridTemplate={`"ring details" auto / auto 1fr`}
            w="full"
            gap="8"
          >
            <Flex
              gridArea="ring"
              justifyContent="center"
              alignItems="center"
              pl={2}
            >
              <StriveRing
                deviceState={deviceState}
                batteryQuery={batteryQuery}
                size="large"
              />
            </Flex>

            <Flex direction="column" gridArea="details" pt="2.5">
              <HStack>
                <Text
                  textStyle="copy_bold"
                  color={
                    deviceState === 'connected' ||
                    deviceState === 'synced' ||
                    deviceState === 'syncing'
                      ? 'primary.300'
                      : 'negative.400'
                  }
                >
                  {deviceState === 'connected' ||
                  deviceState === 'synced' ||
                  deviceState === 'syncing'
                    ? 'Connected'
                    : 'Not Connected'}
                </Text>
                <WaitForQuery query={batteryQuery} loading={null} error={null}>
                  {({ batteryLevel }) => (
                    <Text
                      w={'fit-content'}
                      textStyle="copy_small"
                      color={
                        batteryLevel > 20
                          ? 'positive.300'
                          : batteryLevel > 10
                          ? 'warning.300'
                          : 'negative.300'
                      }
                      rounded={'full'}
                      bg={
                        batteryLevel > 20
                          ? 'positive.100'
                          : batteryLevel > 10
                          ? 'warning.50'
                          : 'negative.50'
                      }
                      px={2}
                      py={1}
                    >
                      {batteryLevel}%
                    </Text>
                  )}
                </WaitForQuery>
              </HStack>

              <Text textStyle={rs({ base: 'h4', xs: 'h5' })} mb={1.5}>
                {deviceName.startsWith('My ')
                  ? 'My StriveWare'
                  : `${deviceName}'s StriveWare`}
              </Text>
              <Box pt={1}>
                {deviceState === 'syncing' ? (
                  <Text textStyle="copy_extra_small" color="neutral.600">
                    Syncing
                  </Text>
                ) : (
                  lastSyncedAt && (
                    <Text textStyle="copy_extra_small" color="neutral.600">
                      {formatSyncTime(
                        userQuery.data?.profile.wearable[0]?.last_synced_at ??
                          null,
                      )}
                    </Text>
                  )
                )}
              </Box>
            </Flex>
          </Grid>
        );
      }}
    </WaitForQuery>
  );
}

// Unified component for the device ring display
interface StriveRingProps {
  deviceState: string;
  batteryQuery: any;
  size?: 'small' | 'large' | 'medium';
}

export function StriveRing({
  deviceState,
  batteryQuery,
  size = 'medium',
}: StriveRingProps) {
  const [primaryColor] = useToken('colors', ['primary.300']);
  const isConnected = deviceState === 'connected' || deviceState === 'synced';
  const isSyncing = deviceState === 'syncing';

  // Calculate sizes based on the size prop
  const outerRingSize =
    size === 'large' ? '108px' : size === 'medium' ? '60px' : '60px';
  const centerCircleSize =
    size === 'large' ? '92px' : size === 'medium' ? '51px' : '51px';
  const deviceImageSize =
    size === 'large' ? '64px' : size === 'medium' ? '36px' : '36px';
  const indicatorDotSize =
    size === 'large' ? '10px' : size === 'medium' ? '6px' : '6px';
  const syncingDotSize =
    size === 'large' ? '10px' : size === 'medium' ? '6px' : '6px';
  const borderWidth =
    size === 'large' ? '6px' : size === 'medium' ? '3px' : '3px';
  const shadowSize =
    size === 'large'
      ? '0px 8.7px 41.74px 0px #0033664D'
      : '0px 4px 20px 0px #0033664D';

  return (
    <Box position="relative" w={outerRingSize} h={outerRingSize}>
      <ZStack position="absolute" w={outerRingSize} h={outerRingSize}>
        {/* Syncing animation ring */}
        {isSyncing && (
          <Circle
            size={outerRingSize}
            position="absolute"
            zIndex={0}
            bg="conic-gradient(from 85deg at 50% 50%, rgba(86, 86, 86, 0) 0deg, rgba(86, 86, 86, 0) 81deg, #565656 360deg)"
            animation={`${spin} 1s linear infinite`}
          >
            <Circle
              size={syncingDotSize}
              position="absolute"
              top={'50%'}
              left={'100%'}
              transform={
                size === 'large'
                  ? 'translate(-10px, -7px)'
                  : 'translate(-6px, -5px)'
              }
              bg="#565656"
              zIndex={3}
            />
          </Circle>
        )}

        {/* Battery indicator ring */}
        {isConnected && !isSyncing && (
          <WaitForQuery query={batteryQuery} loading={null} error={null}>
            {(data) => {
              // Safely extract batteryLevel from data
              const batteryLevel =
                typeof data === 'object' &&
                data !== null &&
                'batteryLevel' in data
                  ? (data as { batteryLevel: number }).batteryLevel
                  : 0;

              // Calculate the angle based on battery level
              const angle = (batteryLevel / 100) * 360;

              return (
                <Circle
                  size={outerRingSize}
                  position="absolute"
                  zIndex={0}
                  bg={`conic-gradient(from 0deg at 50% 50%, 
                  ${primaryColor} 0%, 
                  ${primaryColor} ${batteryLevel}%, 
                  transparent ${batteryLevel}%, 
                  transparent 100%)`}
                >
                  {/* Circle at the start of the battery arc */}
                  <Circle
                    size={indicatorDotSize}
                    position="absolute"
                    top={'0'}
                    left={'50%'}
                    transform={
                      size === 'large'
                        ? 'translate(-6px,0px)'
                        : 'translate(-4px,0px)'
                    }
                    bg={primaryColor}
                    zIndex={3}
                  />

                  {/* Circle at the current battery percentage position */}
                  <Circle
                    size={outerRingSize}
                    position="relative"
                    top={'0'}
                    left={'0'}
                    bg={'none'}
                    zIndex={3}
                    transform={`rotate(${angle}deg) `}
                  >
                    <Circle
                      size={indicatorDotSize}
                      bg={'primary.300'}
                      top={'0'}
                      left={'50%'}
                      transform={
                        size === 'large'
                          ? 'translate(-6px,0px)'
                          : 'translate(-4px,0px)'
                      }
                      position="absolute"
                    />
                  </Circle>
                </Circle>
              );
            }}
          </WaitForQuery>
        )}

        {/* Center circle */}
        <Circle
          size={centerCircleSize}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex={1}
          bg={'#D1D5DB'}
          shadow={shadowSize}
          border={`${borderWidth} solid`}
          borderColor={isConnected || isSyncing ? 'transparent' : 'neutral.400'}
        />

        {/* Device image */}
        <Image
          src={'/images/striveware/black-ring-grey-light.webp'}
          w={deviceImageSize}
          alt=""
          bg="transparent"
          zIndex={2}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        />
      </ZStack>
    </Box>
  );
}

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

function formatSyncTime(timestamp: Date | null): string {
  const now = new Date();
  const diffInMs = now.getTime() - (timestamp?.getTime() ?? now.getTime());
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);

  if (diffInMinutes < 1) {
    return 'Last synced just now';
  } else if (diffInMinutes < 60) {
    return `Last synced ${diffInMinutes} ${
      diffInMinutes === 1 ? 'min' : 'mins'
    } ago`;
  } else if (diffInHours < 48) {
    return `Last synced ${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
  } else {
    return `Last synced ${Math.floor(diffInHours / 24)} day${
      Math.floor(diffInHours / 24) !== 1 ? 's' : ''
    } ago`;
  }
}
