import { BoxProps } from '@chakra-ui/react';

import { LottieFile, LottieFileProps } from './lottie-file';

export type PracticeIconProps = {
  slug: string;
} & Omit<LottieFileProps, 'as'>;

export function PracticeIcon({ slug, ...props }: PracticeIconProps) {
  const [lottie, boxProps] = getLottieForPractice(slug);

  return (
    <LottieFile
      overflow="visible"
      display="grid"
      placeItems="center"
      {...boxProps}
      {...props}
      as={lottie}
    />
  );
}

function getLottieForPractice(slug: string) {
  const lottiesForPractices: Record<
    string,
    [() => Promise<{ default: unknown }>, BoxProps]
  > = {
    // First Steps
    Tool_DestressBreath: [
      () => import('./lottie-files/destress-breath.lottie.json'),
      { transform: 'scale(2)' },
    ],
    Tool_LightExposure: [
      () => import('./lottie-files/light-exp.lottie.json'),
      {},
    ],
    Tool_Hydration: [() => import('./lottie-files/hydration.lottie.json'), {}],

    // REGULATE STRESS - Prepare & Activate
    Tool_ExtendedExhale: [
      () => import('./lottie-files/deact-breath.lottie.json'),
      {},
    ],
    Tool_ShoulderRoll: [
      () => import('./lottie-files/shoulder-roll.lottie.json'),
      {},
    ],
    Tool_Visualization: [() => import('./lottie-files/viz.lottie.json'), {}],

    // REGULATE STRESS - Manage Pressure
    Tool_RelaxationMovement: [
      () => import('./lottie-files/relax-movement.lottie.json'),
      {},
    ],
    Tool_PanoramicVision: [
      () => import('./lottie-files/panoramic-viz.lottie.json'),
      { transform: 'scale(1.6)' },
    ],
    Tool_DeactivationBreath: [
      () => import('./lottie-files/deact-breath.lottie.json'),
      {},
    ],

    // REGULATE STRESS - Process & Reset
    Tool_SituationalDialogue: [
      () => import('./lottie-files/situational-dialogue.lottie.json'),
      { transform: 'scale(0.8)' },
    ],
    Tool_AfterActionReview: [
      () => import('./lottie-files/after-action-review.lottie.json'),
      { transform: 'scale(0.8)' },
    ],
    Tool_StimulusLabeling: [
      () => import('./lottie-files/stimulus-label.lottie.json'),
      { transform: 'scale(1.3)' },
    ],

    // PROMOTE REGENERATION - Improve Sleep
    Tool_ColdDarkQuiet: [
      () => import('./lottie-files/sleep-op.lottie.json'),
      {},
    ],
    Tool_LightRestriction: [
      () => import('./lottie-files/light-restriction.lottie.json'),
      {},
    ],
    Tool_SleepConsistency: [
      () => import('./lottie-files/sleep-consistency.lottie.json'),
      {},
    ],

    // PROMOTE REGENERATION - Turn off the mind
    Tool_BoxBreath: [
      () => import('./lottie-files/box-breath.lottie.json'),
      { transform: 'scale(2)' },
    ],
    Tool_SelfHypnosis: [
      () => import('./lottie-files/self-hyp.lottie.json'),
      {},
    ],
    Tool_YogaNidra: [() => import('./lottie-files/yoga-nidra.lottie.json'), {}],

    // PROMOTE REGENERATION - Decompress
    Tool_ActiveRecovery: [
      () => import('./lottie-files/active-recover.lottie.json'),
      {},
    ],
    Tool_ExamineGratitude: [
      () => import('./lottie-files/gratitude.lottie.json'),
      { transform: 'scale(1.5)' },
    ],
    Tool_Debriefing: [
      () => import('./lottie-files/debriefing.lottie.json'),
      { transform: 'scale(0.9)' },
    ],

    // STABILIZE ENERGY - Build Physical Capacity
    Tool_AMPM_Routine: [
      () => import('./lottie-files/am-pm-movement.lottie.json'),
      { transform: 'scale(1.2)' },
    ],
    Tool_MinimumEffectiveDose: [
      () => import('./lottie-files/minimum-effective-dose.lottie.json'),
      { transform: 'scale(1.75)' },
    ],
    Tool_ExerciseSnacks: [
      () => import('./lottie-files/exercise-snacks.lottie.json'),
      {},
    ],

    // STABILIZE ENERGY - Fuel For Performance
    Tool_MindDiet: [() => import('./lottie-files/mind-diet.lottie.json'), {}],
    Tool_EnergyStabilization: [
      () => import('./lottie-files/energy-stabilization.lottie.json'),
      {},
    ],
    Tool_StrategicFoodTiming: [
      () => import('./lottie-files/strategic-food-timing.lottie.json'),
      {},
    ],

    // Stabilize Energy
    Tool_ShiftingSleepWake: [
      () => import('./lottie-files/shifting-sleep-wake.lottie.json'),
      { transform: 'scale(3)' },
    ],
    Tool_ExerciseTiming: [
      () => import('./lottie-files/exercise-timing.lottie.json'),
      {},
    ],
    Tool_Fuel_Supplements: [
      () => import('./lottie-files/fuel-and-supplements.lottie.json'),
      { transform: 'translateY(-50%)' },
    ],

    Tool_Mindfulness: [
      () => import('./lottie-files/activation-breath.lottie.json'),
      {},
    ],

    Tool_MealTiming: [
      () => import('./lottie-files/strategic-food-timing.lottie.json'),
      {},
    ],

    Tool_FoodAsFuel: [
      () => import('./lottie-files/food-as-fuel.lottie.json'),
      { transform: 'scale(1.75)' },
    ],

    Tool_LiveYourValues: [
      () => import('./lottie-files/live-your-values.lottie.json'),
      { transform: 'scale(1.6)' },
    ],
    Tool_PrioritizeWhatMatters: [
      () => import('./lottie-files/prioritize-what-matters.lottie.json'),
      { transform: 'scale(1.5)' },
    ],
    Tool_SelfTalk: [
      () => import('./lottie-files/self-talk.lottie.json'),
      { transform: 'scale(1.75)' },
    ],
    Tool_ColdExposure: [
      () => import('./lottie-files/cold-exposure.lottie.json'),
      { transform: 'scale(1.5)' },
    ],

    Tool_TransitionProtocol: [
      () => import('./lottie-files/transition-protocol.lottie.json'),
      { transform: 'scale(1.2)' },
    ],
    Tool_Alcohol: [
      () => import('./lottie-files/precision-with-alcohol.lottie.json'),
      { transform: 'scale(1.2)' },
    ],

    Tool_NSDR: [
      () => import('./lottie-files/NSDR.lottie.json'),
      { transform: 'scale(1.5)' },
    ],
    Tool_HipBackRelease: [
      () => import('./lottie-files/energize.lottie.json'),
      {},
    ],

    Tool_Nature: [
      () => import('./lottie-files/time-in-nature.lottie.json'),
      { transform: 'scale(1.1)' },
    ],
    Tool_ThirdThing: [
      () => import('./lottie-files/find-third-thing.lottie.json'),
      { transform: 'scale(1.4)' },
    ],
    Tool_ConnectAuthentically: [
      () => import('./lottie-files/connect-auth.lottie.json'),
      { transform: 'scale(1.2)' },
    ],
    Tool_ShiftWakeUpTime: [
      () => import('./lottie-files/shift-wake-time.lottie.json'),
      { transform: 'scale(1.2)' },
    ],
    Tool_SelfDebrief: [
      () => import('./lottie-files/self-debrief.lottie.json'),
      { transform: 'scale(1.5)' },
    ],
  };

  return lottiesForPractices[slug] ?? [null, {}];
}
