import { Flex, Text } from '@chakra-ui/react';

export function CareIssueContent() {
  return (
    <Flex direction="column" gap={4} w="full" pt={16}>
      <Text textStyle="h5" color="neutral.800">
        How should I care for my StriveWare?
      </Text>

      <Text textStyle="button" color="neutral.700">
        Although StriveWare is water resistant, the ring is not waterproof. Some
        contact with water will not damage the ring, but we recommend avoiding
        significant contact with water, soap, and lotions.
      </Text>

      <Text textStyle="button" color="neutral.700">
        We also advise that you avoid banging or scratching your ring against
        hard surfaces (including weights in the gym).
      </Text>
    </Flex>
  );
}
