import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { safeEnum, zIsoDateTime } from '../lib/schema-utils';
import { playableVideoSchema } from './media.schema';
import { wearableProviderSchema } from './user-profile';

export type Address = z.infer<typeof addressSchema>;

export const addressSchema = z.object({
  full_name: z.string().max(50).min(1, 'Shipping Recipient is required'),
  shipping_email: z
    .string()
    .email(`Email address not properly formed`)
    .min(1, 'Email is required'),
  address: z.string().min(1, 'Address is required '),
  apartment: z.string().nullable(),
  company: z.string().nullable(),
  city: z.string().min(1, 'City is required'),
  state: z.string().min(1, 'State is required'),
  zip_code: z
    .string()
    .min(1, 'Postal Code is required')
    .max(5, 'Postal Code is too long'),
  country: z.string(),
});

const wearableOrderUpdateSchema = z.object({
  address: addressSchema.nullable(),
  size: z.number().nullable(),
  style: z.string().nullable().optional(),
});

const launchpadChecklistState = z.enum([
  'locked',
  'active',
  'skipped',
  'completed',
]);

const launchpadTaskSchema = z.object({
  label: z.string(),
  completed_at: zIsoDateTime.optional().nullable(),
  status: launchpadChecklistState,
});

export const launchpadApi = makeApi([
  {
    alias: 'getLaunchpad',
    description: 'Fetches the current user orientation data',
    method: 'get',
    parameters: [],
    path: '/launchpad/',
    response: z.object({
      cohort_launch_at: zIsoDateTime,
      launch_events: z.string(), // free-form markdown

      wearable: z
        .object({
          provider: wearableProviderSchema,
          status: safeEnum([
            'not_ordered',
            'sizing_ordered',
            'sizing_shipped',
            'size_entered',
            'ordered',
            'shipped',
            'delivered',
            'complete',
          ]),
          tracking_url: z.string().url().optional().nullable(),
        })
        .nullable(),

      checklist: z.array(
        z
          .union([
            makeChecklist(
              'setup_wearable',
              z.array(
                z.union([
                  makeChecklistItem('wearable_order'),
                  makeChecklistItem('add_wearable_size'),
                  makeChecklistItem('confirm_ring_delivery'),
                  makeChecklistItem('sync_wearable'),
                ]),
              ),
            ),
            makeTypeformChecklistItem('pfi_questionnaire'),
            makeTypeformChecklistItem('build_profile_questionnaire'),
            makePlaylistChecklistItem('intro_playlist'),
            makePlaylistChecklistItem('data_playlist'),
            makePlaylistChecklistItem('resource_playlist'),
            makeChecklistItem('commitment_statement'),
            makeChecklistItem('email_verification'),
            launchpadTaskSchema.extend({
              type: z.literal('video'),
              slug: z.string(),
              video: playableVideoSchema,
              children: z.null().optional(),
            }),
            makeChecklistItem('schedule_coaching_call'),
            makeChecklistItem('coach_selection_questionnaire'),
          ])
          .nullable()
          .catch((e) => {
            console.error('Launchpad Task Validation Failed for', e.input);
            console.error(...e.error.errors);
            return null;
          }),
      ),
    }),
  },
  {
    alias: 'setWearableOrder',
    description: 'Post the address the user has entered for shipping purposes',
    method: 'patch',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: wearableOrderUpdateSchema,
      },
    ],
    path: '/launchpad/wearable-order/',
    response: wearableOrderUpdateSchema,
  },
  {
    alias: 'getWearableOrder',
    description: 'Get the wearable order object: address, ring size',
    method: 'get',
    parameters: [],
    path: '/launchpad/wearable-order/',
    response: wearableOrderUpdateSchema,
  },
  {
    alias: 'setLaunchpadChecklistItemStatus',
    description: 'Post the slug of a checklist item and its status',
    method: 'patch',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: z.object({ slug: z.string(), status: launchpadChecklistState }),
      },
    ],
    path: '/launchpad/',
    response: z.object({
      slug: z.string(),
      status: launchpadChecklistState,
    }),
  },
  {
    alias: 'emailVerification',
    description: 'Have the BE send a validation email to the user ',
    method: 'post',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: z.object({ email: z.string() }),
      },
    ],
    path: '/auth/email/verify',
    response: z.unknown(),
  },
]);

// Basic checklist item
function makeChecklistItem<Slug extends string>(slug: Slug) {
  return launchpadTaskSchema.extend({
    slug: z.literal(slug),
  });
}

function makeChecklist<Slug extends string, Children extends z.ZodSchema>(
  slug: Slug,
  children: Children,
) {
  return makeChecklistItem(slug).extend({ children });
}

// Typeform checklist requires a form_id
function makeTypeformChecklistItem<Slug extends string>(slug: Slug) {
  return makeChecklistItem(slug).extend({
    typeform_id: z.string(),
  });
}

// playlist checklist requires playlist slug
function makePlaylistChecklistItem<Slug extends string>(slug: Slug) {
  return makeChecklistItem(slug).extend({
    playlist: z.string(),
  });
}
