import { InfiniteData } from '@tanstack/react-query';

import {
  Coach,
  CoachDetail,
  MergedVideo,
  PaginatedResponse,
  PillarAudio,
  PillarVideo,
  PlayableAudio,
  PlayableMedia,
  PlayableVideo,
  Video,
  VideoDetail,
} from './types';
import { hasKey, isObjectWithKey } from './utils';

export function isStriveEntity<ObjectType>(
  data: unknown,
): data is ObjectType & object & { url: string } {
  return isObjectWithKey(data, 'url') && typeof data.url === 'string';
}

export function isPaginated(data: unknown): data is PaginatedResponse<unknown> {
  return (
    isObjectWithKey(data, 'results') &&
    'count' in data &&
    'next' in data &&
    'previous' in data &&
    Array.isArray(data.results)
  );
}

export function isEmptyPagination(
  data: unknown,
): data is PaginatedResponse<never> {
  return isPaginated(data) && data.results.length === 0;
}

export function isVideo(video: unknown): video is Video {
  return isStriveEntity(video) && !!video.url.match(/\/content\/videos\/\w+/);
}

export function isVideoDetail(video: unknown): video is VideoDetail {
  return isVideo(video) && 'liked' in video && typeof video.liked === 'boolean';
}

export function isPaginatedVideo(
  data: unknown,
): data is PaginatedResponse<Video> {
  return isPaginated(data) && isVideo(data.results[0]);
}

export function isPlayableVideo(data: unknown): data is PlayableVideo {
  return (
    isObjectWithKey(data, 'playback_id') &&
    hasKey(data, 'type') &&
    data.type === 'video'
  );
}

export function isPillarVideo(data: unknown): data is PillarVideo {
  return Boolean(
    isPlayableVideo(data) && hasKey(data, 'category') && data.category,
  );
}

export function isPlayableAudio(data: unknown): data is PlayableAudio {
  return (
    isObjectWithKey(data, 'playback_id') &&
    hasKey(data, 'type') &&
    data.type === 'audio'
  );
}

export function isPillarAudio(data: unknown): data is PillarAudio {
  return Boolean(
    isPlayableAudio(data) && hasKey(data, 'category') && data.category,
  );
}

export function isInfiniteVideos(
  data: unknown,
): data is InfiniteData<PaginatedResponse<Video>> {
  return (
    isObjectWithKey(data, 'pages') &&
    Array.isArray(data.pages) &&
    isPaginatedVideo(data.pages[0])
  );
}

export function isCoach(coach: unknown): coach is Coach {
  return isStriveEntity(coach) && !!coach.url.match(/\/content\/coaches\/\w+/);
}

export function isCoachDetail(coach: unknown): coach is CoachDetail {
  return isCoach(coach) && 'bio' in coach;
}

export function isPaginatedCoach(
  data: unknown,
): data is PaginatedResponse<Coach> {
  return isPaginated(data) && isCoach(data.results[0]);
}

/**
 * Type-safe getter for video properties
 *
 * There are 3 different video types of varying levels of detail
 * This will return the value of the key if it exists, otherwise it will return the fallback (if supplied)
 */
export function getFromVideo<
  VideoType extends Video | PlayableVideo,
  VideoKey extends keyof MergedVideo,
  VideoValue extends MergedVideo[VideoKey],
>(video: VideoType, key: VideoKey, fallback?: VideoValue | null) {
  if (key in video) {
    return (video as MergedVideo)[key] as VideoValue;
  }
  return fallback;
}

export function isMediaPlayed(media: PlayableMedia): boolean {
  return media.type === 'video'
    ? media.watched
    : media.type === 'audio'
    ? media.played
    : false;
}

export function isMediaPlayedThisSession(media: PlayableMedia): boolean {
  return Boolean(
    media.type === 'video'
      ? media.watched_within_current_session
      : media.type === 'audio'
      ? media.played_within_current_session
      : false,
  );
}
