import { Box, Flex, Icon, Text } from '@chakra-ui/react';

import { ChevronRightIcon } from '@arena-labs/strive2-ui';

interface HelpCenterItemProps {
  title: string;
  showDivider?: boolean;
  onClick?: () => void;
}

export function HelpCenterItem({
  title,
  showDivider,
  onClick,
}: HelpCenterItemProps) {
  return (
    <Box
      as="button"
      w="full"
      py={4}
      onClick={onClick}
      borderBottom={showDivider ? '1px solid' : 'none'}
      borderColor="neutral.200"
      _last={{ borderBottom: 'none' }}
    >
      <Flex
        justify="space-between"
        alignItems={'center'}
        align="flex-start"
        w="full"
        h="full"
        gap={3}
        pl={2}
        pr={1}
      >
        <Text
          textStyle="copy"
          color="neutral.800"
          textAlign="left"
          flex="1"
          pr={2}
        >
          {title}
        </Text>

        <Icon as={ChevronRightIcon} boxSize={3} color="neutral.700" />
      </Flex>
    </Box>
  );
}
