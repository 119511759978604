import { Container, ContainerProps, Grid } from '@chakra-ui/react';

import { useGradient } from '../utils/use-gradient';

export type AppContainerProps = ContainerProps & {
  appLayout?: boolean;
  fixedHeight?: boolean;
};

export function AppContainer({
  appLayout,
  fixedHeight,
  ...props
}: AppContainerProps) {
  const bgGradient = useGradient('background');
  const appLayoutProps = {
    as: Grid,
    height: '100vh',
    sx: {
      gridTemplateRows: 'auto auto 1fr auto auto',
      grid: `
        "banner" auto
        "content-header" auto
        "content-body" 1fr
        "content-footer" auto
        "footer" auto
      `,
      '@media(min-height: 650px)': fixedHeight
        ? {
            height: 'calc(100 * var(--vh))',
          }
        : {},
      // Ensure content area is scrollable and touch events work properly
      '& > [data-grid-area="content-body"]': {
        overflowY: 'auto',
        overflowX: 'hidden',
        minHeight: 0, // Required for Firefox
        display: 'flex',
        flexDirection: 'column',
        WebkitOverflowScrolling: 'touch', // Enable momentum scrolling on iOS
        position: 'relative', // Establish new stacking context
        touchAction: 'pan-y', // Explicitly enable vertical touch scrolling
        height: '100%', // Ensure full height
        '-webkit-transform': 'translateZ(0)', // Force GPU acceleration
      },
    },
  };

  return (
    <Container
      p={0}
      pb={'env(safe-area-inset-bottom, 0px)'}
      maxW="container.xl"
      {...props}
      {...(appLayout ? appLayoutProps : {})}
      bg={bgGradient}
      overflow="auto"
    />
  );
}
