import { useEffect } from 'react';

import { useUserProfile } from '@strive/api';

import { initZendeskWithUser } from '../../lib/zendesk';

/**
 * A component that initializes Zendesk with the user profile data.
 * Place this component high in your component tree to ensure Zendesk
 * is initialized with user data when available.
 */
export function ZendeskProvider({ children }: React.PropsWithChildren) {
  const { data: user } = useUserProfile();

  useEffect(() => {
    // Initialize Zendesk with user data when it becomes available
    if (user) {
      initZendeskWithUser(user);
    }
  }, [user]);

  return <>{children}</>;
}
