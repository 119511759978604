import React from 'react';

import { AlertTriangle, WaitForQuery } from '@arena-labs/strive2-ui';
import { $API } from '@strive/api';
import { StrivewareContext, StrivewareSelector } from '@strive/wearable';

import { useNetworkState } from '../../../lib/network-state.machine';
import { AlertCardStriveware } from './alert-card-striveware';
import { NoSleepAlert } from './no-sleep-alert';

export function StrivewareAlerts() {
  const progress = $API.useGetProgressData();
  const networkState = useNetworkState();
  const isConnected = StrivewareContext.useSelector(
    StrivewareSelector.isConnected,
  );

  return (
    <>
      {networkState === 'offline' && (
        <AlertCardStriveware
          icon={AlertTriangle}
          iconColor="negative.400"
          title="No internet connection."
          description="We can't connect right now due to network issues. Please check your connection and try again."
        />
      )}

      {networkState === 'online' && isConnected && (
        <WaitForQuery query={progress} loading={undefined} error={undefined}>
          {(progress) => {
            const shouldShowNoSleepAlert =
              progress.results.at(0)?.sleeps === null;
            return shouldShowNoSleepAlert ? <NoSleepAlert /> : null;
          }}
        </WaitForQuery>
      )}
    </>
  );
}
