import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Icon,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { HeadsetIcon } from '@arena-labs/strive2-ui';

import { registerBackHandler } from '../../../../../lib/back-handler';
import { toggleZendeskChat } from '../../../../../lib/zendesk';

export function PairingIssueContent() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState<any>(null);

  function FirstSlide() {
    return (
      <Flex direction="column" h="full" position="relative" pt={16} pb="60px">
        <Box flex="1" overflowY="auto">
          <Text textStyle="h5" color="neutral.800" mb={4}>
            Having trouble pairing your StriveWare?
          </Text>

          <Text textStyle="button" color="neutral.700">
            Here&apos;s what to check first:
          </Text>

          <OrderedList pl={2} mb={8}>
            <ListItem>
              <Text textStyle="button" color="neutral.700">
                Is the StriveWare within range?
              </Text>
            </ListItem>
            <ListItem>
              <Text textStyle="button" color="neutral.700">
                Is it sufficiently charged ({'>'}50%)?
              </Text>
            </ListItem>
            <ListItem>
              <Text textStyle="button" color="neutral.700">
                Is it already paired with another device?
              </Text>
            </ListItem>
            <ListItem>
              <Text textStyle="button" color="neutral.700">
                Is your bluetooth on?
              </Text>
            </ListItem>
            <ListItem>
              <Text textStyle="button" color="neutral.700">
                Does your Strive app have bluetooth permission?
              </Text>
            </ListItem>
          </OrderedList>

          <Text textStyle="button" color="neutral.700" mt={4}>
            How to check bluetooth permission?
          </Text>
          <UnorderedList pl={2}>
            <ListItem>
              <Text textStyle="button" color="neutral.700">
                Navigate to your phone&apos;s settings and check to see if you
                have permitted the Strive app to access Bluetooth. If your
                permission is &ldquo;off,&rdquo; toggle the permission status to
                &ldquo;on.&rdquo;
              </Text>
            </ListItem>
          </UnorderedList>
        </Box>
        <Button
          variant="secondary"
          position="absolute"
          bottom={4}
          left={0}
          right={0}
          onClick={() => {
            setCurrentSlide(1);
            swiperInstance?.slideNext();
          }}
        >
          Not resolved
        </Button>
      </Flex>
    );
  }

  function SecondSlide() {
    return (
      <Flex direction="column" h="full" position="relative" pt={16} pb="60px">
        <Box flex="1" overflowY="auto">
          <Text textStyle="h5" color="neutral.800" mb={4}>
            Still not working? Try these steps:
          </Text>

          <UnorderedList pl={2}>
            <ListItem>
              <Text textStyle="button" color="neutral.700">
                Force close the Strive app and reopen it.
              </Text>
            </ListItem>
            <ListItem>
              <Text textStyle="button" color="neutral.700">
                Delete the Strive app, then re-download it. (Don&apos;t worry -
                your progress and data are saved in your profile!)
              </Text>
            </ListItem>
          </UnorderedList>
        </Box>
        <Button
          variant="secondary"
          position="absolute"
          bottom={4}
          left={0}
          right={0}
          onClick={() => {
            setCurrentSlide(2);
            swiperInstance?.slideNext();
          }}
        >
          Not resolved
        </Button>
      </Flex>
    );
  }

  function ThirdSlide() {
    return (
      <Flex direction="column" h="full" position="relative" pt={16} pb="60px">
        <Box flex="1" overflowY="auto">
          <Text textStyle="h5" color="neutral.800" mb={4}>
            Seems like you need some more help.
          </Text>

          <Text textStyle="button" color="neutral.700">
            Please reach out to our live chat! An expert from our team will work
            with you to resolve the issue.
          </Text>
        </Box>
        <Button
          variant="primary"
          position="absolute"
          bottom={4}
          left={0}
          right={0}
          onClick={() => toggleZendeskChat(true)}
        >
          <Icon as={HeadsetIcon} boxSize={6} color="primary.800" /> Chat with us
        </Button>
      </Flex>
    );
  }

  useEffect(() => {
    const handleBackButton = () => {
      if (currentSlide > 0) {
        setCurrentSlide(currentSlide - 1);
        swiperInstance?.slidePrev();
        return true;
      }
      return false;
    };

    const unsubscribe = registerBackHandler(handleBackButton);
    return () => unsubscribe();
  }, [currentSlide, swiperInstance]);

  return (
    <Swiper
      style={{
        width: '100%',
        height: '100%',
      }}
      allowTouchMove={false}
      onSwiper={setSwiperInstance}
      onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
    >
      <SwiperSlide>
        <FirstSlide />
      </SwiperSlide>

      <SwiperSlide>
        <SecondSlide />
      </SwiperSlide>

      <SwiperSlide>
        <ThirdSlide />
      </SwiperSlide>
    </Swiper>
  );
}
