import { extendTheme } from '@chakra-ui/react';

import { addAlpha, colorsNew } from '@strive/theme/tokens';

import { theme as baseTheme } from './theme';

// Create darker variations of the existing colors
const darkPrimary = {
  ...colorsNew.primary,
  900: '#000e1a', // Darker than the original 900
};

// Adjusted neutral palette with better contrast
const darkNeutral = {
  ...colorsNew.neutral,
  white: '#FFFFFF',
  50: '#e0e0e0', // Lighter for better contrast against dark bg
  100: '#d0d0d0', // Lighter for better contrast
  200: '#b0b0b0',
  300: '#909090',
  400: '#707070',
  500: '#505050',
  600: '#404040',
  700: '#303030',
  800: '#202020',
  900: '#101010',
  black: '#000000',
};

const darkElevation = {
  '0dp': '#000000',
  '1dp': '#121212',
  '2dp': '#161616',
  '3dp': '#1c1c1c',
  '4dp': '#202020',
  '6dp': '#252525',
  '8dp': '#2a2a2a',
  '12dp': '#323232',
  '16dp': '#353535',
  '24dp': '#383838',
};

// Override the global styles for dark mode
const darkGlobalStyles = {
  ...baseTheme.styles.global,
  body: {
    ...baseTheme.styles.global.body,
    backgroundColor: darkElevation['0dp'],
    color: darkNeutral.white,
  },
  // Improve contrast for search and results
  'input, select, textarea': {
    color: darkNeutral[50],
    _placeholder: {
      color: darkNeutral[300],
    },
  },
  // Ensure list items and results have good contrast
  'li, .result-item': {
    color: darkNeutral[50],
  },
};

// Create a dark version of the strive object
const darkStrive = {
  ...baseTheme.strive,
  primary: darkPrimary['800'],
  secondary: darkPrimary['500'],
  background: `linear-gradient(90deg, ${addAlpha(
    '#121212',
    0.26,
  )} 0%, #121212 100%)`,
  statusbar: darkPrimary['900'],
  card: darkElevation['2dp'],
  cardText: darkNeutral['50'], // Increased contrast
  cardTextSecondary: darkNeutral['100'], // Increased contrast
  cardTextTertiary: darkNeutral['200'], // Increased contrast
  card2: {
    bg: darkElevation['3dp'],
    bgHover: darkElevation['4dp'],
    fg: darkNeutral['50'],
  },
  card3: {
    bg: darkElevation['4dp'],
    bgHover: darkElevation['6dp'],
    fg: darkNeutral['50'],
  },
  card4: {
    bg: darkElevation['6dp'],
    bgHover: darkElevation['8dp'],
    fg: darkNeutral['50'],
  },
  card5: {
    bg: darkPrimary['800'],
    fg: darkNeutral['50'],
  },
  brand: darkPrimary['800'],
  // Search specific colors with better contrast
  search: {
    bg: darkElevation['2dp'],
    inputBg: darkElevation['4dp'],
    inputBorder: darkNeutral['600'],
    inputText: darkNeutral['50'],
    resultBg: darkElevation['3dp'],
    resultText: darkNeutral['50'],
    resultHoverBg: darkElevation['4dp'],
    highlight: darkPrimary['400'],
  },
};

// Define dark mode input styles with better contrast
const darkInputSelectStyles = {
  variants: {
    default: {
      field: {
        color: darkNeutral['50'], // Lighter for better contrast
        bg: darkElevation['3dp'],
        border: '1px solid',
        borderColor: darkNeutral['600'],
        _placeholder: {
          color: darkNeutral['300'], // Better contrast for placeholders
        },
        _focus: {
          fg: darkNeutral['50'],
          bg: darkElevation['4dp'],
          borderColor: darkPrimary['500'], // Brighter on focus for better visibility
        },
        '> option, > optgroup': {
          bg: darkElevation['2dp'],
        },
      },
    },
    '1dp': {
      field: {
        bg: darkElevation['2dp'],
        textStyles: 'copy_bold',
        color: darkNeutral['50'], // Increased contrast
        border: '1px solid',
        borderColor: darkNeutral['600'],
        py: '3',
      },
    },
    search: {
      field: {
        color: darkNeutral['50'],
        bg: darkElevation['3dp'],
        border: '1px solid',
        borderColor: darkNeutral['600'],
        _placeholder: {
          color: darkNeutral['300'],
        },
        _focus: {
          bg: darkElevation['4dp'],
          borderColor: darkPrimary['500'],
          boxShadow: `0 0 0 1px ${darkPrimary['500']}`,
        },
      },
    },
  },
};

// Define dark mode components with enhanced contrast
const darkComponents = {
  ...baseTheme.components,
  Input: {
    ...baseTheme.components.Input,
    variants: {
      ...baseTheme.components.Input.variants,
      ...darkInputSelectStyles.variants,
    },
  },
  Select: {
    ...baseTheme.components.Select,
    variants: {
      ...baseTheme.components.Select.variants,
      ...darkInputSelectStyles.variants,
    },
  },
  Textarea: {
    ...baseTheme.components.Textarea,
    variants: {
      ...baseTheme.components.Textarea.variants,
      ...darkInputSelectStyles.variants,
    },
  },
  Button: {
    ...baseTheme.components.Button,
    variants: {
      ...baseTheme.components.Button.variants,
      primary: () => ({
        bg: darkPrimary['700'],
        color: darkNeutral.white,
        fontWeight: 'bold',
        borderRadius: 'button',
        py: 6,
        _hover: {
          bg: darkPrimary['600'],
        },
        _disabled: {
          bg: darkNeutral['600'],
          cursor: 'not-allowed',
          pointerEvents: 'none',
        },
      }),
      secondary: () => ({
        bg: darkElevation['3dp'],
        color: darkNeutral.white,
        fontWeight: 'bold',
        borderRadius: 'button',
        py: 6,
        border: '1px solid',
        borderColor: darkNeutral['600'],
        _hover: {
          bg: darkElevation['4dp'],
        },
      }),
      // Add search result button style
      searchResult: () => ({
        justifyContent: 'flex-start',
        bg: darkElevation['2dp'],
        color: darkNeutral['50'],
        width: '100%',
        textAlign: 'left',
        fontWeight: 'normal',
        p: 3,
        borderRadius: 4,
        _hover: {
          bg: darkElevation['3dp'],
        },
        _active: {
          bg: darkElevation['4dp'],
        },
      }),
    },
  },
  Modal: {
    ...baseTheme.components.Modal,
    baseStyle: {
      ...baseTheme.components.Modal.baseStyle,
      dialog: {
        ...baseTheme.components.Modal.baseStyle.dialog,
        bg: darkElevation['2dp'],
        color: darkNeutral.white,
      },
    },
  },
  Tabs: {
    ...baseTheme.components.Tabs,
    variants: {
      ...baseTheme.components.Tabs.variants,
      enclosed: {
        tab: {
          color: darkNeutral['200'],
          _selected: {
            color: darkPrimary['300'], // Brighter for better visibility
            bg: darkElevation['2dp'],
          },
          _hover: {
            color: darkNeutral['100'],
          },
        },
        tablist: {
          bg: darkElevation['1dp'],
          borderColor: darkNeutral['700'],
        },
        tabpanel: {
          bg: darkElevation['2dp'],
        },
      },
    },
  },
  // Add specific styling for search components
  InputGroup: {
    ...baseTheme.components.InputGroup,
    baseStyle: {
      ...baseTheme.components.InputGroup?.baseStyle,
      addon: {
        color: darkNeutral['200'],
        bg: 'transparent',
      },
    },
  },
  Menu: {
    ...baseTheme.components.Menu,
    baseStyle: {
      ...baseTheme.components.Menu?.baseStyle,
      list: {
        bg: darkElevation['3dp'],
        borderColor: darkNeutral['700'],
      },
      item: {
        color: darkNeutral['100'],
        _hover: {
          bg: darkElevation['4dp'],
        },
        _focus: {
          bg: darkElevation['4dp'],
        },
      },
    },
  },
  List: {
    ...baseTheme.components.List,
    baseStyle: {
      ...baseTheme.components.List?.baseStyle,
      container: {
        color: darkNeutral['100'],
      },
      item: {
        _hover: {
          bg: darkElevation['3dp'],
        },
      },
    },
  },
};

// Create the extended theme
export const coachDarkTheme = extendTheme({
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    primary: darkPrimary,
    neutral: darkNeutral,
    elevation: darkElevation,
    bg: {
      primary: `linear-gradient(90deg, ${addAlpha(
        '#121212',
        0.26,
      )} 0%, #121212 100%)`,
    },
  },
  styles: {
    ...baseTheme.styles,
    global: darkGlobalStyles,
  },
  strive: darkStrive,
  components: darkComponents,
  config: {
    ...baseTheme.config,
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
});
