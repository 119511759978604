import { Box, Flex, Text } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { HelpCenterItem } from '../../help-center-item';

export type ContentType =
  | 'whoopWhenToWear'
  | 'whoopMissingData'
  | 'whoopGettingStarted'
  | 'whoopSyncFrequency'
  | 'whoopDataCollection'
  | 'whoopDataProtection';

interface HelpCenterContentProps {
  onSelectContent: (content: ContentType) => void;
}

export function WhoopHelpPage({ onSelectContent }: HelpCenterContentProps) {
  const swiper = useSwiper();
  const handleContentSelect = (content: ContentType) => {
    onSelectContent(content);
    swiper.slideTo(1);
  };

  return (
    <Flex direction="column" h="full">
      <Flex direction="column" gap={2} flex="1">
        <Text textStyle="copy_small" color="neutral.800">
          WHOOP Basics
        </Text>

        <Box w={'full'} bg={'neutral.100'} rounded={'card'} mb={2} px={4}>
          <HelpCenterItem
            title="What data does Strive collect from WHOOP?"
            showDivider
            onClick={() => handleContentSelect('whoopDataCollection')}
          />
          <HelpCenterItem
            title="How is my data protected?"
            onClick={() => handleContentSelect('whoopDataProtection')}
          />
        </Box>
        <Text textStyle="copy_small" color="neutral.800">
          Get Started with WHOOP
        </Text>

        <Box w={'full'} bg={'neutral.100'} rounded={'card'} mb={2} px={4}>
          <HelpCenterItem
            title="What should I do when I receive my Whoop?"
            showDivider
            onClick={() => handleContentSelect('whoopGettingStarted')}
          />
          <HelpCenterItem
            title="When should I wear my WHOOP?"
            showDivider
            onClick={() => handleContentSelect('whoopWhenToWear')}
          />
          <HelpCenterItem
            title="How often should I sync my WHOOP to the Strive app?"
            showDivider
            onClick={() => handleContentSelect('whoopSyncFrequency')}
          />
          <HelpCenterItem
            title="Why is my data missing?"
            onClick={() => handleContentSelect('whoopMissingData')}
          />
        </Box>
      </Flex>
    </Flex>
  );
}
