import { Box, Flex, Text } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { HelpCenterItem } from '../../help-center-item';

export type ContentType =
  | 'strivewarePairingIssue'
  | 'strivewareSyncingIssue'
  | 'strivewareBasics'
  | 'strivewareDataCollection'
  | 'strivewareDataProtection'
  | 'strivewareGettingStarted'
  | 'strivewareSyncingGuide'
  | 'strivewareWhenToWear'
  | 'strivewareChargingGuide'
  | 'strivewareCareGuide';

interface HelpCenterContentProps {
  onSelectContent: (content: ContentType) => void;
}

export function StrivewareHelpPage({
  onSelectContent,
}: HelpCenterContentProps) {
  const swiper = useSwiper();
  const handleContentSelect = (content: ContentType) => {
    onSelectContent(content);
    swiper.slideTo(1);
  };

  return (
    <Flex direction="column" h="full" gap={2} flex="1" overflowY="auto" pb={4}>
      <Text textStyle="copy_small" color="neutral.800">
        StriveWare Troubleshoot
      </Text>

      <Box w={'full'} bg={'neutral.100'} rounded={'card'} mb={2} px={4}>
        <HelpCenterItem
          title="Pairing issue"
          showDivider
          onClick={() => handleContentSelect('strivewarePairingIssue')}
        />
        <HelpCenterItem
          title="Syncing issue"
          onClick={() => handleContentSelect('strivewareSyncingIssue')}
        />
      </Box>

      <Text textStyle="copy_small" color="neutral.800">
        StriveWare Basics
      </Text>

      <Box w={'full'} bg={'neutral.100'} rounded={'card'} mb={2} px={4}>
        <HelpCenterItem
          title="What is StriveWare?"
          showDivider
          onClick={() => handleContentSelect('strivewareBasics')}
        />
        <HelpCenterItem
          title="What data does StriveWare collect?"
          showDivider
          onClick={() => handleContentSelect('strivewareDataCollection')}
        />
        <HelpCenterItem
          title="How is my data protected?"
          onClick={() => handleContentSelect('strivewareDataProtection')}
        />
      </Box>
      <Text textStyle="copy_small" color="neutral.800">
        Get Started with StriveWare
      </Text>

      <Box w={'full'} bg={'neutral.100'} rounded={'card'} mb={2} px={4}>
        <HelpCenterItem
          title="What should I do when I receive my StriveWare?"
          showDivider
          onClick={() => handleContentSelect('strivewareGettingStarted')}
        />
        <HelpCenterItem
          title="When should I wear my StriveWare?"
          showDivider
          onClick={() => handleContentSelect('strivewareWhenToWear')}
        />
        <HelpCenterItem
          title="How often should I sync my StriveWare to the Strive app?"
          onClick={() => handleContentSelect('strivewareSyncingGuide')}
        />
        <HelpCenterItem
          title="How often should I charge my StriveWare?"
          onClick={() => handleContentSelect('strivewareChargingGuide')}
        />
        <HelpCenterItem
          title="How should I care for my StriveWare?"
          onClick={() => handleContentSelect('strivewareCareGuide')}
        />
      </Box>
    </Flex>
  );
}
