import React, { useState } from 'react';

import { BaseHelpSwiper } from '../../base-help-swiper';
import {
  CareIssueContent,
  ChargingIssueContent,
  DataCollectionContent,
  DataProtectionContent,
  PairingIssueContent,
  SyncingGetStartedContent,
  SyncingIssueContent,
  WhatIsStrivewareContent,
  WhatShouldIDoContent,
  WhenToWearStriveWareContent,
} from './components';
import { ContentType, StrivewareHelpPage } from './striveware-help-page';

export function StrivewareHelpSwiper({ onClose }: { onClose: () => void }) {
  const [selectedContent, setSelectedContent] =
    useState<ContentType>('strivewareBasics');

  const getDetailTitle = () => {
    switch (selectedContent) {
      // Troubleshoot section
      case 'strivewarePairingIssue':
        return 'Pairing issue';
      case 'strivewareSyncingIssue':
        return 'Syncing issue';

      // Basics section
      case 'strivewareBasics':
      case 'strivewareDataCollection':
      case 'strivewareDataProtection':
        return 'StriveWare Basics';

      // Get Started section
      case 'strivewareGettingStarted':
      case 'strivewareWhenToWear':
      case 'strivewareSyncingGuide':
      case 'strivewareChargingGuide':
      case 'strivewareCareGuide':
        return 'Get Started with StriveWare';

      default:
        return '';
    }
  };

  const getDetailContent = () => {
    switch (selectedContent) {
      // Troubleshoot section
      case 'strivewarePairingIssue':
        return <PairingIssueContent />;
      case 'strivewareSyncingIssue':
        return <SyncingIssueContent />;

      // Basics section
      case 'strivewareBasics':
        return <WhatIsStrivewareContent />;
      case 'strivewareDataCollection':
        return <DataCollectionContent />;
      case 'strivewareDataProtection':
        return <DataProtectionContent />;

      // Get Started section
      case 'strivewareGettingStarted':
        return <WhatShouldIDoContent />;
      case 'strivewareWhenToWear':
        return <WhenToWearStriveWareContent />;
      case 'strivewareSyncingGuide':
        return <SyncingGetStartedContent />;
      case 'strivewareChargingGuide':
        return <ChargingIssueContent />;
      case 'strivewareCareGuide':
        return <CareIssueContent />;

      default:
        return null;
    }
  };

  const getArticleTitle = () => {
    switch (selectedContent) {
      // Troubleshoot section
      case 'strivewarePairingIssue':
        return 'StriveWare - Pairing Issue Troubleshooting';
      case 'strivewareSyncingIssue':
        return 'StriveWare - Syncing Issue Troubleshooting';

      // Basics section
      case 'strivewareBasics':
        return 'StriveWare - What is StriveWare';
      case 'strivewareDataCollection':
        return 'StriveWare - Data Collection Information';
      case 'strivewareDataProtection':
        return 'StriveWare - Data Protection Information';

      // Get Started section
      case 'strivewareGettingStarted':
        return 'StriveWare - Getting Started Guide';
      case 'strivewareWhenToWear':
        return 'StriveWare - When to Wear Guide';
      case 'strivewareSyncingGuide':
        return 'StriveWare - Data Syncing Guide';
      case 'strivewareChargingGuide':
        return 'StriveWare - Charging Guide';
      case 'strivewareCareGuide':
        return 'StriveWare - Care and Maintenance Guide';

      default:
        return '';
    }
  };

  // Determine if feedback should be shown based on content type
  const shouldShowFeedback = () => {
    // Don't show feedback for sync and pairing issues
    return (
      selectedContent !== 'strivewarePairingIssue' &&
      selectedContent !== 'strivewareSyncingIssue'
    );
  };

  return (
    <>
      <BaseHelpSwiper
        onClose={onClose}
        mainContent={
          <StrivewareHelpPage
            onSelectContent={(content: ContentType) => {
              setSelectedContent(content);
            }}
          />
        }
        detailContent={getDetailContent()}
        detailTitle={getDetailTitle()}
        articleTitle={getArticleTitle()}
        showFeedback={shouldShowFeedback()}
      />
    </>
  );
}
