import { Flex, Text } from '@chakra-ui/react';

export function WhenToWearStriveWareContent() {
  return (
    <Flex direction="column" gap={4} w="full" pt={16}>
      <Text textStyle="h5" color="neutral.800">
        When should I wear my StriveWare?
      </Text>

      <Text textStyle="button" color="neutral.700">
        Working in clinical spaces does not always allow you to wear jewelry.
        This is why we built our platform around metrics that can be collected
        during sleep.
      </Text>

      <Text textStyle="button" color="neutral.700">
        You are welcome to wear your device throughout the day if your career
        and lifestyle allow, but no additional data points will result from
        doing so.
      </Text>
    </Flex>
  );
}
