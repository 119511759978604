;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"9525c4ec9797f113fc698f5625648ac0bf2bf953"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import * as Sentry from '@sentry/nextjs';
import { escapeRegExp } from 'lodash-es';

import { apiHost, getResourcesS3Url } from '@strive/api';

const SENTRY_DSN =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN || '';

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT || 'development',
    release: process.env.SENTRY_RELEASE || undefined,
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps

    // @see https://docs.sentry.io/platforms/javascript/session-replay/configuration/
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1.0,

    // @see https://docs.sentry.io/platforms/javascript/guides/nextjs/tracing/instrumentation/automatic-instrumentation/#configuration-options
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      new RegExp(`^${escapeRegExp(apiHost)}`),
    ],

    integrations: [
      Sentry.browserTracingIntegration(),

      // @see https://docs.sentry.io/platforms/javascript/session-replay/privacy/
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [apiHost, getResourcesS3Url()],
        networkRequestHeaders: ['Timezone', 'X-Trace-Id'],
      }),
    ],
  });
}
