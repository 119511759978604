import { MutationOptions, useQueryClient } from '@tanstack/react-query';
import { ZodiosResponseByAlias } from '@zodios/core';
import produce from 'immer';

import { HomeScreenResponse } from '@arena-labs/strive2-coaching';
import { $API, striveAppApi } from '@strive/api';

const homepageKey = $API.getKeyByAlias('getHomepage', {});
const pillarsKey = $API.getKeyByAlias('getPillars', {});

// Zodios doesn't currently support inferring the type of
// the QueryMutationContext from the `onMutate` callback.
// So we'll define this and then use type assertions later
type LogPracticeMutationContext = {
  previousHomepage?: HomeScreenResponse;
  previousPillars?: ZodiosResponseByAlias<typeof striveAppApi, 'getPillars'>;
};

export function useLogPractice(options: Pick<MutationOptions, 'retry'> = {}) {
  const queryClient = useQueryClient();

  return $API.useLogPractice(
    {},
    {
      ...options,
      onMutate({ practice }): LogPracticeMutationContext {
        // Cancel any outgoing refetches so they don't overwrite our optimistic update
        queryClient.cancelQueries({ queryKey: homepageKey });
        queryClient.cancelQueries({ queryKey: pillarsKey });

        // Snapshot the previous values
        const previousHomepage =
          queryClient.getQueryData<HomeScreenResponse>(homepageKey);
        const previousPillars =
          queryClient.getQueryData<
            ZodiosResponseByAlias<typeof striveAppApi, 'getPillars'>
          >(pillarsKey);

        // Optimistically update homepage
        if (previousHomepage) {
          queryClient.setQueryData(
            homepageKey,
            produce(previousHomepage, (draft) => {
              // Update in current pillar practices
              const pillarPractices =
                draft?.pillar_data?.current_pillar?.practices;
              if (pillarPractices) {
                const match = pillarPractices.find(
                  (pr) => pr.slug === practice,
                );
                if (match) {
                  match.log_count += 1;
                  match.completed_today = true;
                  match.status = 'completed';
                }
              }

              // Update in learning session practices
              const sessionPractices = draft?.learning_session?.practices;
              if (sessionPractices) {
                const match = sessionPractices.find(
                  (pr) => pr.slug === practice,
                );
                if (match) {
                  match.log_count += 1;
                  match.completed_today = true;
                  match.status = 'completed';
                }
              }
            }),
          );
        }

        // Optimistically update pillars
        if (previousPillars) {
          queryClient.setQueryData(
            pillarsKey,
            produce(previousPillars, (draft) => {
              for (const pillar of draft) {
                const match = pillar.practices.find(
                  (pr) => pr.slug === practice,
                );
                if (match) {
                  match.log_count += 1;
                  match.completed_today = true;
                  match.status = 'completed';
                  break; // Practice found and updated, no need to continue
                }
              }
            }),
          );
        }

        return { previousHomepage, previousPillars };
      },
      onError(_error, _variables, context) {
        const ctx = context as LogPracticeMutationContext;
        // If the mutation fails, roll back to the previous values
        if (ctx?.previousHomepage) {
          queryClient.setQueryData(homepageKey, ctx.previousHomepage);
        }
        if (ctx?.previousPillars) {
          queryClient.setQueryData(pillarsKey, ctx.previousPillars);
        }
      },
      onSettled() {
        // Always refetch after error or success to ensure consistency
        queryClient.invalidateQueries({
          queryKey: homepageKey,
          refetchType: 'active',
          exact: true,
        });
        queryClient.invalidateQueries({
          queryKey: pillarsKey,
          refetchType: 'active',
          exact: true,
        });
      },
    },
  );
}

export function useSkipPractice(options: Pick<MutationOptions, 'retry'> = {}) {
  const queryClient = useQueryClient();

  return $API.useSkipPractice(
    {},
    {
      ...options,
      onSettled() {
        queryClient.invalidateQueries($API.getKeyByAlias('getScorecard'));
        queryClient.invalidateQueries({
          queryKey: pillarsKey,
          refetchType: 'active',
          exact: true,
        });
        queryClient.invalidateQueries({
          queryKey: homepageKey,
          refetchType: 'active',
          exact: true,
        });
      },
    },
  );
}
