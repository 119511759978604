import { useEffect, useState } from 'react';
import { Box, Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import {
  ChevronLeftIcon,
  CloseIcon,
  SupportNew,
  useResponsive,
} from '@arena-labs/strive2-ui';

import { handleBack } from '../../lib/back-handler';

//Todo
export function HelpCenterBanner({
  onClose,
  showIcon = true,
  title,
}: {
  onClose: () => void;
  showIcon?: boolean;
  title?: string;
}) {
  const swiper = useSwiper();
  const [isInitialSlide, setIsInitialSlide] = useState(
    !swiper.activeIndex || swiper.activeIndex === 0,
  );

  useEffect(() => {
    const handleSlideChange = () => {
      setIsInitialSlide(!swiper.activeIndex || swiper.activeIndex === 0);
    };

    swiper.on('slideChange', handleSlideChange);
    return () => {
      swiper.off('slideChange', handleSlideChange);
    };
  }, [swiper]);

  const handleBackClick = () => {
    if (swiper.activeIndex && swiper.activeIndex > 0) {
      // First try to handle back in nested components
      if (!handleBack()) {
        // If no nested component handled back, go back in the main swiper
        swiper.slidePrev();
      }
    } else {
      onClose();
    }
  };

  const rs = useResponsive();

  return (
    <Flex flexDirection={'column'} w="full" pt={'env(safe-area-inset-top)'}>
      <Box position={'relative'} w={'full'}>
        <IconButton
          variant={'unstyled'}
          position={'absolute'}
          left={isInitialSlide ? 'auto' : 0}
          right={isInitialSlide ? 0 : 'auto'}
          onClick={handleBackClick}
          aria-label={isInitialSlide ? 'close' : 'back'}
          p={0}
          textAlign={'left'}
          mt={isInitialSlide ? 2 : 0}
        >
          <Icon
            as={isInitialSlide ? CloseIcon : ChevronLeftIcon}
            mt={'2'}
            color={'neutral.700'}
            boxSize={isInitialSlide ? 8 : 5}
          />
        </IconButton>
        {title && (
          <Text w={'full'} textAlign={'center'} textStyle={'copy_bold'} mt={2}>
            {title}
          </Text>
        )}
      </Box>

      {showIcon && (
        <Flex w={'full'} gap={3} alignItems={'center'} mt={12} pl={4}>
          <Icon
            as={SupportNew}
            ml={-3}
            boxSize={rs({ base: '76px', xs: '56px' })}
            mb="6"
            color={'primary.400'}
          />

          <Text textStyle={rs({ base: 'h5', xs: 'copy_bold' })} mt={-4}>
            Strive Support
          </Text>
        </Flex>
      )}
    </Flex>
  );
}
