import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  BoxProps,
  CloseButton,
  Flex,
  keyframes,
} from '@chakra-ui/react';

import { useResponsive } from '../utils/use-responsive';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

type AlertBoxContent = string | React.ReactNode;

export type AlertBoxProps = {
  canClose?: boolean;
  showIcon?: boolean | BoxProps['as'];
  iconAnimation?: 'spin';
} & (
  | {
      title: AlertBoxContent;
      description?: AlertBoxContent;
    }
  | {
      title?: AlertBoxContent;
      description: AlertBoxContent;
    }
) &
  AlertProps;

export function AlertBox({
  showIcon,
  title,
  description,
  iconAnimation,
  canClose,
  ...props
}: AlertBoxProps) {
  const rs = useResponsive();
  return (
    <Alert
      flexDirection="column"
      overflow="unset"
      gap={rs({ base: 2, xs: 0 })}
      py={rs({ base: 2, xs: 1 })}
      {...props}
    >
      {canClose && <CloseButton position="absolute" right={2} top={2} />}
      <Flex alignItems="center">
        {showIcon === true ? (
          <AlertIcon boxSize={4} />
        ) : showIcon ? (
          <AlertIcon
            boxSize={6}
            as={showIcon}
            animation={
              iconAnimation === 'spin'
                ? `${spin} 2s linear infinite`
                : undefined
            }
          />
        ) : null}
        <AlertTitle>{title}</AlertTitle>
      </Flex>

      {description ? (
        <AlertDescription w="full" lineHeight={rs({ base: 1.5, xs: 1.2 })}>
          {description}
        </AlertDescription>
      ) : null}
    </Alert>
  );
}
