import { useEffect, useState } from 'react';
import { useAnimate } from 'framer-motion';

export function useDrawerTouch({ onClose }: { onClose: () => void }) {
  const [scope, animate] = useAnimate();
  const [deltaY, setDeltaY] = useState(0);

  const handleTouchStart = (event: TouchEvent) => {
    const touchStartY = event.touches[0]?.clientY;
    let localTranslateY = 0;

    const handleTouchMove = (event: TouchEvent) => {
      const touchEndY = event.touches[0]?.clientY;
      const newDeltaY = touchStartY && touchEndY ? touchStartY - touchEndY : 0;
      setDeltaY(newDeltaY);

      if (newDeltaY > 0 && newDeltaY < 200 && scope.current) {
        animate(
          [
            [scope.current, { translateY: -newDeltaY }],
            [
              '.drawer-overlay-backdrop',
              { opacity: Math.max(0, 1 - newDeltaY / 100) },
            ],
          ],
          { duration: 0 },
        );
      }

      if (newDeltaY >= 200 && scope.current) {
        animate(
          [
            [scope.current, { translateY: -1000 }],
            ['.drawer-overlay-backdrop', { opacity: 0 }],
            ['.safe-area-inset', { translateY: -1000 }],
          ],
          { duration: 0.5 },
        ).then(() => {
          localTranslateY = newDeltaY;
          window.removeEventListener('touchmove', handleTouchMove);
        });
      }
    };

    const handleTouchEnd = () => {
      if (localTranslateY < 200) {
        if (scope.current) {
          animate(
            [
              [scope.current, { translateY: 0 }],
              ['.drawer-overlay-backdrop', { opacity: 1 }],
            ],
            { duration: 0.3 },
          );
          setDeltaY(0);
        }
      } else {
        animate(
          [
            [scope.current, { translateY: -1000 }],
            ['.drawer-overlay-backdrop', { opacity: 0 }],
            ['.safe-area-inset', { translateY: -1000 }],
          ],
          { duration: 0.5 },
        ).then(onClose);
      }
      window.removeEventListener('touchmove', handleTouchMove);
    };

    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd, { once: true });
  };

  useEffect(() => {
    window.addEventListener('touchstart', handleTouchStart);
    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return { scope, animate, deltaY };
}
