import { Flex, Text } from '@chakra-ui/react';

export function ChargingIssueContent() {
  return (
    <Flex direction="column" gap={4} w="full" pt={16}>
      <Text textStyle="h5" color="neutral.800">
        How often should I charge my StriveWare?
      </Text>

      <Text textStyle="button" color="neutral.700">
        We advise that you charge your ring daily to ensure that a low battery
        does not impact your data collection. The ring will take 60-90 minutes
        to charge fully. You can monitor your battery level by clicking the ring
        icon in the top right corner of your app.
      </Text>
    </Flex>
  );
}
