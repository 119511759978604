import { Flex, ListItem, OrderedList, Text } from '@chakra-ui/react';

export function WhatShouldIDoContent() {
  return (
    <Flex direction="column" gap={4} w="full" pt={16}>
      <Text textStyle="h5" color="neutral.800">
        What should I do when I receive my StriveWare?
      </Text>

      <OrderedList pl={2}>
        <ListItem>
          <Text textStyle="button" color="neutral.700">
            Charge the ring fully.
          </Text>
        </ListItem>

        <ListItem>
          <Text textStyle="button" color="neutral.700">
            Wear it for one full sleep before the initial setup.
          </Text>
        </ListItem>

        <ListItem>
          <Text textStyle="button" color="neutral.700">
            Pair your ring and sync your data to the Strive app via Bluetooth.
          </Text>
        </ListItem>
      </OrderedList>
      <Text textStyle="button" color="neutral.700">
        Once synced, your HRV and Sleep data will populate in the progress tab.
        Wear your ring every time you sleep to track your progress.
      </Text>
    </Flex>
  );
}
