import {
  Box,
  BoxProps,
  CheckboxProps,
  useCheckbox,
  useStyleConfig,
} from '@chakra-ui/react';

export interface CheckboxButtonProps extends CheckboxProps {
  wrapper?: BoxProps;
  checkbox?: BoxProps;
}

export function CheckboxButton(props: CheckboxButtonProps) {
  const styles = useStyleConfig('CheckboxButton', props);
  const { getInputProps, getCheckboxProps } = useCheckbox(props);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputProps = getInputProps() as any;
  const checkboxProps = getCheckboxProps() as CheckboxProps;
  const labelId = props.checkbox?.id ?? `checkbox-${props.name}-${props.value}`;

  return (
    <Box as="label" {...props.wrapper} position="relative">
      <input
        {...inputProps}
        aria-labelledby={labelId}
        style={{
          position: 'absolute',
          opacity: 0,
          width: '100%',
          height: '100%',
        }}
      />
      <Box
        {...checkboxProps}
        display="grid"
        h="full"
        id={labelId}
        {...props.checkbox}
        __css={styles}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export const CheckboxButtonTheme = {
  // The styles all button have in common
  baseStyle: {
    borderRadius: 'button',
    cursor: 'pointer',
    boxShadow: 'base',
    borderColor: 'transparent',
    color: 'white',
    _checked: {
      bg: 'strive.colorScheme.primary.accent',
      color: 'strive.colorScheme.primary.main',
      borderColor: 'currentColor',
      fontWeight: 'normal',
    },
    _focus: {
      boxShadow: 'outline',
    },
  },
  sizes: {
    xs: {
      fontSize: 'copy_small',
      px: 1,
      py: 0.5,
    },
    sm: {
      fontSize: 'copy_small',
      px: 2,
      py: 1,
    },
    md: {
      fontSize: 'copy',
      px: 3,
      py: 2,
    },
    lg: {
      fontSize: 'copy',
      px: 4,
      py: 3,
    },
  },
  variants: {
    secondary: {
      color: 'primary.800',
      textAlign: 'center',
      bg: `neutral.50`,
      border: '1px solid #C3C3C3',
      py: '10px',
      _checked: {
        borderColor: 'primary.800',
      },
    },
    solid: ({ colorScheme = 'primary' }) => ({
      bg: `strive.colorScheme.${colorScheme}.main`,
      color: `strive.colorScheme.${colorScheme}.text`,
    }),
    outline: {
      bg: 'transparent',
      border: '1px solid',
    },
    'outline-selected': ({ colorScheme = 'primary' }) => ({
      bg: `strive.colorScheme.${colorScheme}.main`,
      color: `strive.colorScheme.${colorScheme}.text`,
      border: '2px solid ',
      borderColor: 'transparent',
      fontWeight: 'bold',
      boxShadow: 'md',
      _checked: {
        fontWeight: 'bold',
        borderColor: 'currentColor',
        bg: `strive.colorScheme.${colorScheme}.main`,
        color: `strive.colorScheme.${colorScheme}.text`,
      },
    }),
    pairing: () => ({
      bg: 'neutral.50',
      color: 'neutral.600',
      boxShadow: 'none',
      border: '2px solid',
      borderColor: 'neutral.100',
      w: '100%',
      _checked: {
        borderColor: 'primary.800',
      },
    }),
    glass: {
      bg: 'strive.glass',
      color: 'white',
      _checked: {
        bg: 'strive.glass',
        color: 'white',
        fontWeight: 'bold',
        border: '1.5px solid',
        borderColor: 'currentColor',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
  },
};
