import { ReactNode } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { FeedbackContent } from './content/striveware/components/feedback';
import { HelpCenterBanner } from './help-center-banner';
import { HelpPageFooter } from './help-page-footer';

interface BaseHelpSwiperProps {
  onClose: () => void;
  mainContent: ReactNode;
  detailContent: ReactNode;
  detailTitle?: string;
  articleTitle?: string;
  showFeedback?: boolean;
}

export function BaseHelpSwiper({
  onClose,
  mainContent,
  detailContent,
  detailTitle,
  articleTitle,
  showFeedback = true,
}: BaseHelpSwiperProps) {
  return (
    <Swiper
      style={{
        width: '100%',
        height: '100%',
        paddingBottom: '24px',
        paddingLeft: '24px',
        paddingRight: '24px',
      }}
      spaceBetween={40}
      allowTouchMove={false}
    >
      <SwiperSlide
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <HelpCenterBanner onClose={onClose} />
        {mainContent}
        <Box pt={4}>
          <HelpPageFooter />
        </Box>
      </SwiperSlide>
      <SwiperSlide
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Flex direction="column" h="full" overflowY="auto">
          <HelpCenterBanner
            onClose={onClose}
            title={detailTitle}
            showIcon={false}
          />

          <Flex direction="column" flex="1" overflowY="auto">
            {detailContent}
            {articleTitle && showFeedback && (
              <FeedbackContent articleTitle={articleTitle} />
            )}
          </Flex>
        </Flex>
      </SwiperSlide>
    </Swiper>
  );
}
