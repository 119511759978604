import React from 'react';
import {
  Box,
  BoxProps,
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  Text,
} from '@chakra-ui/react';

import {
  BluetoothBlue,
  CloseIcon,
  useResponsive,
  ZStack,
} from '@arena-labs/strive2-ui';
import { StrivewareContext } from '@strive/wearable';

import { useSetupActor } from './hooks';

type SetupIntroProps = {
  onBack?: React.MouseEventHandler<HTMLAnchorElement>;
  onClose: () => void;
} & BoxProps;

export function SetupIntro({ onBack, onClose }: SetupIntroProps) {
  const setupActor = useSetupActor();
  const strivewareActor = StrivewareContext.useActorRef();

  const onContinue = () => {
    strivewareActor.send({ type: 'Unpair Device' });
    setupActor.send({ type: 'Next' });
  };
  const rs = useResponsive();
  return (
    <Flex direction="column" pb={6} h="full" align="center" gap={4}>
      <Flex gap="4" position={'relative'} w={'full'} px={4} pt={4}>
        <IconButton
          ml={'auto'}
          aria-label="Close"
          icon={<Icon as={CloseIcon} boxSize={6} />}
          onClick={() => onClose()}
        />
      </Flex>
      <Text textStyle={rs({ base: 'h1', xs: 'h3' })} mt="60px" px={4}>
        Plug in StriveWare for initial pairing.
      </Text>
      <Text textStyle="copy" color="neutral.600" px={4}>
        Keep <Icon as={BluetoothBlue} boxSize={6} mb={-1} /> Bluetooth on for
        real-time insights with StriveWare.
      </Text>
      <ZStack w={'full'} h={'full'} overflowY={'hidden'}>
        <Image
          ml={rs({ base: '0%', xs: '20%' })}
          mt={rs({ base: '-45px', xs: '-100px' })}
          src="/images/striveware/charge-cord.png"
          alt="plug in striveware"
          w={rs({ base: 'full', xs: '80%' })}
          height="auto"
        />
        <Image
          mt={rs({ base: '40px', xs: '0px' })}
          src="/images/striveware/striveware-power-case.webp"
          alt="plug in striveware"
          w={rs({ base: '90%', xs: '70%' })}
          mx={'auto'}
          height="auto"
        />
      </ZStack>
      <Box w={'full'} px={4}>
        <Button variant="primary" mt="auto" w="full" onClick={onContinue}>
          Start Pairing
        </Button>
      </Box>
    </Flex>
  );
}
