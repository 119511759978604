import { useEffect, useMemo } from 'react';
import {
  Box,
  Center,
  Flex,
  Grid,
  Icon,
  IconButton,
  Image,
  Text,
} from '@chakra-ui/react';
import { useAnimate } from 'framer-motion';

import {
  BackLink,
  CloseIcon,
  LoadingBubbles,
  LottieFile,
  useResponsive,
} from '@arena-labs/strive2-ui';

import { useSetupActor } from './hooks';

export function SetupScanning({ onClose }: { onClose: () => void }) {
  const rs = useResponsive();
  const animation = useMemo(
    () => import('./scanning-for-devices.lottie.json'),
    [],
  );
  const [scope, animate] = useAnimate();
  useEffect(() => {
    animate(
      '.rotate',
      { rotate: 720 },
      {
        duration: 3,
        repeat: Infinity,
        ease: 'linear',
      },
    );
  }, [animate]);

  const setupActor = useSetupActor();

  return (
    <Flex direction="column" px="4" py="4" alignItems="center" h="full">
      <Grid
        templateColumns="auto 1fr auto"
        alignItems="center"
        gap="4"
        w="full"
      >
        <BackLink
          href="/"
          color="neutral.800"
          onClick={(event) => {
            event.preventDefault();
            setupActor.send({ type: 'Back' });
          }}
        />
        <Text textStyle={'copy_bold'} textAlign={'center'}>
          Pair StriveWare
        </Text>
        <IconButton
          aria-label="Close"
          icon={<Icon as={CloseIcon} boxSize={6} />}
          onClick={() => onClose()}
        />
      </Grid>

      <Box my={'auto'} w={'auto'} h={'auto'} position={'relative'}>
        <Image
          alt=""
          src="/images/striveware/ring-ghost.png"
          w={'280px'}
          h={'auto'}
          opacity={'0.05'}
          mx={'auto'}
        />
        <Center
          position={'absolute'}
          top={'0'}
          left={'0'}
          w={'full'}
          h={'full'}
        >
          <LottieFile
            as={animation}
            boxSize={rs({ base: '280px', xs: '200px' })}
            mx={'auto'}
            className="rotate"
            mt={2}
          />
        </Center>
      </Box>

      <Flex align="center" gap="4" mt={'auto'} mb={4} ref={scope}>
        <Icon
          as={LoadingBubbles}
          boxSize="20px"
          color={'neutral.800'}
          className="rotate"
        />{' '}
        <Text textStyle="copy_small" color={'neutral.800'}>
          Connecting...
        </Text>
      </Flex>
    </Flex>
  );
}
