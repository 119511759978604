import React, { useCallback, useState } from 'react';
import {
  Button,
  chakra,
  // Circle,
  Divider,
  Fade,
  Flex,
  Text,
  // VStack,
} from '@chakra-ui/react';

import { useResponsive } from '@arena-labs/strive2-ui';
import {
  StrivewareContext,
  //  StrivewareSelector
} from '@strive/wearable';

import { useSupportHub } from '../../help-center/use-support-hub';
import {
  useUltimatelyReadyToPair,
  useUltimatelyReadyToSync,
} from '../device-content';
import { useWearableHub } from '../use-wearable-hub';
import { DeviceInfo } from './device-info';
import { StrivewareAlerts } from './striveware-alerts';
// import { DeviceActions } from './striveware-device-actions';
// import { useReadyToSync } from '../device-content';
// import { SlideComponentOptions } from '../types';
// import { DeviceActions } from './striveware-device-actions';
import { DeviceAlerts } from './striveware-device-alerts';
import { DeviceDetails } from './striveware-device-details';
import { UnpairDevice } from './unpair-device';

export function StrivewareDevice() {
  const rs = useResponsive();
  const strivewareActor = StrivewareContext.useActorRef();
  const supportHub = useSupportHub();
  const { onClose } = useWearableHub();
  const [isUnpairing, setIsUnpairing] = useState(false);
  const [_, setIsUltimatelyReadyToSync] = useUltimatelyReadyToSync();
  const [ultimatelyReadyToPair] = useUltimatelyReadyToPair();

  const handleSync = useCallback(() => {
    strivewareActor.send({ type: 'Sync' });
  }, [strivewareActor]);

  const { onTouchStart, onTouchMove, onTouchEnd } = useSwipeToRefresh(
    100,
    handleSync,
  );

  const handleDeviceDelete = () => {
    // Set unpairing state
    setIsUnpairing(true);

    // First send unpair command to state machine
    strivewareActor.send({ type: 'Unpair Device' });

    // Listen for completion of unpairing
    const subscription = strivewareActor.subscribe((state) => {
      if (state.matches({ 'Bluetooth Ready': 'Ready to Pair' })) {
        // Cleanup subscription
        subscription.unsubscribe();

        // Reset unpairing state
        setIsUnpairing(false);
      }
    });
  };

  const readyToPairAgain = StrivewareContext.useSelector((state) => {
    return Boolean(!state.context.pairedDevice && !ultimatelyReadyToPair);
  });

  const handleSwitchOverToSetup = () => {
    // First ensure we're in the success view
    if (readyToPairAgain) {
      // Then transition to setup after a delay
      setTimeout(() => {
        setIsUltimatelyReadyToSync(false);
      }, 1000); // Give enough time to see the success view
    }
  };

  const closeAndSwitchOverToSetup = () => {
    onClose();
    // First ensure we're in the success view
    if (readyToPairAgain) {
      // Then transition to setup after a delay
      setTimeout(() => {
        setIsUltimatelyReadyToSync(false);
      }, 1000); // Give enough time to see the success view
    }
  };

  return (
    <Flex
      direction="column"
      gap="4"
      w="full"
      h="100%"
      overflow="auto"
      bg={'neutral.white'}
      px={'6'}
      pb={8}
      pt={4}
      transition="height 0.3s ease-in-out"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      {readyToPairAgain ? (
        <Fade
          in={readyToPairAgain}
          transition={{ enter: { duration: 0.3 } }}
          style={{ paddingTop: '20px' }}
        >
          <chakra.div
            display="flex"
            flexDirection="column"
            gap="2"
            px={4}
            py={3}
            rounded={'card'}
            bg={'neutralAlpha.50'}
            justifyContent="center"
            alignItems="center"
          >
            <Text
              textStyle="copy"
              color="neutral.800"
              textAlign={'left'}
              width={'full'}
            >
              Device unpaired successfully!
            </Text>
            <Text textStyle="copy_small" color="neutral.800" pb={2}>
              You can pair a new device anytime by pressing the StriveWare icon.
            </Text>
            <Flex gap="6" w="full">
              <Button
                w="full"
                variant="secondary"
                onClick={closeAndSwitchOverToSetup}
              >
                Dismiss
              </Button>
              <Button
                w="full"
                variant="primary"
                onClick={handleSwitchOverToSetup}
              >
                Pair New Device
              </Button>
            </Flex>
          </chakra.div>
        </Fade>
      ) : (
        <Fade in={!readyToPairAgain} transition={{ enter: { duration: 0.3 } }}>
          <Flex direction="column" w="full" gap={rs({ base: 4, xs: 2 })}>
            <DeviceDetails />
            <DeviceAlerts />

            {/* code for resync in there */}
            {/* <DeviceActions /> */}

            {/* <Flex justify="space-around">
              <BluetoothTrafficLight />
              <ConnectionTrafficLight />
              <SyncTrafficLight />
            </Flex> */}

            <StrivewareAlerts />

            <Flex
              zIndex={1}
              direction="column"
              gap={rs({ base: 6, xs: 3 })}
              w="full"
              px="2"
              pt={4}
              borderColor="white"
              color="neutral.700"
            >
              <DeviceInfo />
              <Divider borderColor="neutral.200" />
              <UnpairDevice
                onUnpair={handleDeviceDelete}
                isUnpairing={isUnpairing}
              />
              <Divider borderColor="neutral.200" />
            </Flex>
          </Flex>
        </Fade>
      )}
      <Text
        textStyle="copy_small"
        color="neutral.800"
        w={'full'}
        textAlign={'center'}
      >
        Need Help? Get{' '}
        <chakra.span
          as="button"
          fontWeight={'bold'}
          textDecoration={'underline'}
          onClick={() => {
            onClose();
            supportHub.onOpen();
          }}
        >
          Strive Support
        </chakra.span>
        .
      </Text>
    </Flex>
  );
}

export const useSwipeToRefresh = (threshold = 100, onSwipe?: () => void) => {
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);

  const onTouchStart = useCallback((e: React.TouchEvent) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0]?.clientY ?? null);
  }, []);

  const onTouchMove = useCallback((e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0]?.clientY ?? null);
  }, []);

  const onTouchEnd = useCallback(() => {
    if (!touchStart || !touchEnd) return;

    const distance = touchEnd - touchStart;
    const isDownSwipe = distance > threshold;

    if (isDownSwipe) {
      onSwipe?.();
    }

    setTouchStart(null);
    setTouchEnd(null);
  }, [touchStart, touchEnd, threshold, onSwipe]);

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
};
