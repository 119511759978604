import { useEffect, useState } from 'react';
import { useMountedState } from 'react-use';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  HStack,
  Icon,
  Text,
  useCallbackRef,
} from '@chakra-ui/react';
import { Widget } from '@typeform/embed-react';
import { pickBy } from 'lodash-es';

import { GeneralTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { CloseIcon, ZStack } from '@arena-labs/strive2-ui';
import { useUserProfile } from '@strive/api';

const DEFAULT_AUTO_CLOSE_DELAY = 5000;

export type TypeformSubmitPayload = {
  responseId: string;
};

export type TypeformModalProps = {
  formId: string;
  title?: string;
  hidden?: Record<string, string>;
  autoClose?: boolean | number;
  onSubmitted?: (payload: TypeformSubmitPayload) => void;
  allowClose?: boolean;
  source?: 'appGate';
} & Omit<DrawerProps, 'children'>;

export function TypeformModal({
  formId,
  title,
  hidden = {},
  autoClose = DEFAULT_AUTO_CLOSE_DELAY,
  onSubmitted,
  allowClose,
  source,
  ...props
}: TypeformModalProps) {
  // Analytics & reset the form when the drawer opens
  const analytics = useAnalytics();
  useEffect(() => {
    if (!props.isOpen) return;
    !source && analytics.logEvent(GeneralTrackingEvent.TypeformModalOpen);
  }, [props.isOpen, analytics, source]);

  // Add in some user data as hidden fields
  const { data: user } = useUserProfile();
  const userProps = pickBy(
    {
      user_id: String(user?.id),
      team_id: String(user?.profile.team_id),
      first_name: String(user?.first_name),
    },
    (val) => !!val,
  );

  // Handle the submit event
  const isMounted = useMountedState();
  const handleSubmit = useCallbackRef((payload: TypeformSubmitPayload) => {
    // Send the responseId to the caller
    onSubmitted?.(payload);
    !source && analytics.logEvent(GeneralTrackingEvent.TypeformSubmitted);

    // If auto-close is enabled, set a timer to close the modal after a delay
    if (autoClose === false || autoClose == null) {
      return;
    }
    const closeMs =
      typeof autoClose === 'number' ? autoClose : DEFAULT_AUTO_CLOSE_DELAY;
    setTimeout(() => {
      if (isMounted()) {
        props.onClose();
      }
    }, closeMs);
  });

  const [isFormReady, setIsFormReady] = useState(false);
  const onReady = useCallbackRef(() => {
    setTimeout(() => setIsFormReady(true), 5000);
  });

  return (
    <Drawer placement="bottom" size="full" {...props}>
      <DrawerOverlay bg={'information.50'} />
      <DrawerContent>
        {allowClose || title ? (
          <DrawerHeader minHeight="12" bg="information.50">
            <HStack justify="space-between">
              {title && <Text textStyle="h4">{title}</Text>}
              {allowClose && (
                <Box>
                  <Button
                    variant="unstyled"
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    <Icon as={CloseIcon} boxSize={6} mt={'auto'} />
                  </Button>
                </Box>
              )}
            </HStack>
          </DrawerHeader>
        ) : (
          <Box h="env(safe-area-inset-top)" />
        )}
        <DrawerBody py="0" px="0" bg={'information.50'}>
          <ZStack h="full">
            <Text
              aria-hidden
              textStyle="h1"
              placeSelf="center"
              color={isFormReady ? 'white' : 'transparent'}
            >
              {/* Render something for session replay which can't see typeform iframe */}
              Typeform
            </Text>
            <Flex
              direction={'column'}
              h={'full'}
              px={2}
              pt={2}
              pb={8}
              sx={{
                '.tf-v1-widget': {
                  fontSize: '16px !important',
                  input: {
                    fontSize: '16px !important',
                  },
                },
              }}
            >
              <Widget
                id={formId}
                inlineOnMobile
                style={{ height: '100%' }}
                hidden={{ ...hidden, ...userProps }}
                iframeProps={{ title: title ?? 'Typeform' }}
                onSubmit={handleSubmit}
                onReady={onReady}
              />
            </Flex>
          </ZStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
