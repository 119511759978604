import { Button, Circle, Flex, Icon } from '@chakra-ui/react';

import {
  //  FeedbackIcon,
  HeadsetIcon,
} from '@arena-labs/strive2-ui';

import { useZendeskUnreadMessages } from '../../hooks/use-zendesk-unread-messages';
import { toggleZendeskChat } from '../../lib/zendesk';

export function HelpPageFooter() {
  const unread = useZendeskUnreadMessages();

  return (
    <>
      <Flex w={'full'} gap={4}>
        {/* <Button
          opacity={0}
          rounded={'button'}
          bg={'neutral.100'}
          w={'full'}
          border={'1x solid'}
          borderColor={'neutralAlpha.100'}
          textStyle={'button'}
          color={'neutral.600'}
          fontWeight={'normal'}
        >
          <Icon as={FeedbackIcon} boxSize={5} mr={2} />
          Give feedback
        </Button> */}
        <Button
          rounded={'button'}
          bg={'neutral.100'}
          w={'full'}
          border={'1x solid'}
          borderColor={'neutralAlpha.100'}
          textStyle={'button'}
          color={'neutral.600'}
          fontWeight={'normal'}
          onClick={() => {
            toggleZendeskChat(true);
          }}
          position="relative"
        >
          <Icon as={HeadsetIcon} boxSize={5} mr={2} />
          Chat with us
          {typeof unread === 'number' && unread > 0 && (
            <Circle
              size="12px"
              bg="red.500"
              position="absolute"
              top="-3px"
              right="-2px"
            />
          )}
        </Button>
      </Flex>
    </>
  );
}
