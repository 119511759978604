import { useForm } from 'react-hook-form';
import {
  Button,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export default function EmailVerification({
  openSupport,
  onSubmit,
}: {
  openSupport: () => void;
  onSubmit: (email: string) => void;
}) {
  const formMethods = useForm<{ email: string }>({
    resolver: yupResolver(
      yup.object({ email: yup.string().email().required() }),
    ),
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = formMethods;

  const submitEmail = handleSubmit(({ email }) => {
    onSubmit(email);
  });

  return (
    <Flex direction={'column'} mt={10} h={'full'} px={6} gap={2}>
      <Text textStyle={'h2'}>Verify your Work Email</Text>
      <Text textStyle={'copy'} color={'neutral.600'}>
        Your email is solely used for account verification. No spam or
        unnecessary emails—just one for security. Your privacy matters to us.
      </Text>
      <chakra.form
        display={'flex'}
        flexDirection={'column'}
        h={'full'}
        onSubmit={submitEmail}
      >
        <FormControl isInvalid={Boolean(errors.email)}>
          <Input
            type="email"
            mt={6}
            bg={'neutral.50'}
            shadow={'1dp'}
            border={'none'}
            placeholder="Enter Work Email"
            {...register('email')}
          />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>
        <Flex mt={'auto'} direction={'column'} gap={6} textAlign={'center'}>
          <Text textStyle={'copy'} color={'neutral.600'}>
            Any questions? Check out our{' '}
            <chakra.a
              href="https://arenalabs.co/privacy-policy"
              target="_blank"
              color={'primary.500'}
            >
              <u>data policy</u>
            </chakra.a>{' '}
            or reach out to{' '}
            <Button
              onClick={() => {
                openSupport();
              }}
              variant={'link'}
              color={'primary.500'}
            >
              <u>strive support</u>
            </Button>
            .
          </Text>
          <Button type={'submit'} variant={'primary'} w={'full'}>
            Continue
          </Button>
        </Flex>
      </chakra.form>
    </Flex>
  );
}
