import {
  makeApi,
  ZodiosBodyByAlias,
  ZodiosEndpointDefinition,
  ZodiosPathParamByAlias,
  ZodiosQueryParamsByAlias,
  ZodiosRequestOptionsByAlias,
  ZodiosResponseByAlias,
} from '@zodios/core';

import { aciApi } from './aci';
import { adminToolsApi } from './admin-tools';
import { appGateApi } from './app-gate';
import { biometricsApi } from './biometrics';
import { coachDashboardApi } from './coach-dashboard';
import { coachesApi } from './coaches';
import { contentAPI, playlistApi } from './content';
import { deviceApi } from './device';
import { homepageApi } from './homepage';
import { incentivesApi } from './incentives';
import { launchpadApi } from './launchpad';
import { notificationsApi } from './notifications';
import { pillarsApi } from './pillars';
import { practicesApi } from './practices';
import { resourcesS3Api } from './resources-s3';
import { userProfileApi } from './user-profile';

export * from './coach-dashboard';
export * from './coaches';
export type { BiometricsResponse } from './biometrics';
export type { AppGates, AppGateProps } from './app-gate';
export type { IncentiveType, AchievementIncentiveGroup } from './incentives';
export { homepageSchema } from './homepage';
export { learningStateSchema } from './user-profile';
export { resourcesS3Api };
export { addressSchema } from './launchpad';

export const striveAppApi = makeApi([
  ...aciApi,
  ...adminToolsApi,
  ...appGateApi,
  ...biometricsApi,
  ...coachesApi,
  ...contentAPI,
  ...deviceApi,
  ...homepageApi,
  ...incentivesApi,
  ...notificationsApi,
  ...pillarsApi,
  ...practicesApi,
  ...userProfileApi,
  ...launchpadApi,
]);
export const coachPortalApi = makeApi([
  ...userProfileApi,
  ...coachDashboardApi,
  ...playlistApi,
]);

export type ZodiosEndpointTypeFactory<
  Api extends ZodiosEndpointDefinition[],
  ApiAliases extends Extract<Api[number]['alias'], string>,
  Alias extends ApiAliases,
> = Alias extends string
  ? {
      Request: ZodiosBodyByAlias<Api, Alias>;
      Response: ZodiosResponseByAlias<Api, Alias>;
      PathParams: ZodiosPathParamByAlias<Api, Alias>;
      QueryParams: ZodiosQueryParamsByAlias<Api, Alias>;
      RequestOptions: ZodiosRequestOptionsByAlias<Api, Alias>;
    }
  : never;

export type ZodiosTypeFactory<
  Api extends ZodiosEndpointDefinition[],
  ApiAliases extends Extract<Api[number]['alias'], string> = Extract<
    Api[number]['alias'],
    string
  >,
> = {
  Aliases: ApiAliases;
  FetchAliases: Extract<(Api[number] & { method: 'get' })['alias'], string>;
  ['/']: {
    [Alias in ApiAliases]: ZodiosEndpointTypeFactory<Api, ApiAliases, Alias>;
  };
};

export type StriveApi = ZodiosTypeFactory<typeof striveAppApi>;
export type StriveApiResponse<Alias extends StriveApi['Aliases']> =
  StriveApi['/'][Alias]['Response'];
export type StriveApiRequest<Alias extends StriveApi['Aliases']> =
  StriveApi['/'][Alias]['Request'];

export type CoachApi = ZodiosTypeFactory<typeof coachPortalApi>;
export type CoachApiResponse<Alias extends CoachApi['Aliases']> =
  CoachApi['/'][Alias]['Response'];
export type CoachApiRequest<Alias extends CoachApi['Aliases']> =
  CoachApi['/'][Alias]['Request'];

export type ResourceApi = ZodiosTypeFactory<typeof resourcesS3Api>;
export type ResourceApiResponse<Alias extends ResourceApi['Aliases']> =
  ResourceApi['/'][Alias]['Response'];
