import React, { createContext, useContext, useState } from 'react';

type SupportHubContextType = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const SupportHubContext = createContext<SupportHubContextType | undefined>(
  undefined,
);

export function SupportHubProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <SupportHubContext.Provider value={{ isOpen, onOpen, onClose }}>
      {children}
    </SupportHubContext.Provider>
  );
}

export function useSupportHub() {
  const context = useContext(SupportHubContext);
  if (!context) {
    throw new Error('useSupportHub must be used within a SupportHubProvider');
  }
  return context;
}
