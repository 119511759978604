import React, { useState } from 'react';

import { SyncWarning } from '@arena-labs/strive2-ui';

import { useSupportHub } from '../../help-center/use-support-hub';
import { AlertCardStriveware } from './alert-card-striveware';

type NoSleepAlertState = 'initial' | 'technical' | 'reminder';

export function NoSleepAlert() {
  const [alertState, setAlertState] = useState<NoSleepAlertState>('initial');
  const [previousState, setPreviousState] =
    useState<NoSleepAlertState>('initial');
  const [showButtons, setShowButtons] = useState(true);
  const supportHub = useSupportHub();

  const handleYesResponse = () => {
    setPreviousState(alertState);
    setAlertState('technical');
    setShowButtons(true);
  };

  const handleNoResponse = () => {
    setPreviousState(alertState);
    setAlertState('reminder');
    setShowButtons(true);
  };

  const handleBack = () => {
    // Special case: if we're in reminder state and came from technical, go back to technical
    if (alertState === 'reminder' && previousState === 'technical') {
      setAlertState('technical');
    } else {
      // All other cases (including technical state) go back to initial
      setAlertState('initial');
    }
    setShowButtons(true);
  };

  const handleDismiss = () => {
    setShowButtons(false);
  };

  const handleTroubleshoot = () => {
    supportHub.onOpen();
    setTimeout(() => {
      setPreviousState('technical');
      setAlertState('reminder');
      setShowButtons(true);
    }, 330);
  };

  const getAlertContent = () => {
    switch (alertState) {
      case 'technical':
        return {
          description:
            "It seems like there's a technical issue. Follow the troubleshooting steps to resolve insights.",
          primaryButton: {
            text: 'Troubleshoot',
            onClick: handleTroubleshoot,
          },
          secondaryButton: {
            text: 'Back',
            onClick: handleBack,
          },
        };
      case 'reminder':
        return {
          description:
            'Make sure you wear the StriveWare during sleep to generate data insights.',
          primaryButton: {
            text: 'Dismiss',
            onClick: handleDismiss,
          },
          secondaryButton: {
            text: 'Back',
            onClick: handleBack,
          },
        };
      default:
        return {
          description:
            "We couldn't locate your sleep record from last night. Did you wear your StriveWare during sleep?",
          primaryButton: {
            text: 'Yes',
            onClick: handleYesResponse,
          },
          secondaryButton: {
            text: 'No',
            onClick: handleNoResponse,
          },
        };
    }
  };

  const content = getAlertContent();

  return (
    <AlertCardStriveware
      icon={SyncWarning}
      iconColor="warning.400"
      title="No sleep recorded last night."
      description={content.description}
      primaryButton={showButtons ? content.primaryButton : undefined}
      secondaryButton={showButtons ? content.secondaryButton : undefined}
    />
  );
}
