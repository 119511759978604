import { useForm } from 'react-hook-form';
import { VStack } from '@chakra-ui/react';

import { AlertBox, AlertTriangle, toast } from '@arena-labs/strive2-ui';

import { useFactoryResetMutation } from './admin-mutations';

export type AdminFactoryResetProps = {
  formId: string;
  onSuccess: () => void;
};

export function AdminFactoryReset({
  formId,
  ...props
}: AdminFactoryResetProps) {
  const factoryResetUser = useFactoryResetMutation();
  const { handleSubmit } = useForm({
    defaultValues: {},
  });

  const onSubmit = async () => {
    try {
      await factoryResetUser.mutateAsync();
      props.onSuccess();
      toast({ title: 'Account reset', status: 'success' });
    } catch (error) {
      toast({ title: `Error: ${error}`, status: 'error' });
    }
  };

  return (
    <VStack
      as="form"
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      spacing="4"
      align="stretch"
    >
      <AlertBox
        status="warning"
        title="WARNING"
        description="This will wipe all user data and transport you back to onboarding"
        variant="toast"
        showIcon={AlertTriangle}
      />
    </VStack>
  );
}
