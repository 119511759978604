import { useEffect, useState } from 'react';

declare global {
  interface Window {
    zE?: any;
  }
}

/**
 * A hook that tracks unread messages in Zendesk messenger
 * @returns The number of unread messages in support hub
 */
export function useZendeskUnreadMessages(): number {
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Run immediately
    if (typeof window !== 'undefined' && window.zE) {
      window.zE('messenger:on', 'unreadMessages', function (count: number) {
        setCount(count);
      });
    }

    const intervalId = setInterval(() => {
      if (typeof window !== 'undefined' && window.zE) {
        window.zE('messenger:on', 'unreadMessages', function (count: number) {
          setCount(count);
        });
      }
    }, 2500); // Changed to 2500 milliseconds (2.5 seconds)

    return () => clearInterval(intervalId);
  }, []);

  return count;
}
