import { useForm } from 'react-hook-form';
import {
  Box,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  HTMLChakraProps,
  Icon,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { SupportNew, useResponsive } from '@arena-labs/strive2-ui';

import { ContactFormData, contactFormSchema } from './contact-form-schema';

export type ContactFormProps = {
  context: ReturnType<typeof useContactForm>;
} & HTMLChakraProps<'form'>;

export function useContactForm() {
  return useForm<ContactFormData>({
    resolver: yupResolver(contactFormSchema),
  });
}

export function ContactForm({
  context,
  onSubmit,
  ...formProps
}: ContactFormProps) {
  const {
    register,
    formState: { errors },
  } = context;
  const rs = useResponsive();
  return (
    <chakra.form {...formProps} onSubmit={onSubmit}>
      <HStack
        justifyContent={'left'}
        w={'full'}
        gap={3}
        mb={rs({ base: 6, xs: 2 })}
      >
        <Icon
          as={SupportNew}
          ml={-3}
          boxSize={rs({ base: '76px', xs: '56px' })}
          mb="6"
          color={'primary.500'}
        />

        <Flex direction={'column'}>
          <Box pb={2}>
            <Text textStyle={rs({ base: 'h5', xs: 'copy_bold' })}>
              Welcome to{' '}
            </Text>
            <Text textStyle={rs({ base: 'h5', xs: 'copy_bold' })}>
              Strive Support
            </Text>
          </Box>
          <Text textStyle={'copy_small_thin'}>
            We&apos;re here. Let us know how we can help!
          </Text>
        </Flex>
      </HStack>
      <Stack direction="column" w="full" spacing={rs({ base: 6, xs: 2 })}>
        <FormControl
          id="help-form-category"
          isInvalid={Boolean(errors.category)}
        >
          <FormLabel textStyle="button" mb="2">
            I have feedback or a question about
          </FormLabel>
          <RadioGroup>
            <Stack spacing={rs({ base: 2, xs: 1 })} direction="column">
              <Radio
                {...register('category')}
                value="wearable"
                colorScheme="primary"
                bg="white"
                textStyle="copy_small"
                borderColor="neutral.500"
                border="1px solid"
              >
                Wearable
              </Radio>
              <Radio
                {...register('category')}
                value="videos"
                colorScheme="primary"
                bg="white"
                textStyle="copy_small"
                borderColor="neutral.500"
                border="1px solid"
              >
                Videos
              </Radio>
              <Radio
                {...register('category')}
                value="coaching"
                colorScheme="primary"
                bg="white"
                textStyle="copy_small"
                borderColor="neutral.500"
                border="1px solid"
              >
                Coaching
              </Radio>
              <Radio
                {...register('category')}
                value="feedback"
                colorScheme="primary"
                bg="white"
                textStyle="copy_small"
                borderColor="neutral.500"
                border="1px solid"
              >
                Feedback!
              </Radio>
              <Radio
                {...register('category')}
                value="other"
                colorScheme="primary"
                bg="white"
                textStyle="copy_small"
                borderColor="neutral.500"
                border="1px solid"
              >
                It&apos;s something else...
              </Radio>
            </Stack>
          </RadioGroup>
          <FormErrorMessage>{errors.category?.message}</FormErrorMessage>
        </FormControl>

        <FormControl id="help-form-message" isInvalid={Boolean(errors.message)}>
          <FormLabel textStyle="button" mb="1">
            Message
          </FormLabel>
          <Textarea
            bg={'neutral.50'}
            {...register('message')}
            placeholder="Your message"
            rows={6}
            borderRadius="12px"
          />
          <FormErrorMessage>{errors.message?.message}</FormErrorMessage>
        </FormControl>

        <FormControl
          id="help-form-contactPreference"
          isInvalid={Boolean(errors.contactPreference)}
        >
          <RadioGroup
            defaultValue="sms"
            aria-labelledby="help-form-contactPreference-label"
          >
            <Text textStyle={'copy_bold'} mb="2">
              We will contact you via:
            </Text>
            <Stack spacing={2} direction="column">
              <Radio
                {...register('contactPreference')}
                value="sms"
                fontSize="sm"
              >
                SMS
              </Radio>
            </Stack>
          </RadioGroup>
          <FormErrorMessage>
            {errors.contactPreference?.message}
          </FormErrorMessage>
        </FormControl>
      </Stack>
    </chakra.form>
  );
}
