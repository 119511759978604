import { makeApi } from '@zodios/core';
import { z } from 'zod';

export const coachSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  username: z.string(),
  timezone: z.string(),
  calendly_link: z.string().nullable(),
  headshot_url: z.string().nullable(),
  bio: z.string().nullable(),
  gender: z.string(),
  tags: z.array(z.string()),
  short_bio: z.string().nullable(),
  background: z.array(z.string()),
  personal_life: z.array(z.string()),
  specialization: z.array(z.string()),
  availability: z.array(z.string()),
  coaching_style: z.array(z.string()),
});

export type Coach = z.infer<typeof coachSchema>;

export const coachesApi = makeApi([
  {
    alias: 'getCoaches',
    description: 'Get all available coaches',
    method: 'get',
    path: '/coaches/',
    response: z.array(coachSchema),
  },
  {
    alias: 'assignCoach',
    description: 'Assign a coach to the current user',
    method: 'post',
    path: '/coaches/',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: z.object({
          username: z.string(),
        }),
      },
    ],
    response: z.any(),
  },
]);
