import { useState } from 'react';
import { useRouter } from 'next/router';
import { Flex } from '@chakra-ui/react';
import { match } from 'ts-pattern';

import { DeviceContent } from './device-content';
import { StrivewareSetup } from './striveware/setup/striveware-setup';
import { StrivewareDevice } from './striveware/striveware-device';

export function StrivewareWearable() {
  const router = useRouter();
  const [view, setView] = useState<'device-details' | 'setup' | null>(null);

  const handleNav = (path: string) => {
    router.push(path);
  };
  return (
    <Flex
      direction={'column'}
      height={'auto'}
      minHeight={0}
      pb={'0'}
      width="100%"
      transition="height 0.3s ease-in-out"
    >
      <DeviceContent setView={setView} />
      {match(view)
        .with('setup', () => (
          <Flex
            direction={'column'}
            pb={0}
            width="100%"
            height={'auto'}
            minHeight={0}
          >
            <StrivewareSetup
              onCancel={() => {
                handleNav('/');
              }}
            />
          </Flex>
        ))
        .with('device-details', () => (
          <Flex width="100%" height={'auto'} minHeight={0}>
            <StrivewareDevice />
          </Flex>
        ))
        .with(null, () => null)
        .exhaustive()}
    </Flex>
  );
}
