import * as React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  Box,
  Button,
  chakra,
  Circle,
  Flex,
  Grid,
  Link as ChakraLink,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

import { isPlayableVideo } from '@arena-labs/shared-models';
import { useHomeScreenData } from '@arena-labs/strive2-coaching';
import {
  DocumentFillIcon,
  FairyWandIcon,
  Icon,
  NotificationIcon,
  PerfAmbassadorsIcon,
  SignOutIcon,
  StriveSupport,
  SVGIconComponentType,
  UserProfileIcon,
} from '@arena-labs/strive2-ui';
import { useAuthService, useUserProfile } from '@strive/api';

import { useZendeskUnreadMessages } from '../../hooks/use-zendesk-unread-messages';
import { useAdminDashboard } from '../admin/admin-dashboard';
import { AdminModal } from '../admin/admin-modal';
import { useStriveChatActions } from '../chat/chat-provider';
import { useSupportHub } from '../help-center/use-support-hub';
import { TeamVideoModal } from '../home/team-video-modal';
import { ContactModal } from '../support/contact-modal';
import { useMenu } from './menu/use-menu';

export function AppMenu() {
  const helpDisclosure = useDisclosure();
  const adminDisclosure = useDisclosure();
  const teamVideoModal = useDisclosure();
  const supportHub = useSupportHub();
  const appMenu = useMenu();

  const {
    isActive: isAdminActive,
    activateAdmin,
    deactivateAdmin,
    open: openAdmin,
  } = useAdminDashboard();
  const unread = useZendeskUnreadMessages();
  const [homescreen, { refreshState }] = useHomeScreenData();
  const teamVideo = homescreen?.team_video_data;
  const { data: userData } = useUserProfile();

  const router = useRouter();
  const authSvc = useAuthService();
  const queryClient = useQueryClient();

  const { disconnect: signoutChat } = useStriveChatActions();
  const onLogout = () => {
    signoutChat();
    appMenu.onClose();
    authSvc.send('Log out');
    router.push('/log-in');
    queryClient.clear();
  };
  return (
    <Flex direction="column" h="full" pb={8}>
      <Flex flexDirection={'column'} gap="2" px={3} py={4}>
        <VStack spacing={0} w="full" align="stretch">
          <AppMenuItem type="link" icon={UserProfileIcon} href="/user/profile">
            Profile
          </AppMenuItem>

          <AppMenuItem
            type="link"
            icon={NotificationIcon}
            href="/user/notifications"
          >
            Notifications
          </AppMenuItem>

          <AppMenuItem
            type="link"
            icon={PerfAmbassadorsIcon}
            href="/coaching/ambassadors"
          >
            Performance Ambassadors
          </AppMenuItem>

          <AppMenuItem type="link" icon={DocumentFillIcon} href="/legal">
            Legal
          </AppMenuItem>

          <AppMenuItem
            type="button"
            icon={SignOutIcon}
            onClick={() => {
              onLogout();
            }}
          >
            Sign out
          </AppMenuItem>

          {userData?.is_staff && (
            <Box pt={4}>
              <chakra.button
                py={4}
                w="full"
                px="3"
                rounded="full"
                display="flex"
                justifyContent="center"
                alignItems="center"
                border="3px solid"
                borderColor="orange.500"
                bg="linear-gradient(45deg, #FF0080 0%, #7928CA 33%, #FFD700 66%, #FF4D4D 100%)"
                onClick={
                  isAdminActive
                    ? () => {
                        deactivateAdmin();
                        appMenu.onClose();
                      }
                    : () => {
                        activateAdmin();
                        openAdmin();
                        appMenu.onClose();
                      }
                }
              >
                <Icon
                  color="neutral.white"
                  as={FairyWandIcon}
                  boxSize="5"
                  mr="2"
                />
                <chakra.span color="neutral.white" fontWeight={'bold'}>
                  {isAdminActive ? 'Exit Admin Mode' : 'Admin Mode'}
                </chakra.span>{' '}
              </chakra.button>
            </Box>
          )}
        </VStack>
      </Flex>

      {isPlayableVideo(teamVideo) && (
        <TeamVideoModal
          isOpen={teamVideoModal.isOpen}
          onClose={() => {
            refreshState();
            teamVideoModal.onClose();
          }}
        />
      )}

      <ContactModal {...helpDisclosure} />
      <AdminModal {...adminDisclosure} />

      <Flex justify="center" mt="auto" pb={16} position="relative">
        <Button
          variant="unstyled"
          onClick={() => {
            appMenu.onClose();
            supportHub.onOpen();
          }}
        >
          <VStack spacing={2}>
            <Circle size={'56px'} bg="neutralAlpha.50">
              <Icon as={StriveSupport} boxSize={'33px'} color="logo" />
            </Circle>
            {typeof unread === 'number' && unread > 0 && (
              <Circle
                size="12px"
                bg="red.500"
                position="absolute"
                top="0"
                right="calc(50% - 30px)"
              />
            )}
            <Text textStyle={'copy'} whiteSpace={'nowrap'}>
              Strive Support
            </Text>
          </VStack>
        </Button>
      </Flex>
    </Flex>
  );
}

export type AppMenuItemProps = {
  icon: SVGIconComponentType;
  children: React.ReactNode;
} & (
  | { type: 'button'; onClick: () => void }
  | {
      type: 'link';
      href: string;
    }
);

export function AppMenuItem({ icon, children, ...props }: AppMenuItemProps) {
  const { onClose } = useMenu();

  return (
    <Grid
      templateColumns="24px 1fr"
      gap="3"
      py={4}
      pb={5}
      justifyContent="start"
      alignItems={'center'}
      borderBottom="1px solid"
      borderColor="neutral.400"
    >
      <Icon as={icon} boxSize={7} color="logo" />
      {props.type === 'button' && (
        <chakra.button
          textAlign="left"
          color="neutral.black"
          onClick={() => {
            props.onClick();
            // onClose();
          }}
        >
          <Text textStyle={'button'} whiteSpace={'nowrap'}>
            <span>{children}</span>{' '}
          </Text>
        </chakra.button>
      )}
      {props.type === 'link' && (
        <Link passHref legacyBehavior href={props.href}>
          <ChakraLink variant="unstyled" onClick={onClose}>
            <Text textStyle={'button'} whiteSpace={'nowrap'}>
              {' '}
              {children}
            </Text>
          </ChakraLink>
        </Link>
      )}
    </Grid>
  );
}
export { useMenu };
