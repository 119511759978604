import { useEffect } from 'react';
import {
  BoxProps,
  Button,
  Flex,
  Grid,
  SlideFade,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useMachine } from '@xstate/react';

import { ACILocation } from '@arena-labs/shared-models';
import {
  AlertBox,
  AlertTriangle,
  VisibleOnMount,
} from '@arena-labs/strive2-ui';
import { $API } from '@strive/api';

import { aciFormMachine, ACIPostResponse } from './aci-form.machine';
import { ACIHeader } from './aci-header';
import { CheckInLocation } from './location';
import { CheckInQuestion } from './question';

export type ACIFormProps = {
  location?: ACILocation;
  onSuccess?: (results: ACIPostResponse) => void;
  updateLocation?: (value: ACILocation) => void;
  wrapper?: BoxProps;
};

export function ACIForm({
  location,
  onSuccess,
  updateLocation,
  wrapper,
}: ACIFormProps) {
  const queryClient = useQueryClient();
  const [formState, send] = useMachine(aciFormMachine, {
    devTools: true,
    actions: {
      onSuccess: (ctx, event) => {
        // Call back to the parent component when the form is successfully submitted
        onSuccess?.(event.data);
        queryClient.invalidateQueries($API.getKeyByAlias('getScorecard'));
      },
    },
  });

  // If the location is passed in, update the state machine
  useEffect(() => {
    if (location) {
      send({ type: 'setLocation', location });
    }
  }, [location, send]);

  const { availableQuestions, questions } = formState.context;

  return (
    <Grid h="100%" templateRows="auto 1fr" isolation="isolate" {...wrapper}>
      <ACIHeader wrapper={{ zIndex: 10 }} />
      <Flex
        zIndex={0}
        direction="column"
        bg="bg.secondary"
        px="6"
        position="relative"
        _before={{
          // Fill the area below the header where the form shows through the cut-out
          content: '""',
          position: 'absolute',
          left: 0,
          width: '100%',
          height: '100%',
          bg: 'bg.secondary',
          display: 'block',
          transform: 'translateY(-100%)',
        }}
      >
        <Text textStyle={'h2'} textAlign="center" mb="9">
          Where are you in your Arena?
        </Text>

        <CheckInLocation
          value={formState.context.location}
          onChange={(val: ACILocation) => {
            if (updateLocation) {
              updateLocation(val);
            }

            send({ type: 'setLocation', location: val });
          }}
        />

        <VStack spacing="16" mt="16" align="stretch">
          {availableQuestions.map((question, index) => {
            const questionValue = formState.context.responses[question.id];
            const questionInput = (
              <CheckInQuestion
                key={question.id}
                number={index + 1}
                total={questions.length}
                question={question}
                value={questionValue}
                onChange={(value) =>
                  send({
                    type: 'setResponse',
                    questionId: question.id,
                    value,
                  })
                }
              />
            );

            return questionValue === undefined ? (
              <SlideFade
                in
                key={question.id}
                offsetX="-150px"
                transition={{
                  enter: { duration: 0.6 },
                }}
              >
                <VisibleOnMount delay={250}>{questionInput}</VisibleOnMount>
              </SlideFade>
            ) : (
              questionInput
            );
          })}

          {(formState.can('submit') || formState.matches('submitting')) && (
            <SlideFade in offsetY="20px" unmountOnExit>
              <VisibleOnMount
                delay={350}
                display="flex"
                justifyContent="space-around"
              >
                <Button
                  variant="primary"
                  isLoading={formState.matches('submitting')}
                  onClick={() => send('submit')}
                  px="10"
                >
                  View Current Streak
                </Button>
              </VisibleOnMount>
            </SlideFade>
          )}

          <SlideFade
            in={formState.matches('input.error')}
            reverse
            offsetY="20px"
          >
            <AlertBox
              hidden={!formState.matches('input.error')}
              status="error"
              title="There was a problem submitting your check-in"
              variant="toast"
              showIcon={AlertTriangle}
            />
          </SlideFade>
        </VStack>
      </Flex>
    </Grid>
  );
}
