import { Flex, Text } from '@chakra-ui/react';

export function SyncingGetStartedContent() {
  return (
    <Flex direction="column" gap={4} w="full" pt={16}>
      <Text textStyle="h5" color="neutral.800">
        How often should I sync my StriveWare to the Strive app?
      </Text>

      <Text textStyle="button" color="neutral.700">
        After your initial setup, the app will automatically pair your ring and
        sync data when the app is open and your device is nearby.
      </Text>

      <Text textStyle="button" color="neutral.700">
        For the most up-to-date data for your Arena Check-In, simply pull to
        refresh on the app&apos;s home screen to trigger a sync.
      </Text>
    </Flex>
  );
}
