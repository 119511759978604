import { z } from 'zod';

import { zIsoDateTime } from '../lib/schema-utils';

// Basic Schemas
export const professionalFulfillmentSchema = z.object({
  score: z.number(),
  color: z.enum(['red', 'yellow', 'green']),
});

export type ACIScoreSummary = z.infer<typeof ACIScoreSchema>;
export type ACIValueSummary = z.infer<typeof ACIValueSchema>;

// ACI Reflection Schemas
export const aciReflectionEntrySchema = z.object({
  timestamp: zIsoDateTime,
  content: z.string().nullable(),
});

export const aciReflectionDaySchema = z.object({
  date: z.string(),
  entries: z.array(aciReflectionEntrySchema),
});

export const aciReflectionResponseSchema = z.object({
  next_page: z.string().nullable(),
  previous_page: z.string().nullable(),
  results: z.array(aciReflectionDaySchema),
});

export const ACIScoreSchema = z.object({
  created_at: zIsoDateTime,
  arena_location: z.string().nullable(),
  score: z.number().nullable(),
  current_stress: z.number().nullable(),
  current_energy: z.number().nullable(),
  current_focus: z.number().nullable(),
});

export const ACIValueSchema = z.object({
  avg: z.number().nullable(),
  min: z.number().nullable(),
  max: z.number().nullable(),
});

export const sensorDataSchema = z.object({
  data: z.number(),
  percentage: z.number().nullable(),
  date: zIsoDateTime,
});

export const biometricHistoryEntrySchema = z.object({
  action_status: z.string(),
  last_synced: zIsoDateTime,
  value: z.number(),
  value_status: z.string(),
});

export const AutomatedMessagesResponseSchema = z.object({
  status: z.string(),
});

// Pagination Schema
export const paginationSchema = z.object({
  nextPage: z.string().nullable(),
  prevPage: z.string().nullable(),
});

// Profile Summary Related Schemas
export const hrvHistoryItemSchema = z.object({
  date: z.string(),
  hrvValue: z.number().nullable(),
  avgValue: z.number().nullable(),
});

export const hrvHistorySchema = z.object({
  next_page: z.string().nullable(),
  previous_page: z.string().nullable(),
  results: z.array(hrvHistoryItemSchema),
});

export const sleepConsistencyHistoryItemSchema = z.object({
  date: z.string(),
  bed_time: z.string().nullable(),
  wake_time: z.string().nullable(),
  consistency: z.number().nullable(),
  avg_sleep_stats: z.object({
    bed_time: z.string().nullable(),
    wake_time: z.string().nullable(),
    consistency: z.number().nullable(),
  }),
});

export const sleepConsistencyHistorySchema = z.object({
  next_page: z.string().nullable(),
  previous_page: z.string().nullable(),
  results: z.array(sleepConsistencyHistoryItemSchema),
});

export const toolPracticeItemSchema = z.object({
  date: z.string(),
  toolsPracticed: z.array(z.string()),
});

export const challengeToolSchema = z.object({
  toolName: z.string(),
  status: z.enum(['not_started', 'in_progress', 'complete']),
  completedAt: z.string().nullable(),
});

export const challengeSchema = z.object({
  challengeName: z.string(),
  tools: z.array(challengeToolSchema),
});

// Evaluation Schema
export const evaluationSchema = z
  .object({
    score: z.number(),
    color: z.enum(['red', 'yellow', 'green']),
  })
  .nullable();

// Intake Schema
export const intakeSchema = z.object({
  preferred_name: z.string().nullable(),
  race: z.string().nullable(),
  gender: z.string().nullable(),
  age: z.number(),
  role_shift: z.string(),
  medical_field: z.string().nullable(),
  goal: z.string().nullable(),
  professional_fulfillment: evaluationSchema,
  burnout_propensity: evaluationSchema,
  self_evaluation: evaluationSchema,
  user_defined_challenges: z.string(),
  notes_from_user: z.string(),
  last_login: zIsoDateTime,
  timezone: z.string(),
});

export type IntakeSchema = z.infer<typeof intakeSchema>;

// Content Progress Schemas
export const contentProgressToolSchema = z.object({
  toolName: z.string(),
  status: z.enum(['complete', 'in_progress', 'not_started']),
  completedAt: z.string().nullable(),
});

export const contentProgressChallengeSchema = z.object({
  challengeName: z.string(),
  tools: z.array(contentProgressToolSchema),
});

export const contentProgressSchema = z.object({
  current_tool: z.string().nullable(),
  current_stall_time: z.string(),
  challenges_completion: z.array(contentProgressChallengeSchema),
});

export type ContentProgress = z.infer<typeof contentProgressSchema>;

// Tool Completion History Schemas
export const toolCompletionHistoryItemSchema = z.object({
  date: z.string(),
  tools_practiced: z.array(z.string()),
});

export const toolCompletionHistoryResponseSchema = z.object({
  next_page: z.string().nullable(),
  previous_page: z.string().nullable(),
  results: z.array(toolCompletionHistoryItemSchema),
});

export type ToolCompletionHistoryItem = z.infer<
  typeof toolCompletionHistoryItemSchema
>;
export type ToolCompletionHistoryResponse = z.infer<
  typeof toolCompletionHistoryResponseSchema
>;

// Coach Note Schemas
export const coachNoteSchema = z.object({
  id: z.number(),
  message: z.string(),
  tags: z.array(z.string()),
  created_at: zIsoDateTime,
  updated_at: zIsoDateTime,
  created_by: z.number(),
  updated_by: z.number(),
  created_by_name: z.string().nullable(),
  updated_by_name: z.string().nullable(),
});

export const coachNoteCreateSchema = z.object({
  message: z.string(),
  tags: z.array(z.string()),
  profile_id: z.number(),
});

export type CoachNote = z.infer<typeof coachNoteSchema>;
export type CoachNoteCreate = z.infer<typeof coachNoteCreateSchema>;
export type SleepConsistencyHistoryItem = z.infer<
  typeof sleepConsistencyHistoryItemSchema
>;
export type SleepConsistencyHistory = z.infer<
  typeof sleepConsistencyHistorySchema
>;
export type HrvHistory = z.infer<typeof hrvHistorySchema>;
export type ACIReflectionEntry = z.infer<typeof aciReflectionEntrySchema>;
export type ACIReflectionDay = z.infer<typeof aciReflectionDaySchema>;
export type ACIReflectionResponse = z.infer<typeof aciReflectionResponseSchema>;
