import { format, subDays } from 'date-fns';

/**
 * Stub data for the new profile summary endpoint

*/

export const sleepConsistency = {
  next_page: '/api/coach/profile-summary-new/123/sleep?page=2',
  previous_page: null,
  results: [
    {
      date: new Date().toISOString(),
      bed_time: '22:30',
      wake_time: '06:30',
      consistency: '85%',
      avg_sleep_stats: {
        bed_time: '22:45',
        wake_time: '06:45',
        consistency: '75%',
      },
    },
    {
      date: subDays(new Date(), 1).toISOString(),
      bed_time: '22:45',
      wake_time: '06:45',
      consistency: '75%',
      avg_sleep_stats: {
        bed_time: '22:45',
        wake_time: '06:45',
        consistency: '70%',
      },
    },
    {
      date: subDays(new Date(), 2).toISOString(),
      bed_time: '20:15',
      wake_time: '06:15',
      consistency: '76%',
      avg_sleep_stats: {
        bed_time: '22:45',
        wake_time: '06:45',
        consistency: '81%',
      },
    },
    {
      date: subDays(new Date(), 3).toISOString(),
      bed_time: '00:01',
      wake_time: '08:00',
      consistency: '77%',
      avg_sleep_stats: {
        bed_time: '22:45',
        wake_time: '06:45',
        consistency: '70%',
      },
    },
    {
      date: subDays(new Date(), 4).toISOString(),
      bed_time: '03:30',
      wake_time: '08:30',
      consistency: '95%',
      avg_sleep_stats: {
        bed_time: '22:45',
        wake_time: '06:45',
        consistency: '86%',
      },
    },
    {
      date: subDays(new Date(), 5).toISOString(),
      bed_time: '19:45',
      wake_time: '06:45',
      consistency: '90%',
      avg_sleep_stats: {
        bed_time: '22:45',
        wake_time: '06:45',
        consistency: '92%',
      },
    },
    {
      date: subDays(new Date(), 6).toISOString(),
      bed_time: '21:30',
      wake_time: '04:30',
      consistency: '75%',
      avg_sleep_stats: {
        bed_time: '22:45',
        wake_time: '06:45',
        consistency: '78%',
      },
    },
  ],
};

export const aciReflections = {
  next_page: '/api/coach/profile-summary-new/123/aci2?page=2',
  previous_page: null,
  results: [
    {
      date: format(new Date(), 'yyyy-MM-dd'),
      entries: [
        {
          timestamp: new Date(),
          content:
            'Started implementing the new breathing techniques during afternoon meetings.',
        },
      ],
    },
    {
      date: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
      entries: [
        {
          timestamp: subDays(new Date(), 1),
          content:
            'Feeling more energized after practicing the breathing technique for a week.',
        },
      ],
    },
    {
      date: format(subDays(new Date(), 3), 'yyyy-MM-dd'),
      entries: [
        {
          timestamp: subDays(new Date(), 3),
          content:
            'Noticed increased stress levels during afternoon meetings. Will try to implement short breaks.',
        },
      ],
    },
    {
      date: format(subDays(new Date(), 5), 'yyyy-MM-dd'),
      entries: [
        {
          timestamp: subDays(new Date(), 5),
          content:
            'Sleep quality improved after reducing screen time before bed.',
        },
      ],
    },
  ],
};
