import 'core-js/actual';
import '../lib/bootstrap';
import '../lib/devtools';
// Global CSS
import './styles.css';
import 'stream-chat-react/dist/css/v2/index.css';
import './chat-styles.css';
import '@arena-labs/strive2-ui/styles/video-js.base.css';
import '@arena-labs/strive2-ui/styles/video-js.css';
import 'react-phone-number-input/style.css';

import React, { useMemo } from 'react';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Capacitor } from '@capacitor/core';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { enableMapSet } from 'immer';

import {
  AnalyticsContext,
  AppTrackingEvent,
  TrackingTag,
  useRouterAnalytics,
} from '@arena-labs/analytics';
import { isObjectWithKey } from '@arena-labs/shared-models';
import { AciContext } from '@arena-labs/strive2-data';
import {
  ScreenContainer,
  ThemeProvider,
  ToastContainer,
  useAppStateChange,
} from '@arena-labs/strive2-ui';
import {
  $API,
  apiHost,
  createQueryClientPersister,
  queryClient,
} from '@strive/api';
import { LoggedInOnly } from '@strive/auth-ui';
import { MediaPlayer, MediaSuspender } from '@strive/av';
import {
  FilesystemDataStore,
  setDataStoreProvider,
  SQLiteDataStore,
} from '@strive/utils';
import { StrivewareProvider } from '@strive/wearable';

import { AppACIModal } from '../components/aci/app-aci-modal';
import { useConsoleLogSetup } from '../components/admin/admin-console-logs';
import { PassportManager } from '../components/auth/passport-manager';
import { ChatContextProvider } from '../components/chat/chat-provider';
import { AppGateListener } from '../components/gates/app-gate-listener';
import { SupportHub } from '../components/help-center/support-hub';
import { SupportHubProvider } from '../components/help-center/use-support-hub';
import IncentivesHub from '../components/incentives/incentives-hub';
import { MenuDrawer } from '../components/layout/menu/menu-drawer';
import { NotificationListener } from '../components/notifications/notification-listener';
import { ZendeskProvider } from '../components/support/zendesk-provider';
import { CheckLatestVersion } from '../components/version-update/check-latest-version';
import { WearableHub } from '../components/wearable/wearable-hub';
import { useScreenOrientation } from '../lib/orientation-lock';
import { connect as connectToDatabase } from '../lib/sqlite';
import { useAppInfo } from '../lib/use-app-info';
import { useAppUrlListener } from '../lib/use-app-url-listener';

enableMapSet();

type StriveNextPage = NextPage & {
  // Shared layout - https://nextjs.org/docs/basic-features/layouts
  getLayout?: (
    page: React.ReactElement,
    pageProps?: unknown,
  ) => React.ReactNode;

  // Auth-related props
  allowGuest?: boolean;

  // Analytics
  analyticsTags: TrackingTag[];
  disableAnalyticsPageLoad?: boolean;
};

type AppPropsWithLayout = AppProps & {
  Component: StriveNextPage;
};

const urlParams = new URLSearchParams(
  typeof window === 'undefined' ? '' : window.location.search,
);
const isCacheDisabled = urlParams.has('disableCache');

function StriveApp({ Component, pageProps, router }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  const analytics = useRouterAnalytics({
    tags: Component.analyticsTags,
    isStandaloneDisplayMode:
      typeof window !== 'undefined' &&
      window.matchMedia('(display-mode: standalone)').matches,
  });
  const appInfo = useAppInfo();
  useAppUrlListener();

  useConsoleLogSetup();

  useScreenOrientation({ lock: true });

  const persister = useMemo(() => setupDataStores(), []);

  useAppStateChange((isActive) => {
    const state = isActive ? 'foreground' : 'background';
    console.info(`App State Changed`, {
      state,
      date: new Date(),
    });
    analytics.logEvent(AppTrackingEvent.AppStateChange, { state });
  });

  return (
    <>
      <Head>
        <title>Arena Strive</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover, user-scalable=yes, maximum-scale=5"
        />
        <style>
          {`
            @supports (padding-top: env(safe-area-inset-top)) {
              iframe[title="Messaging window"],
              iframe#launcher, 
              iframe#webWidget {
                height: calc(var(--vh, 1vh) * 100) !important;
                padding-top: env(safe-area-inset-top) !important;
                width: 100vw !important;
                position: fixed !important;
                top: 0 !important;
                left: 0 !important;
                right: 0 !important;
                bottom: 0 !important;
                z-index: 9999 !important;
                border: none !important;
                max-width: 100vw !important;
                max-height: 100vh !important;
                background: white !important;
                viewport-fit: cover !important;
              }
            }

          `}
        </style>
      </Head>
      <ThemeProvider>
        <SupportHubProvider>
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{
              persister,
              maxAge: isCacheDisabled
                ? 0
                : queryClient.getDefaultOptions().queries?.cacheTime,
              buster: `${apiHost}-${process.env.VERSION}`,
              hydrateOptions: {
                defaultOptions: {
                  queries: {
                    cacheTime: isCacheDisabled ? 0 : 1000 * 60 * 60 * 24 * 7, // 1 week
                  },
                },
              },
              dehydrateOptions: {
                shouldDehydrateQuery: ({ queryKey }) => {
                  const shouldPersist =
                    isObjectWithKey(queryKey[0], 'api') &&
                    queryKey[0].api === 'strive-api';
                  return shouldPersist;
                },
              },
            }}
          >
            <ScreenContainer>
              <ToastContainer />
              <AnalyticsContext context={analytics.context}>
                <NotificationListener>
                  <CheckLatestVersion>
                    <PassportManager
                      allowGuest={Component.allowGuest}
                      onDenyAccess={() => router.replace('/log-in')}
                      trackPageLoad={!Component.disableAnalyticsPageLoad}
                      appInfo={appInfo}
                    >
                      <StrivewareProvider>
                        <ChatContextProvider>
                          <MediaSuspender.Provider>
                            <LoggedInOnly>
                              <IncentivesHub />
                              <ZendeskProvider />
                            </LoggedInOnly>
                            <AppGateListener>
                              <WearableHub>
                                <MediaPlayer
                                  onPlayerClosed={() => {
                                    queryClient.invalidateQueries(
                                      $API.getKeyByAlias('getHomepage'),
                                    );
                                    queryClient.invalidateQueries(
                                      $API.getKeyByAlias('getScorecard'),
                                    );
                                  }}
                                >
                                  <AciContext.Provider>
                                    <AppACIModal />
                                    <MenuDrawer />
                                    <SupportHub>
                                      {getLayout(
                                        <Component {...pageProps} />,
                                        pageProps,
                                      )}
                                    </SupportHub>
                                  </AciContext.Provider>
                                </MediaPlayer>
                              </WearableHub>
                            </AppGateListener>
                          </MediaSuspender.Provider>
                        </ChatContextProvider>
                      </StrivewareProvider>
                    </PassportManager>
                  </CheckLatestVersion>
                </NotificationListener>
              </AnalyticsContext>
            </ScreenContainer>
          </PersistQueryClientProvider>
        </SupportHubProvider>
      </ThemeProvider>
    </>
  );
}

export default StriveApp;

function setupDataStores() {
  if (typeof window === 'undefined') {
    // Disable persistence on server & static builds
    return {
      persistClient: () => void 0,
      restoreClient: () => void 0,
      removeClient: () => void 0,
    };
  }

  // Initialize the database and add it as a data store provider
  connectToDatabase().then(({ db }) => {
    setDataStoreProvider('db', new SQLiteDataStore(db, 'data_store'));
  });

  // Add a filesystem data store provider
  const fsStore = new FilesystemDataStore();
  setDataStoreProvider('fs', fsStore);

  return createQueryClientPersister(fsStore);
}

if (typeof performance !== 'undefined' && performance.mark) {
  performance.mark('app:bootup:start');
  console.info('App Started: ' + Capacitor.getPlatform());
}
