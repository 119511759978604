import React, { useState } from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/react';

import { useGradient } from '@arena-labs/strive2-ui';
import { IfWearable } from '@strive/wearable';

import { DeviceContent } from './device-content';
import { StrivewareWearable } from './striveware';
import { useDrawerTouch } from './use-drawer-touch';
import { useWearableHub } from './use-wearable-hub';
import { WhoopDevice } from './whoop/whoop-device';

export type SlideComponentOptions =
  | 'whenToWear'
  | 'deviceInfo'
  | 'troubleShooting'
  | '';

export function WearableHub({ children }: { children: React.ReactNode }) {
  const mainGradient = useGradient('background');
  const { isOpen, onClose } = useWearableHub();
  const { scope, animate, deltaY } = useDrawerTouch({ onClose });
  const [view, setView] = useState<'device-details' | 'setup' | null>(null);

  const handleSetView = (view: 'device-details' | 'setup' | null) => {
    setView(view);
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="top"
        onClose={onClose}
        closeOnOverlayClick={false}
        blockScrollOnMount={true}
      >
        <DrawerContent
          padding={0}
          roundedBottom={'card'}
          paddingTop={'env(safe-area-inset-top)'}
          boxShadow={'none'}
          h={'auto'}
        >
          <Box
            roundedBottom={'20px'}
            ref={scope}
            transition="height 0.3s ease"
            position="relative"
          >
            <DrawerOverlay
              backdropFilter={'none'}
              h={'300vh'}
              bg="transparent"
              zIndex={-2}
            >
              <Box
                backdropFilter={'none'}
                className="drawer-overlay-backdrop"
                opacity={deltaY > 0 ? Math.max(0, 1 - deltaY / 100) : 1}
                onClick={async () => {
                  await animate(
                    [
                      [scope.current, { translateY: -1000 }],
                      ['.drawer-overlay-backdrop', { opacity: 0 }],
                    ],
                    { duration: 0.5 },
                  );
                  onClose();
                }}
                bg={'neutralAlpha.200'}
                h={'full'}
              />
            </DrawerOverlay>

            <IfWearable mode="striveware">
              <DeviceContent setView={handleSetView} />
              <DrawerOverlay
                className="drawer-overlay"
                zIndex={0}
                height={'calc(100% + 60px)'}
                marginTop={`-60px`}
                roundedBottom={'card'}
                shadow={'4dp'}
              >
                {view === 'device-details' ? (
                  <Box h={'100%'} bg={'neutral.white'} roundedBottom={'card'} />
                ) : (
                  <Box h={'100%'} bg={mainGradient} roundedBottom={'card'} />
                )}
              </DrawerOverlay>
            </IfWearable>
            <IfWearable mode="partner">
              <DrawerOverlay
                zIndex={-1}
                height={'calc(100% + 60px)'}
                marginTop={`-60px`}
                roundedBottom={'card'}
                shadow={'4dp'}
              >
                <Box h={'100%'} bg={'neutral.white'} roundedBottom={'card'} />
              </DrawerOverlay>
            </IfWearable>

            <DrawerBody padding={0} roundedBottom={'card'} position="relative">
              <Box position="relative">
                <IfWearable mode="striveware">
                  <>
                    <DeviceContent setView={setView} />

                    <StrivewareWearable />
                  </>
                </IfWearable>

                <IfWearable mode="partner">
                  <WhoopDevice
                    deltaY={deltaY}
                    onClose={onClose}
                    scope={scope}
                    animate={animate}
                  />
                </IfWearable>

                <Box
                  position="absolute"
                  bottom={1.5}
                  left="50%"
                  transform="translateX(-50%)"
                  zIndex={100000000}
                  h={'5px'}
                  w={'135px'}
                  bg={'neutral.400'}
                  rounded={'full'}
                />
              </Box>
            </DrawerBody>
          </Box>
        </DrawerContent>
      </Drawer>
      {children}
    </>
  );
}
