import Image from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import {
  Box,
  Button,
  Circle,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { format } from 'date-fns';

import {
  ArrowDown,
  ChevronRightIcon,
  SyncWarning,
  TriangleAlert,
  useAppStateChange,
  WaitForQuery,
} from '@arena-labs/strive2-ui';
import { $API, queryClient, useUserProfile } from '@strive/api';
import { IfWearable } from '@strive/wearable';

import { openApp } from '../../../lib/open-app';

export function WhoopDevice({
  deltaY,
  onClose,
  scope,
  animate,
}: {
  deltaY: number;
  onClose: () => void;
  scope: React.RefObject<HTMLElement>;
  animate: any;
}) {
  const { data: user, invalidate } = useUserProfile();
  const progress = $API.useGetProgressData();
  const authURL = user?.profile?.wearable[0]?.url;
  const router = useRouter();

  const handleHelpCenterClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    onClose();
    await router.replace('/help-center/', undefined, { shallow: true });
  };

  useAppStateChange((isActive) => {
    if (isActive) {
      invalidate();
      queryClient.invalidateQueries();
    }
  });

  return (
    <>
      <DrawerOverlay
        backdropFilter={'none'}
        h={'300vh'}
        bg="transparent"
        zIndex={-2}
      >
        <Box
          backdropFilter={'none'}
          className="drawer-overlay-backdrop"
          opacity={deltaY > 0 ? Math.max(0, 1 - deltaY / 100) : 1}
          onClick={async () => {
            await animate(
              [
                [scope.current, { translateY: -1000 }],
                ['.drawer-overlay-backdrop', { opacity: 0 }],
              ],
              { duration: 0.5 },
            );
            onClose();
          }}
          bg={'neutralAlpha.200'}
          h={'full'}
        />
      </DrawerOverlay>

      <Box bg={'neutral.white'} pt={6} pb={2} px={6}>
        <IfWearable>
          {({ wearable }) => {
            return (
              <>
                <HStack w={'full'} gap={8}>
                  <Circle bg={'information.50'} size={'80px'}>
                    <Image
                      width={50}
                      height={50}
                      style={{ height: 'auto' }}
                      src={'/images/whoop/whoop-band-2025.webp'}
                      alt={'whoop-band'}
                      priority
                      unoptimized
                    />
                  </Circle>
                  <Flex flexDir={'column'} alignItems={'flex-start'} gap={0}>
                    <Text
                      textStyle={'copy_bold'}
                      color={
                        wearable.status === 'valid'
                          ? 'primary.300'
                          : 'negative.400'
                      }
                    >
                      {wearable.status === 'valid'
                        ? 'Authorized'
                        : 'Authorization required'}
                    </Text>
                    <Text textStyle={'h4'} color={'neutral.black'} mt={'-1'}>
                      {user?.profile?.preferred_name ??
                        user?.first_name ??
                        user?.username ??
                        'Your'}
                      &apos;s WHOOP
                    </Text>
                    <Text textStyle={'copy_extra_small'} color={'neutral.600'}>
                      {wearable.last_synced_at
                        ? (() => {
                            const now = new Date();
                            const syncedDate = new Date(
                              wearable.last_synced_at,
                            );
                            const diffInMinutes = Math.floor(
                              (now.getTime() - syncedDate.getTime()) /
                                (1000 * 60),
                            );

                            return diffInMinutes < 60
                              ? `Last synced ${diffInMinutes} minute${
                                  diffInMinutes === 1 ? '' : 's'
                                } ago`
                              : `Last synced ${format(
                                  syncedDate,
                                  'MMM d, yyyy h:mm a',
                                )}`;
                          })()
                        : null}
                    </Text>
                  </Flex>
                </HStack>

                <WaitForQuery query={progress}>
                  {(progressData) => {
                    const hasHRVToday = progressData.results.find((result) => {
                      const today = format(new Date(), 'yyyy-MM-dd');
                      return result.date === today;
                    });
                    return wearable.status !== 'valid' ? (
                      <AlertCardWhoop
                        icon={TriangleAlert}
                        iconColor="negative.400"
                        title="Authorize WHOOP data"
                        description="Unlock your data by authorize your data for Strive now."
                        primaryButton={{
                          text: 'Authorize via WHOOP',
                          onClick: () => {
                            if (authURL) {
                              window.open(
                                authURL,
                                '_blank',
                                'noopener,noreferrer',
                              );
                            }
                          },
                        }}
                      />
                    ) : !hasHRVToday?.hrv ? (
                      <AlertCardWhoop
                        icon={SyncWarning}
                        iconColor="warning.400"
                        title="Data not yet available today"
                        description="Make sure you refresh WHOOP app daily to allow your biometric data to come through."
                        primaryButton={{
                          text: 'Open WHOOP',
                          onClick: () => {
                            openApp('whoop');
                          },
                        }}
                        secondaryButton={{
                          text: 'Help Center',
                          onClick: handleHelpCenterClick,
                        }}
                      />
                    ) : null;
                  }}
                </WaitForQuery>

                <Flex
                  zIndex={1}
                  direction="column"
                  gap={3}
                  w="full"
                  mt={6}
                  color="neutral.700"
                >
                  <LinkBox
                    as="article"
                    textStyle={'copy'}
                    display="inline-flex"
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={handleHelpCenterClick}
                    pb={wearable.status === 'valid' ? 0 : 2}
                  >
                    <LinkOverlay as={NextLink} href="/help-center/">
                      <Text textStyle={'copy'}>Help Center</Text>
                    </LinkOverlay>
                    <Icon as={ChevronRightIcon} boxSize={'3'} />
                  </LinkBox>
                  {/* {wearable.status === 'valid' && (
                    <>
                      <Divider borderColor={'neutral.300'} />
                      <Button
                        variant={'inline'}
                        onClick={() => {
                          alert('Unlink Device');
                        }}
                        display="inline-flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Text textStyle={'copy'}>Unlink Device</Text>
                        <Icon as={ChevronRightIcon} boxSize={'3'} />
                      </Button>
                    </>
                  )} */}
                </Flex>
              </>
            );
          }}
        </IfWearable>
      </Box>
    </>
  );
}

interface WhoopAlertCardProps {
  icon: React.ComponentType;
  iconColor: string;
  title: string;
  description: string;
  primaryButton: {
    text: string;
    onClick?: () => void;
  };
  secondaryButton?: {
    text: string;
    onClick?: (e: React.MouseEvent) => void;
  };
}

export function AlertCardWhoop({
  icon,
  iconColor,
  title,
  description,
  primaryButton,
  secondaryButton,
}: WhoopAlertCardProps) {
  const supportModal = useDisclosure();

  return (
    <Flex
      direction={'column'}
      gap={3}
      mt={4}
      w={'full'}
      bg={'neutral.50'}
      px={4}
      py={3}
      rounded={'card'}
    >
      <HStack color={iconColor}>
        <Icon as={icon} boxSize={5} />
        <Text textStyle={'copy'}>{title}</Text>
      </HStack>
      <Text textStyle={'copy_small'}>{description}</Text>
      <HStack w={'full'}>
        {secondaryButton && (
          <Button
            variant={'secondary'}
            w={'full'}
            onClick={secondaryButton.onClick}
          >
            {secondaryButton.text}
          </Button>
        )}
        <Button variant={'primary'} w={'full'} onClick={primaryButton.onClick}>
          {primaryButton.text}{' '}
          <Icon as={ArrowDown} boxSize={4} transform={'rotate(225deg)'} />
        </Button>
      </HStack>
      <Text mx={'auto'} textStyle={'copy_extra_small'}>
        Still having trouble?{' '}
        <Button variant={'link'} onClick={() => supportModal.onOpen()}>
          <Text textStyle={'copy_extra_small'} color={'primary.500'}>
            Contact Support
          </Text>
        </Button>
      </Text>
    </Flex>
  );
}
