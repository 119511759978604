type BackHandler = () => boolean;

let currentBackHandler: BackHandler | null = null;

export function registerBackHandler(handler: BackHandler) {
  currentBackHandler = handler;
  return () => {
    if (currentBackHandler === handler) {
      currentBackHandler = null;
    }
  };
}

export function handleBack(): boolean {
  if (currentBackHandler) {
    return currentBackHandler();
  }
  return false;
}
