import { Flex, Text } from '@chakra-ui/react';

export function WhatShouldIDoContent() {
  return (
    <Flex direction="column" gap={4} w="full" pt={16}>
      <Text textStyle="h5" color="neutral.800">
        What should I do when I receive my WHOOP?
      </Text>

      <Flex direction="column" gap={4} px={2}>
        <Text textStyle="button" color="neutral.700">
          1. Download the WHOOP app and set up your band.
        </Text>

        <Text textStyle="button" color="neutral.700">
          2. Wear it for a full sleep cycle before syncing it with the Strive
          app.
        </Text>
      </Flex>

      <Text textStyle="button" color="neutral.700">
        Once synced, your HRV and Sleep data will populate in the progress tab.
        View your WHOOP every time you sleep to track your progress.
      </Text>
    </Flex>
  );
}

export function WhenToWearWhoopContent() {
  return (
    <Flex direction="column" gap={4} w="full" pt={16}>
      <Text textStyle="h5" color="neutral.800">
        When should I wear my WHOOP?
      </Text>

      <Text textStyle="button" color="neutral.700">
        Working in clinical spaces does not always allow you to wear jewelry.
        This is why we&apos;ve built our platform around metrics that can be
        collected during sleep.
      </Text>

      <Text textStyle="button" color="neutral.700">
        You are welcome to wear your device throughout the day if your career
        and lifestyle allow, but no additional data points will result from
        doing so.
      </Text>
    </Flex>
  );
}

export function SyncFrequencyContent() {
  return (
    <Flex direction="column" gap={4} w="full" pt={16}>
      <Text textStyle="h5" color="neutral.800">
        How often should I sync my WHOOP to the Strive app?
      </Text>

      <Text textStyle="button" color="neutral.700">
        Stay signed in to the WHOOP app and open it daily to ensure your data
        flows seamlessly. The Strive app will automatically sync with your WHOOP
        data when opened.
      </Text>

      <Text textStyle="button" color="neutral.700">
        For the most up-to-date data for your Arena Check-In, simply pull to
        refresh on the app&apos;s home screen to trigger a sync.
      </Text>
    </Flex>
  );
}

export function DataMissingContent() {
  return (
    <Flex direction="column" gap={4} w="full" pt={16}>
      <Text textStyle="h5" color="neutral.800">
        Why is my data missing?
      </Text>

      <Text textStyle="button" color="neutral.700">
        If your data isn&apos;t showing up in Strive, it might be due to an
        issue with your WHOOP sign-in. Here&apos;s what you can do:
      </Text>

      <Flex direction="column" gap={4} px={2}>
        <Text textStyle="button" color="neutral.700">
          1. Ensure you&apos;re signed into the WHOOP app and open it daily to
          keep data flowing.
        </Text>

        <Text textStyle="button" color="neutral.700">
          2. Try logging out and back into WHOOP.
        </Text>

        <Text textStyle="button" color="neutral.700">
          3. If data appears in WHOOP but not in Strive, contact us. For
          WHOOP-specific issues, reach out to WHOOP Support.
        </Text>
      </Flex>
    </Flex>
  );
}

export function DataCollectionContent() {
  return (
    <Flex direction="column" gap={4} w="full" pt={16}>
      <Text textStyle="h5" color="neutral.800" mb={6}>
        What data does Strive collect from WHOOP?
      </Text>

      <Text textStyle="copy_bold" color="neutral.800">
        Heart Rate Variability (HRV)
      </Text>

      <Text textStyle="button" color="neutral.700">
        HRV is the most reliable measurement of autonomic function. Increased
        HRV is associated with increased stress resilience and higher
        parasympathetic nervous system activity, whereas decreased HRV is
        representative of poorer stress recovery. WHOOP tracks HRV by measuring
        the average variability between successive heartbeats at structured time
        increments.
      </Text>

      <Text textStyle="copy_bold" color="neutral.800" mt={4}>
        Sleep Consistency
      </Text>

      <Text textStyle="button" color="neutral.700">
        Sleep consistency is a measurement of your bed-time and wake-time
        trends. Higher sleep consistency is associated with improved stress
        recovery and overall sleep quality. To find your sleep consistency
        score, WHOOP tracks your longest sleep period from the 24 hour day, and
        calculates the average of your longest single sleep periods from the
        past 7 days. This average sleep consistency score is a reflection of
        your sleep consistency trends throughout the week.
      </Text>
    </Flex>
  );
}

export function DataProtectionContent() {
  return (
    <Flex direction="column" gap={4} w="full" pt={16}>
      <Text textStyle="h5" color="neutral.800">
        How is my data protected?
      </Text>

      <Text textStyle="button" color="neutral.700">
        Here at Strive, you own your data. We house your data on HIPAA compliant
        servers within a closed loop system to keep it safe, but the only people
        who have access to your data are you, your coach, and - if you were to
        request support for a data-related problem - our user support engineers.
      </Text>
    </Flex>
  );
}
