import { Flex, Text } from '@chakra-ui/react';

export function DataProtectionContent() {
  return (
    <Flex direction="column" gap={4} w="full" pt={16}>
      <Text textStyle="h5" color="neutral.800">
        How is my data protected?
      </Text>

      <Text textStyle="button" color="neutral.700">
        Here at Strive, you own your data. We house your data on HIPAA compliant
        servers within a closed loop system to keep it safe, but the only people
        who have access to your data are you, your coach, and - if you were to
        request support for a data-related problem - our user support engineers.
      </Text>
    </Flex>
  );
}
