import { useForm } from 'react-hook-form';
import { VStack } from '@chakra-ui/react';

import { AlertBox, AlertTriangle, toast } from '@arena-labs/strive2-ui';

import { useAdminUndoMutation } from './admin-mutations';

export type AdminUndoProps = {
  formId: string;
  onSuccess: () => void;
};

export function AdminUndo({ formId, ...props }: AdminUndoProps) {
  const generateData = useAdminUndoMutation();
  const { handleSubmit } = useForm({
    defaultValues: {},
  });

  const onSubmit = async () => {
    try {
      const action = await generateData.mutateAsync();
      props.onSuccess();
      toast({ title: action.message, status: 'success' });
    } catch (error) {
      toast({ title: `Error: ${error}`, status: 'error' });
    }
  };

  return (
    <VStack
      as="form"
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      spacing="4"
      align="stretch"
    >
      <AlertBox
        status="info"
        title="UNDO"
        description="Undo your last action (check-in, practice, audio/video play, etc.)"
        variant="toast"
        showIcon={AlertTriangle}
      />
    </VStack>
  );
}
