import { Flex, Text } from '@chakra-ui/react';

export function WhatIsStrivewareContent() {
  return (
    <Flex direction="column" gap={4} w="full" pt={16}>
      <Text textStyle="h5" color="neutral.800">
        What is StriveWare?
      </Text>

      <Text textStyle="button" color="neutral.700">
        StriveWare is a ring that measures biometric indicators of stress and
        recovery. Each StriveWare device seamlessly connects to the Arena Strive
        app, allowing you to track and improve your individual recovery metrics
        with the help of Strive coaches and tools.
      </Text>
    </Flex>
  );
}
