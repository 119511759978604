// import { useMemo } from 'react';
import { useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  IconButton,
  Image,
  ListItem,
  OrderedList,
  Text,
} from '@chakra-ui/react';
import { useAnimate } from 'framer-motion';
import { match, P } from 'ts-pattern';
import { waitFor } from 'xstate5';

import {
  BackLink,
  BluetoothBlue,
  CloseIcon,
  LoadingBubbles,
  LottieFile,
  useResponsive,
  ZStack,
} from '@arena-labs/strive2-ui';
import { openSettingsPage } from '@strive/notifications';
import { StrivewareContext, StrivewareSelector } from '@strive/wearable';

import { useSetupActor } from './hooks';

export function SetupCharging({ onClose }: { onClose: () => void }) {
  const animation = useMemo(
    () => import('./scanning-for-devices.lottie.json'),
    [],
  );
  const strivewareActor = StrivewareContext.useActorRef();
  const state = StrivewareContext.useSelector(
    StrivewareSelector.bluetoothState,
  );
  const rs = useResponsive();
  const setupActor = useSetupActor();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    const onStartScanning = async () => {
      if (state === 'permission.prompt') {
        strivewareActor.send({ type: 'Request Permissions' });
        const snapshot = await waitFor(
          strivewareActor,
          (state) =>
            StrivewareSelector.bluetoothState(state) !== 'permission.prompt',
        );
        if (snapshot.matches('Bluetooth Ready')) {
          setupActor.send({ type: 'Next' });
        }
      } else if (state === 'bluetooth.ready') {
        setupActor.send({ type: 'Next' });
      }
    };
    if (state === 'bluetooth.ready' || state === 'permission.prompt') {
      onStartScanning();
    }
  }, [state, setupActor, strivewareActor]);

  useEffect(() => {
    if (scope.current) {
      animate(
        '.rotate',
        { rotate: 720 },
        {
          duration: 3,
          repeat: Infinity,
          ease: 'linear',
        },
      );
    }
  }, [animate, scope]);

  return (
    <Flex direction="column" px="4" py={4} gap="4" h="full">
      <Grid
        templateColumns="auto 1fr auto"
        alignItems="center"
        gap="4"
        w="full"
      >
        <BackLink
          href="/"
          color="neutral.800"
          onClick={(event) => {
            event.preventDefault();
            setupActor.send({ type: 'Back' });
          }}
        />
        <Text textStyle={'copy_bold'} textAlign={'center'}>
          Pair StriveWare
        </Text>
        <IconButton
          aria-label="Close"
          icon={<Icon as={CloseIcon} boxSize={6} />}
          onClick={() => onClose()}
        />
      </Grid>

      {match(state)
        .with(P.union('permission.prompt', 'bluetooth.ready'), () => (
          <Flex direction="column" px="6" pt="4" alignItems="center" h="full">
            <ZStack my={'auto'}>
              <Image
                alt=""
                src="/images/striveware/ring-ghost.png"
                w={'280px'}
                h={'auto'}
                opacity={'0.05'}
              />
              <LottieFile
                as={animation}
                boxSize={rs({ base: '280px', xs: '200px' })}
                mx={'auto'}
                className="rotate"
                mt={2}
              />
            </ZStack>
            <Flex align="center" gap="4" mt={'auto'} mb={4} ref={scope}>
              <Icon
                as={LoadingBubbles}
                boxSize="20px"
                color={'neutral.800'}
                className="rotate"
              />{' '}
              <Text textStyle="copy_small" color={'neutral.800'}>
                Connecting...
              </Text>
            </Flex>
          </Flex>
        ))
        .with('permission.denied', () => (
          <>
            <Icon
              as={BluetoothBlue}
              boxSize="40px"
              mr={'auto'}
              ml={'-2'}
              mt="8"
            />
            <Text
              textStyle={rs({ base: 'h1', xs: 'h2' })}
              as="h1"
              color="neutral.800"
            >
              Enable Bluetooth permissions
            </Text>
            <Text textStyle="copy" color="neutral.600">
              To complete the pairing process and access data, please allow
              Bluetooth permission in your phone settings.
            </Text>
            <Box>
              <Text textStyle="copy" color="neutral.600">
                Follow these steps to enable Bluetooth permission for Arena
                Strive:
              </Text>
              <OrderedList
                pl="2"
                spacing="0"
                textStyle="copy"
                color="neutral.600"
              >
                <ListItem>Go to Settings on your phone.</ListItem>
                <ListItem>Select Arena Strive from the list of apps.</ListItem>
                <ListItem>
                  Allow Arena Strive to access Bluetooth by toggling the switch
                  to ON.
                </ListItem>
              </OrderedList>
            </Box>
            <Button
              variant={'secondary'}
              onClick={() => openSettingsPage()}
              mt="auto"
            >
              Open Settings
            </Button>
            <Button
              variant={'primary'}
              onClick={() => setupActor.send({ type: 'Back' })}
            >
              Try Again
            </Button>
          </>
        ))
        .with('bluetooth.off', () => (
          <>
            <Icon
              as={BluetoothBlue}
              boxSize="40px"
              mr={'auto'}
              ml={'-2'}
              mt="4"
            />
            <Text
              textStyle={rs({ base: 'h2', xs: 'h4' })}
              as="h1"
              color="neutral.800"
            >
              Enable Bluetooth
            </Text>
            <Text textStyle="copy" color="neutral.600">
              To complete the pairing process and access data, please turn
              Bluetooth on in your phone settings.
            </Text>
            <Button
              variant={'primary'}
              mt={'auto'}
              onClick={() => setupActor.send({ type: 'Back' })}
            >
              Try Again
            </Button>
          </>
        ))
        .exhaustive()}
    </Flex>
  );
}
