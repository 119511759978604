import React, { useEffect, useMemo, useState } from 'react';
import {
  Collapse,
  HStack,
  Icon,
  Image,
  keyframes,
  Text,
} from '@chakra-ui/react';
import { match } from 'ts-pattern';
import { z } from 'zod';

import {
  BluetoothIcon,
  CheckCircleIcon,
  SyncWarning,
  TriangleAlert,
  WaitForQuery,
} from '@arena-labs/strive2-ui';
import { openBluetoothSettings, openSettingsPage } from '@strive/notifications';
import { createDataStore, useDataStore } from '@strive/utils';
import {
  StrivewareContext,
  StrivewareSelector,
  useStrivewareBattery,
} from '@strive/wearable';

import { AlertCardStriveware } from './alert-card-striveware';

// Define the spinning animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SYNC_SUCCESS_DURATION = 300000; // 5 minutes in milliseconds

const syncTimeStore = createDataStore({
  key: 'Striveware.LastSyncTime',
  schema: z.number(),
});

export function DeviceAlerts() {
  const batteryQuery = useStrivewareBattery();
  const strivewareActor = StrivewareContext.useActorRef();
  const [showSyncFailedAlert, setShowSyncFailedAlert] = useState(false);
  const [lastSyncTime, setLastSyncTime] = useDataStore(syncTimeStore);

  const state = StrivewareContext.useSelector(
    StrivewareSelector.bluetoothState,
  );

  const deviceState = StrivewareContext.useSelector(
    StrivewareSelector.deviceState,
  );

  // Update showSyncFailedAlert and lastSyncTime based on deviceState
  useEffect(() => {
    if (deviceState === 'sync-failed') {
      setShowSyncFailedAlert(true);
    } else if (deviceState === 'syncing') {
      // Hide the alert when syncing starts
      setShowSyncFailedAlert(false);
    } else if (deviceState === 'synced') {
      setLastSyncTime(Date.now());
    }
  }, [deviceState, setLastSyncTime]);

  // Calculate whether to show success message
  const showSyncSuccess = useMemo(() => {
    if (!lastSyncTime) return false;
    return Date.now() - lastSyncTime < SYNC_SUCCESS_DURATION;
  }, [lastSyncTime]);

  const handleSyncAgain = () => {
    // Hide the alert
    setShowSyncFailedAlert(false);
    // Trigger sync
    strivewareActor.send({ type: 'Sync' });
  };

  return (
    <>
      <WaitForQuery query={batteryQuery} loading={null} error={null}>
        {({ batteryStatus, batteryLevel }) =>
          batteryLevel <= 20 && (
            <AlertCardStriveware
              icon={TriangleAlert}
              iconColor={batteryLevel <= 10 ? 'negative.400' : 'warning.400'}
              title="Battery level is low."
              description="Charge your device before going to bed to ensure uninterrupted data sync."
            />
          )
        }
      </WaitForQuery>
      {match(state)
        .with('bluetooth.off', () => (
          <AlertCardStriveware
            icon={BluetoothIcon}
            iconColor="warning.400"
            title="Bluetooth Disabled"
            description="Bluetooth is off. Please turn it on in your phone's settings to connect to your StriveWare."
            primaryButton={{
              text: 'Open Settings',
              onClick: () => openBluetoothSettings(),
            }}
          />
        ))
        .with('permission.denied', () => (
          <>
            <AlertCardStriveware
              icon={TriangleAlert}
              iconColor="negative.400"
              title="Bluetooth permission in disabled."
              description="Strive needs Bluetooth permissions to connect. Please enable them in your phone's settings."
              primaryButton={{
                text: 'Open Settings',
                onClick: () => openSettingsPage(),
              }}
            />
          </>
        ))
        .with('permission.prompt', () => (
          <AlertCardStriveware
            icon={TriangleAlert}
            iconColor="negative.400"
            title="Allow Bluetooth for StriveWare"
            description="Strive needs Bluetooth permissions to connect."
            primaryButton={{
              text: 'Enable Bluetooth Access',
              onClick: () =>
                strivewareActor.send({ type: 'Request Permissions' }),
            }}
          />
        ))
        .with('bluetooth.ready', () => null)
        .exhaustive()}
      <Collapse in={showSyncFailedAlert} animateOpacity>
        <AlertCardStriveware
          icon={SyncWarning}
          iconColor="warning.400"
          title="Sync timed out"
          description="The sync timed out, possibly because you left the app. Keep the app open to ensure a complete sync."
          primaryButton={{
            text: 'Sync again',
            onClick: handleSyncAgain,
          }}
        />
      </Collapse>
      {deviceState === 'syncing' && (
        <HStack
          bg={'neutralAlpha.50'}
          p={4}
          borderRadius={'card'}
          spacing={3}
          mt={4}
        >
          <Image
            src={'./images/striveware/Sync.png'}
            w={8}
            alt={'Syncing icon'}
            animation={`${spin} 2s linear infinite`}
            color={'neutral.400'}
          />

          <Text textStyle={'copy_small'}>
            Syncing your data. Keep the app open for a smooth and complete sync.
          </Text>
        </HStack>
      )}
      {showSyncSuccess && deviceState !== 'syncing' && !showSyncFailedAlert && (
        <HStack
          bg={'neutralAlpha.50'}
          p={4}
          borderRadius={'card'}
          spacing={3}
          mt={4}
        >
          <Icon as={CheckCircleIcon} boxSize={6} color={'positive.300'} />

          <Text textStyle={'copy_small'}>Your data is all caught up!</Text>
        </HStack>
      )}
    </>
  );
}
