import React, { useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  chakra,
  Flex,
  Text,
} from '@chakra-ui/react';

import { ChevronRightIcon, useResponsive } from '@arena-labs/strive2-ui';
import { StrivewareContext, StrivewareSelector } from '@strive/wearable';

export function DeviceInfo() {
  const [isOpen, setIsOpen] = useState(false);
  const device = StrivewareContext.useSelector(StrivewareSelector.userDevice);
  const rs = useResponsive();

  return (
    <Accordion allowToggle>
      <AccordionItem border="none">
        <AccordionButton
          p={0}
          textStyle={rs({ base: 'button', xs: 'copy_small' })}
          display="inline-flex"
          justifyContent="space-between"
          alignItems="center"
          fontWeight={'md'}
          _hover={{ bg: 'transparent' }}
          onClick={() => setIsOpen(!isOpen)}
        >
          Device Info
          <ChevronRightIcon
            style={{
              transform: isOpen ? 'rotate(270deg)' : 'rotate(90deg)',
              transition: 'transform 0.2s ease-in-out',
              width: '12px',
              height: '12px',
            }}
          />
        </AccordionButton>
        <AccordionPanel pb={0} pt={6} px={0}>
          <chakra.dl
            display="flex"
            flexDirection="column"
            gap="2"
            px={4}
            py={2}
            rounded={'card'}
            bg={'neutralAlpha.50'}
          >
            <Text textStyle="copy" color="neutral.800">
              Device Info
            </Text>
            <Flex gap="2" py={3}>
              <chakra.dt textStyle="copy_small" color="neutral.800">
                Device Name:
              </chakra.dt>
              <chakra.dd textStyle="copy_small" color="neutral.860">
                {device?.name ?? 'No Device Connected'}
              </chakra.dd>
            </Flex>
          </chakra.dl>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
