import { Box } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';

import { useGradient } from '@arena-labs/strive2-ui';
import { IfWearable } from '@strive/wearable';

import { StrivewareHelpSwiper } from './content/striveware/striveware-help-swiper';
import { WhoopHelpSwiper } from './content/whoop/whoop-help-swiper';
import { useSupportHub } from './use-support-hub';

const supportVariants = {
  enter: {
    y: '100%',
  },
  center: {
    y: 0,
    transition: {
      type: 'spring',
      damping: 30,
      stiffness: 300,
    },
  },
  exit: {
    y: '100%',
    transition: {
      type: 'spring',
      damping: 30,
      stiffness: 300,
    },
  },
};

export function SupportHub({ children }: { children: React.ReactNode }) {
  const { isOpen, onClose } = useSupportHub();
  const bgGradient = useGradient('background');

  return (
    <>
      <style>
        {`
           @supports (padding-top: env(safe-area-inset-top)) {
              iframe[title="Messaging window"] {
                padding-top: env(safe-area-inset-top) !important;
              }
            }
        `}
      </style>
      <Box position="relative" h="100%" overflow="hidden">
        {/* Main view stays static */}
        <Box position="absolute" width="100%" height="100%">
          {children}
        </Box>

        {/* Support view animates over the top */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              key="support"
              initial="enter"
              animate="center"
              exit="exit"
              variants={supportVariants}
              className="support-hub-content"
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: bgGradient,
                zIndex: 9999,
              }}
            >
              <IfWearable mode={'partner'}>
                <WhoopHelpSwiper onClose={onClose} />
              </IfWearable>
              <IfWearable mode={'striveware'}>
                <StrivewareHelpSwiper onClose={onClose} />
              </IfWearable>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </>
  );
}
