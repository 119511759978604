import { Fragment, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Dialog } from '@capacitor/dialog';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { stripIndent } from 'common-tags';
import { z } from 'zod';

import {
  AlertBox,
  AlertTriangle,
  CloseIcon,
  DD,
  DL,
  DT,
  Elevation,
  Icon,
  pluralize,
  PlusIcon,
  WaitForQuery,
} from '@arena-labs/strive2-ui';
import { queryClient } from '@strive/api';
import { saveAs } from '@strive/utils';

import { useDatabase } from '../../lib/sqlite';

type RowSet = Array<Record<string, string | number | null | boolean>>;

export function AdminDatabase() {
  const { db } = useDatabase();
  const databaseInfo = useQuery({
    queryKey: ['db', 'schema'],
    queryFn: async () => ({
      name: await db?.getConnectionDBName(),
      tables: await db?.getTableList(),
      version: (await db?.getVersion())?.version ?? 'unknown',
    }),
  });

  const counter = useRef(0);
  const [queries, setQueries] = useState([counter.current]);
  const [activeTab, setActiveTab] = useState(0);

  const onCloseQuery = (query: number) => {
    const queryIndex = queries.findIndex((q) => q === query);
    const updatedQueries = queries.filter((q) => q !== query);
    setQueries(updatedQueries);

    let nextQueryIndex =
      updatedQueries[queryIndex] !== undefined ? queryIndex : queryIndex - 1;
    setActiveTab(nextQueryIndex + 1);
  };

  const onAddQuery = () => {
    setQueries((prev) => prev.concat(++counter.current));
    setActiveTab(queries.length + 1);
  };

  const toast = useToast();

  const onDeleteDatabase = async () => {
    const { value: confirmed } = await Dialog.confirm({
      title: 'Delete Database',
      message: 'Are you sure you want to delete the database?',
    });
    if (confirmed) {
      await db?.close();
      await db?.delete();
      queryClient.invalidateQueries(['db']);
      toast({
        title: 'Database deleted',
        description:
          'The database has been deleted. It will be recreated on the next reload/app start.',
      });
    }
  };

  const onExport = async () => {
    const json = await db?.exportToJson('full');
    try {
      saveAs({
        filename: 'strive-database.json',
        data: JSON.stringify(json, null, 2),
        type: 'application/json;charset=utf-8',
      });
      toast({ title: 'Database exported' });
    } catch (e) {
      // User aborted download
      console.log(e);
    }
  };

  return (
    <WaitForQuery query={databaseInfo}>
      {({ name, version, tables }) => (
        <Tabs
          m="-4"
          index={activeTab}
          onChange={setActiveTab}
          variant="solid-rounded"
        >
          <TabList
            position="fixed"
            bg="bg.primary"
            zIndex="1"
            w="full"
            maxW="full"
            overflowX="auto"
            overflowY="hidden"
            pr="16"
            pb="2"
          >
            <Tab>Schema</Tab>
            {queries.map((index) => (
              <Tab key={index} py="0" flexShrink="0">
                Query {index + 1}
                <Button
                  p="0"
                  colorScheme="black"
                  ml="4"
                  minW="0"
                  onClick={() => onCloseQuery(index)}
                >
                  <Icon as={CloseIcon} />
                </Button>
              </Tab>
            ))}
            <Box position="fixed" right="0" px="2" py="1" bg="bg.primary">
              <Button variant="outline" onClick={() => onAddQuery()} p="2">
                <Icon as={PlusIcon} />
              </Button>
            </Box>
          </TabList>
          <TabPanels pt="10" px="4">
            <TabPanel>
              <Flex direction="column" gap="4">
                <DL gridTemplateColumns="auto 1fr">
                  <DT>Name:</DT>
                  <DD>{name}</DD>

                  <DT>Version:</DT>
                  <DD>{version}</DD>
                </DL>

                <Flex gap="4">
                  <Button
                    colorScheme="danger"
                    onClick={onDeleteDatabase}
                    flexBasis="50%"
                  >
                    Delete
                  </Button>
                  <Button
                    colorScheme="primary"
                    onClick={onExport}
                    flexBasis="50%"
                  >
                    Export
                  </Button>
                </Flex>

                <Divider />

                <Text textStyle={'h3'}>Tables:</Text>
                {tables?.values?.map((table, index, arr) => (
                  <Fragment key={index}>
                    <DatabaseTable name={table} />
                    {index < arr.length - 1 && <Divider />}
                  </Fragment>
                ))}
              </Flex>
            </TabPanel>
            {queries.map((query) => (
              <TabPanel key={query}>
                <DatabaseQueryForm
                  saveAsFilename={`strive-query-${query + 1}.csv`}
                  table={tables?.values?.[0] ?? 'unknown'}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      )}
    </WaitForQuery>
  );
}

function DatabaseTable({ name }: { name: string }) {
  const tableInfo = useTableInfo(name);
  const { db } = useDatabase();

  const getTableRows = async () => {
    const data = await db?.query(`SELECT * FROM ${name}`);
    return data?.values as RowSet;
  };
  return (
    <WaitForQuery query={tableInfo} loading={`Loading "${name}" table info...`}>
      {(data) => (
        <Flex
          as={Elevation}
          level="8dp"
          borderRadius="card"
          direction="column"
          gap="2"
          p="4"
        >
          <Text textStyle={'copy_bold'} color="secondary.700">
            {name.toUpperCase()}
          </Text>
          <QueryResultTable
            data={data.columns}
            count={data.count}
            saveAsFilename={`strive-db-table-${name}.csv`}
            saveData={getTableRows}
          />
        </Flex>
      )}
    </WaitForQuery>
  );
}

function useTableInfo(name: string) {
  const { db } = useDatabase();
  const query = useQuery({
    queryKey: ['db', 'table', name],
    queryFn: () =>
      Promise.all([
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        db!.query(`PRAGMA table_info(${name})`),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        db!.query(`SELECT COUNT(*) as count FROM ${name}`),
      ]),
    enabled: !!db,
    select: ([tableInfo, count]) => {
      return { columns: tableInfo.values, ...count.values?.[0] } as {
        columns: RowSet;
        count: number;
      };
    },
  });
  return query;
}

function QueryResultTable({
  data,
  count = data.length,
  saveAsFilename = 'strive-database-export.csv',
  saveData = data,
}: {
  data: RowSet;
  count?: number;
  saveAsFilename?: string;
  saveData?: RowSet | Promise<RowSet> | (() => Promise<RowSet>);
}) {
  if (!data[0]) return <Text textStyle={'copy'}>No results</Text>;

  const columns = Object.keys(data[0]);
  return (
    <Flex direction="column" gap="2">
      <Flex>
        <Text textStyle={'copy_small'}>
          {count} {pluralize(count, 'row')}
        </Text>
        <Button
          ml="auto"
          variant="underline-link"
          color="secondary.500"
          textStyle={'copy_small'}
          px="0"
          onClick={() => {
            downloadCsv(
              saveAsFilename,
              typeof saveData === 'function' ? saveData() : saveData,
            );
          }}
        >
          Download
        </Button>
      </Flex>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              {columns.map((col, index) => (
                <Th key={index} color="white">
                  {col}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, index) => (
              <Tr key={index}>
                {columns.map((col, index) => (
                  <Td key={index}>{row[col]}</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}

function DatabaseQueryForm({
  table,
  saveAsFilename: saveAs,
}: {
  table: string;
  saveAsFilename: string;
}) {
  const querySchema = z.object({
    query: z.string().min(1, 'A query is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof querySchema>>({
    defaultValues: {
      query: stripIndent`
        SELECT *
          FROM ${table}
          ORDER BY ROWID DESC
          LIMIT 10
        ;`,
    },
    resolver: zodResolver(querySchema),
  });

  const { db, saveDB } = useDatabase();

  const execute = useMutation({
    mutationFn: async ({ query: statement }: { query: string }) => {
      //? We need to call different methods depending on whether the query is a
      //? SELECT or not. If it is, we need to use `query` instead of `execute`.
      // This regex is a bit of a hack, and we should probably use
      // https://npm.im/sql-query-identifier for a more robust solution.
      const isQuery = statement.match(/^\s*(SELECT|PRAGMA)/i);
      const res = isQuery
        ? await db?.query(statement)
        : await db?.execute(statement);
      return res;
    },
    onSuccess: () => {
      saveDB();
      queryClient.invalidateQueries(['db']);
    },
  });

  const result =
    execute.data && 'values' in execute.data
      ? (execute.data.values as RowSet)
      : execute.data && 'changes' in execute.data
      ? ([execute.data.changes] as RowSet)
      : null;

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit((data) => execute.mutate(data))}
      direction="column"
      gap="4"
    >
      <FormControl id="query" isInvalid={!!errors.query}>
        <FormLabel>Query:</FormLabel>
        <Textarea
          {...register('query')}
          rows={8}
          autoCorrect="off"
          spellCheck="false"
          autoCapitalize="off"
        />
        <FormErrorMessage>{errors.query?.message}</FormErrorMessage>
      </FormControl>
      <Button
        variant="primary"
        size="sm"
        isLoading={execute.isLoading}
        type="submit"
      >
        Execute
      </Button>

      {execute.isSuccess && result ? (
        <>
          <Text textStyle={'copy_bold'}>Result:</Text>
          <Elevation level="8dp" borderRadius="card" p="4">
            <QueryResultTable data={result} saveAsFilename={saveAs} />
          </Elevation>
        </>
      ) : execute.isError ? (
        <AlertBox
          title={String(execute.error)}
          status="error"
          variant="toast"
          showIcon={AlertTriangle}
        />
      ) : null}
    </Flex>
  );
}

async function downloadCsv(filename: string, data: RowSet | Promise<RowSet>) {
  data = await data;
  const { stringify } = await import('csv-stringify/sync');
  const csv = stringify(data, { header: true, bom: true });
  try {
    return saveAs({ filename, data: csv, type: 'text/csv;charset=utf-8' });
  } catch (e) {
    // User aborted download
    console.log(e);
  }
}
