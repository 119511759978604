import React from 'react';
import { Flex } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';

import { AnalyticsContext, DataTrackingTag } from '@arena-labs/analytics';
import { PillarPractice } from '@arena-labs/shared-models';
import { WaitForQuery, ZStack } from '@arena-labs/strive2-ui';
import { $API } from '@strive/api';

import { useAciMachine } from './aci-context';
import { CheckInInsights } from './checkin-insights/check-in-insights';
import { ComparisonAnimation } from './checkin-insights/comparison-animation';
import { GeneratingInsights } from './checkin-insights/generating-insights';
import { ContextInfo } from './context-info';
import { StreakInfo } from './streak-info';
import { AciUserInputs } from './user-input-views/aci-user-inputs';

export function ACI({
  banner,
  logPracticeCard,
  onContactSupport,
  supportHub,
}: {
  banner: (onNavigateBack?: () => void) => React.ReactNode;
  logPracticeCard: (practice: PillarPractice) => React.ReactNode;
  onContactSupport?: () => void;
  supportHub: any;
}) {
  const [state, send] = useAciMachine();
  const streakData = $API.useGetStreakData();

  const shouldShowBanner =
    state.matches({ Open: 'userInputs' }) ||
    state.matches({ Open: 'insights' }) ||
    state.matches({ Open: 'contextInfo' }) ||
    state.matches({ Open: 'displayStreakInfo' });

  const canNavigateBack = state.can({ type: 'back' });
  const goBack = () => send({ type: 'back' });

  return (
    <AnalyticsContext context={{ tags: [DataTrackingTag.ACI] }}>
      {/* i want to move the banner up to this level, but i think we might want to combine the state machines in that case TODO */}
      {shouldShowBanner && banner(canNavigateBack ? goBack : undefined)}

      <WaitForQuery query={streakData}>
        {(streaks) => {
          return (
            <Flex
              direction={'column'}
              w={'full'}
              pt={0}
              px={6}
              gridArea="content"
              transition={'all 0.3s ease-in-out'}
            >
              <ZStack h={'full'}>
                <AnalyticsContext context={{ tags: [DataTrackingTag.ACIForm] }}>
                  <AnimatePresence>
                    {state.matches({ Open: 'userInputs' }) ? (
                      <AciUserInputs
                        supportHub={supportHub}
                        key="inputs"
                        logPracticeCard={logPracticeCard}
                        onContactSupport={onContactSupport}
                      />
                    ) : null}
                  </AnimatePresence>
                  <AnimatePresence>
                    {state.matches({ Open: 'generatingInsights' }) ||
                    state.matches({ Open: 'waiting' }) ? (
                      <GeneratingInsights
                        currentStreak={streaks.streak.current + 1}
                        totalACIs={streaks.streak.total_acis + 1}
                        maxStreak={streaks.streak.max}
                        streakRecord={
                          streaks?.history
                            ? [
                                ...streaks.history.slice(0, 3),
                                { date: new Date(), status: 'hit' },
                                ...streaks.history.slice(-3),
                              ]
                            : []
                        }
                      />
                    ) : null}
                  </AnimatePresence>

                  <AnimatePresence>
                    {state.matches({ Open: 'comparison' }) ? (
                      <ComparisonAnimation
                        onAnimationEnd={() => send({ type: 'ANIMATION_END' })}
                        estimate={
                          state.context.aciResponse?.reflection_selection
                        }
                      />
                    ) : null}
                  </AnimatePresence>
                </AnalyticsContext>

                <AnalyticsContext
                  context={{ tags: [DataTrackingTag.ACIInsights] }}
                >
                  <AnimatePresence>
                    {state.matches({ Open: 'insights' }) ? (
                      <CheckInInsights
                        logPracticeCard={logPracticeCard}
                        onOpenInfo={() => send({ type: 'OPEN_CONTEXT_INFO' })}
                      />
                    ) : null}
                  </AnimatePresence>

                  <AnimatePresence>
                    {state.matches({ Open: 'contextInfo' }) ? (
                      <ContextInfo />
                    ) : null}
                  </AnimatePresence>
                  <AnimatePresence>
                    {state.matches({ Open: 'displayStreakInfo' }) ? (
                      <StreakInfo onClose={() => send({ type: 'back' })} />
                    ) : null}
                  </AnimatePresence>
                </AnalyticsContext>
              </ZStack>
            </Flex>
          );
        }}
      </WaitForQuery>
    </AnalyticsContext>
  );
}
