import { useCallback, useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import {
  Box,
  BoxProps,
  Button,
  Flex,
  Icon,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  AlertBox,
  AlertTriangle,
  CheckCircleIcon,
  HeadsetIcon,
  InfoIcon,
  SyncingIcon,
  SyncWarning,
} from '@arena-labs/strive2-ui';
import { StrivewareContext, StrivewareSelector } from '@strive/wearable';

import { registerBackHandler } from '../../../../../lib/back-handler';
import { toggleZendeskChat } from '../../../../../lib/zendesk';

type NotificationProps = {
  title: string;
  description?: string;
  status?: 'info' | 'warning' | 'success' | 'error' | 'loading';
  duration?: number;
  showIcon?: boolean | BoxProps['as'];
  onClose: () => void;
};

function Notification({
  title,
  description,
  status = 'info',
  duration = 5000,
  showIcon,
  onClose,
}: NotificationProps) {
  useEffect(() => {
    const timer = setTimeout(onClose, duration);
    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <Box
      as={motion.div}
      mb={4}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
    >
      <AlertBox
        status={status}
        title={title}
        description={description}
        variant="toast"
        showIcon={showIcon}
      />
    </Box>
  );
}

export function SyncingIssueContent() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState<any>(null);
  const [isSyncing, setIsSyncing] = useState(false);
  const strivewareActor = StrivewareContext.useActorRef();
  const isConnected = StrivewareContext.useSelector(
    StrivewareSelector.isConnected,
  );
  const deviceState = StrivewareContext.useSelector(
    StrivewareSelector.deviceState,
  );
  const isWeb = Capacitor.getPlatform() === 'web';
  const [slide1Notifications, setSlide1Notifications] = useState<
    Array<{ id: number } & NotificationProps>
  >([]);
  const [slide2Notifications, setSlide2Notifications] = useState<
    Array<{ id: number } & NotificationProps>
  >([]);

  const removeSlide1Notification = useCallback((id: number) => {
    setSlide1Notifications((prev) => prev.filter((n) => n.id !== id));
  }, []);

  const removeSlide2Notification = useCallback((id: number) => {
    setSlide2Notifications((prev) => prev.filter((n) => n.id !== id));
  }, []);

  const addSlide1Notification = useCallback(
    (notification: Omit<NotificationProps, 'onClose'>) => {
      const id = Date.now();
      setSlide1Notifications((prev) => [
        ...prev,
        { ...notification, id, onClose: () => removeSlide1Notification(id) },
      ]);
    },
    [removeSlide1Notification],
  );

  const addSlide2Notification = useCallback(
    (notification: Omit<NotificationProps, 'onClose'>) => {
      const id = Date.now();
      setSlide2Notifications((prev) => [
        ...prev,
        { ...notification, id, onClose: () => removeSlide2Notification(id) },
      ]);
    },
    [removeSlide2Notification],
  );

  const handleSync = () => {
    // First check if we're on desktop web
    if (isWeb) {
      addSlide1Notification({
        title: 'Not available on desktop',
        status: 'error',
        showIcon: AlertTriangle,
      });

      return;
    }

    // Then check if device is connected
    if (!isConnected) {
      addSlide1Notification({
        title:
          'Please make sure your StriveWare device is connected before syncing.',
        status: 'error',
        showIcon: AlertTriangle,
      });
      return;
    }

    // Check if device is already syncing
    if (deviceState === 'syncing') {
      addSlide1Notification({
        title:
          'Your StriveWare device is syncing. Please wait for it to complete.',
        status: 'info',
        showIcon: InfoIcon,
      });
      return;
    }

    // Start the sync
    addSlide1Notification({
      title: 'Sync Started, device is syncing data...',
      status: 'info',
      showIcon: InfoIcon,
    });

    setIsSyncing(true);
    strivewareActor.send({ type: 'Sync' });
  };

  const handleUnpair = () => {
    // First check if we're on desktop web
    if (isWeb) {
      addSlide2Notification({
        title: 'Not available on desktop',
        description:
          'Unpairing is only available on the mobile app. Please use your mobile device to unpair your StriveWare.',
        status: 'warning',
      });
      return;
    }

    // Then check if device is connected
    if (!isConnected) {
      addSlide2Notification({
        title: 'No StriveWare device is currently connected.',
        status: 'error',
        showIcon: AlertTriangle,
      });
      return;
    }

    // Start the unpair process
    addSlide2Notification({
      title: 'Your StriveWare device is being unpaired...',
      status: 'info',
      showIcon: InfoIcon,
    });

    strivewareActor.send({ type: 'Unpair Device' });
  };

  // Watch for sync completion/failure
  useEffect(() => {
    if (!isSyncing) return;

    if (deviceState === 'synced') {
      addSlide1Notification({
        title: 'All set! Your data is up to date.',
        status: 'success',
        showIcon: CheckCircleIcon,
      });
      setIsSyncing(false);
    } else if (deviceState === 'sync-failed') {
      addSlide1Notification({
        title: 'We are not able to sync your data.',
        status: 'warning',
        showIcon: SyncWarning,
      });
      setIsSyncing(false);
    } else if (deviceState === 'syncing') {
      addSlide1Notification({
        title:
          'Syncing your data. Keep the app open for a smooth and complete sync.',
        status: 'loading',
        showIcon: SyncingIcon,
      });
    }
  }, [deviceState, addSlide1Notification, isSyncing]);

  function FirstSlide() {
    return (
      <Flex direction="column" h="full" position="relative" pt={16} pb={4}>
        <Box flex="1" overflowY="auto">
          <Text textStyle="h5" color="neutral.800" mb={4}>
            Having trouble syncing your StriveWare?
          </Text>

          <Text textStyle="button" color="neutral.700">
            First, make sure to keep the app open while syncing. Try{' '}
            <Button
              variant="link"
              textDecoration="underline"
              color="inherit"
              onClick={() => {
                handleSync();
              }}
              p={0}
              h="auto"
              minW="auto"
              fontWeight="inherit"
              fontSize="inherit"
            >
              sync again
            </Button>
            .
          </Text>
        </Box>
        <Box position="relative" mt={4}>
          <AnimatePresence>
            {slide1Notifications.map((notification) => (
              <Notification key={notification.id} {...notification} />
            ))}
          </AnimatePresence>
        </Box>
        <Box>
          <Button
            variant="secondary"
            w="full"
            onClick={() => {
              setCurrentSlide(1);
              swiperInstance?.slideNext();
            }}
          >
            Not resolved
          </Button>
        </Box>
      </Flex>
    );
  }

  function SecondSlide() {
    return (
      <Flex direction="column" h="full" position="relative" pt={16} pb={16}>
        <Box flex="1" overflowY="auto">
          <Text textStyle="h5" color="neutral.800" mb={4}>
            Still not working? Try these steps:
          </Text>

          <UnorderedList pl={2}>
            <ListItem>
              <Text textStyle="button" color="neutral.700">
                Force close the Strive app and reopen it.
              </Text>
            </ListItem>
            <ListItem>
              <Text textStyle="button" color="neutral.700">
                <Button
                  variant="link"
                  textDecoration="underline"
                  color="inherit"
                  onClick={handleUnpair}
                  p={0}
                  h="auto"
                  minW="auto"
                  fontWeight="inherit"
                  fontSize="inherit"
                >
                  Unpair
                </Button>{' '}
                and repair your StriveWare.
              </Text>
            </ListItem>
            <ListItem>
              <Text textStyle="button" color="neutral.700">
                Delete the Strive app, then re-download it. (Don&apos;t worry -
                your progress and data are saved in your profile!)
              </Text>
            </ListItem>
          </UnorderedList>
        </Box>
        <Box position="relative" mt={4}>
          <AnimatePresence>
            {slide2Notifications.map((notification) => (
              <Notification key={notification.id} {...notification} />
            ))}
          </AnimatePresence>
        </Box>
        <Button
          variant="secondary"
          position="absolute"
          bottom={4}
          left={0}
          right={0}
          onClick={() => {
            setCurrentSlide(2);
            swiperInstance?.slideNext();
          }}
        >
          Not resolved
        </Button>
      </Flex>
    );
  }

  function ThirdSlide() {
    return (
      <Flex direction="column" h="full" position="relative" pt={16}>
        <Box flex="1" overflowY="auto">
          <Text textStyle="h5" color="neutral.800" mb={4}>
            Seems like you need some more help.
          </Text>

          <Text textStyle="button" color="neutral.700">
            Please reach out to our live chat! An expert from our team will work
            with you to resolve the issue.
          </Text>
        </Box>
        <Button
          variant="primary"
          position="absolute"
          bottom={4}
          left={0}
          right={0}
          onClick={() => toggleZendeskChat(true)}
        >
          <Icon as={HeadsetIcon} boxSize={6} color="primary.800" /> Chat with us
        </Button>
      </Flex>
    );
  }

  useEffect(() => {
    const handleBackButton = () => {
      if (currentSlide > 0) {
        setCurrentSlide(currentSlide - 1);
        swiperInstance?.slidePrev();
        return true;
      }
      return false;
    };

    const unsubscribe = registerBackHandler(handleBackButton);
    return () => unsubscribe();
  }, [currentSlide, swiperInstance]);

  return (
    <Swiper
      style={{
        width: '100%',
        height: '100%',
      }}
      allowTouchMove={false}
      onSwiper={setSwiperInstance}
      onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
    >
      <SwiperSlide>
        <FirstSlide />
      </SwiperSlide>

      <SwiperSlide>
        <SecondSlide />
      </SwiperSlide>

      <SwiperSlide>
        <ThirdSlide />
      </SwiperSlide>
    </Swiper>
  );
}
