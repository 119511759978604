import { useMountedState } from 'react-use';
import {
  Button,
  Flex,
  Link as ChakraLink,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useMachine } from '@xstate/react';

import { LoadingOrError } from '@arena-labs/strive2-ui';
import { useIsLoggedIn } from '@strive/api';

import { useSupportHub } from '../help-center/use-support-hub';
import { ContactModal } from '../support/contact-modal';
import { ErrorLayout } from './error-layout';
import { errorRecoveryMachine } from './error-recovery.machine';

export type GeneralErrorProps = {
  title: string;
  onReload?: () => void;
};

export function GeneralError({
  title = 'That was unexpected...',
  onReload,
}: GeneralErrorProps) {
  const helpDisclosure = useDisclosure();
  const [state, send] = useMachine(errorRecoveryMachine, {
    context: { onRecover: onReload },
  });

  // Check if the user is authenticated
  const isAuthenticated = useIsLoggedIn();

  // Get the SupportHub context for authenticated users
  const supportHub = useSupportHub();

  const isMounted = useMountedState();
  const view = state.matches('custom')
    ? { text: "Let's try that again.", button: 'Try Again' }
    : state.matches('reload')
    ? { text: "Hmm, let's attempt a fresh reload.", button: 'Reload' }
    : state.matches('clearCache')
    ? {
        text: "Still stuck? Let's clear the cache for a fresh start.",
        button: 'Clear Cache',
      }
    : state.matches('logout')
    ? { text: "Let's try logging out and back in.", button: 'Logout' }
    : state.matches('help')
    ? {
        text: "Need assistance? We're here to help!",
        button: 'Contact Support',
      }
    : null;

  // Use SupportHub for authenticated users, ContactModal for guests
  const handleClick = state.matches('help')
    ? isAuthenticated
      ? supportHub.onOpen
      : helpDisclosure.onOpen
    : () => send('RECOVER');

  // Handle the "Strive Support" button click based on authentication status
  const handleSupportClick = isAuthenticated
    ? supportHub.onOpen
    : helpDisclosure.onOpen;

  return (
    <ErrorLayout title="Unknown Error">
      <Flex
        direction="column"
        width="min(100% - 2rem, 50ch)"
        mx="auto"
        pb="8"
        gap="4"
        suppressHydrationWarning
        height="full"
      >
        <Text color={'neutral.700'} opacity="0.3" fontSize="70px">
          Well...
        </Text>
        <Text textStyle="h1" mb="4">
          {title}
        </Text>
        {isMounted() && view ? (
          <Flex direction="column" my="auto" gap="6">
            <Text textStyle="copy">
              {view.text}
              {/* Only render ContactModal for unauthenticated users */}
              {!isAuthenticated && (
                <ContactModal
                  {...helpDisclosure}
                  isOpen={helpDisclosure.isOpen}
                  guestMode={true}
                />
              )}
            </Text>

            <Button variant="primary" onClick={handleClick}>
              {view.button}
            </Button>

            {state.can('RESET') ? (
              <Button
                variant="underline-link"
                color={'neutral.600'}
                onClick={() => send('RESET')}
              >
                Start Over
              </Button>
            ) : null}
          </Flex>
        ) : isMounted() ? (
          <LoadingOrError status="loading" />
        ) : null}
        <Flex justify="space-between" mt="auto">
          <ChakraLink href="/" textDecoration="underline">
            Strive Home
          </ChakraLink>
          <Button
            variant="link"
            textDecoration="underline"
            color="Background.500"
            onClick={handleSupportClick}
          >
            Strive Support
          </Button>
        </Flex>
      </Flex>
    </ErrorLayout>
  );
}
