import { useEffect } from 'react';
import {
  Button,
  Flex,
  Grid,
  Icon,
  IconButton,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

import { GeneralTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { BackLink, CloseIcon, useResponsive } from '@arena-labs/strive2-ui';

import { useSupportHub } from '../../../help-center/use-support-hub';
// import { ContactModal } from '../../../support/contact-modal';
import { useSetupActor } from './hooks';

export function SetupNoDevices({ onClose }: { onClose: () => void }) {
  const supportHub = useSupportHub();
  const setupActor = useSetupActor();

  // const helpDisclosure = useDisclosure();
  const analytics = useAnalytics();
  const rs = useResponsive();
  useEffect(() => {
    analytics.logEvent(
      GeneralTrackingEvent.UserInitiatedStrivewarePairingStats,
      {
        pairingFailedTime: new Date(),
        pairingSuccessTime: null,
        foundNoDevices: true,
      },
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Flex direction="column" gap="3" px="4" py="4" h="full">
      <Grid
        templateColumns="auto 1fr auto"
        alignItems="center"
        gap="4"
        w="full"
      >
        <BackLink
          href="/"
          color="neutral.800"
          onClick={(event) => {
            event.preventDefault();
            setupActor.send({ type: 'Back' });
          }}
        />
        <Text textStyle={'copy_bold'} textAlign={'center'}>
          Pair StriveWare
        </Text>
        <IconButton
          aria-label="Close"
          icon={<Icon as={CloseIcon} boxSize={6} />}
          onClick={() => onClose()}
        />
      </Grid>
      <Text textStyle={rs({ base: 'h1', xs: 'h3' })} color="neutral.800">
        Unable to locate your StriveWare
      </Text>

      <Text textStyle="copy" color="neutral.600">
        Still not pairing? Try this:
      </Text>
      <UnorderedList
        ml="8"
        textStyle={'copy_small'}
        spacing="2"
        color="neutral.600"
        mb={'auto'}
      >
        <ListItem>
          Try pairing with the ring on the plugged in charger.
        </ListItem>
        <ListItem>Turn off your phone bluetooth and turn it on again.</ListItem>
        <ListItem>
          Check if the ring has battery by showing the green light.
        </ListItem>
      </UnorderedList>
      <Button
        mt="auto"
        onClick={() => {
          supportHub.onOpen();
        }}
        variant="secondary"
      >
        Strive Support
      </Button>
      <Button
        onClick={() => {
          setupActor.send({ type: 'Start again' });
        }}
        variant="primary"
      >
        Try Again
      </Button>
    </Flex>
  );
}
