import { useState } from 'react';

import { BaseHelpSwiper } from '../../base-help-swiper';
import {
  DataCollectionContent,
  DataMissingContent,
  DataProtectionContent,
  SyncFrequencyContent,
  WhatShouldIDoContent,
  WhenToWearWhoopContent,
} from './whoop-help-content';
import { ContentType, WhoopHelpPage } from './whoop-help-page';

export function WhoopHelpSwiper({ onClose }: { onClose: () => void }) {
  const [selectedContent, setSelectedContent] =
    useState<ContentType>('whoopWhenToWear');

  const getDetailTitle = () => {
    switch (selectedContent) {
      case 'whoopWhenToWear':
        return 'When to wear';
      case 'whoopMissingData':
        return 'Missing data';
      case 'whoopGettingStarted':
        return 'Getting Started';
      case 'whoopSyncFrequency':
        return 'Syncing Data';
      case 'whoopDataCollection':
      case 'whoopDataProtection':
        return 'WHOOP Basics';
      default:
        return '';
    }
  };

  const getDetailContent = () => {
    switch (selectedContent) {
      case 'whoopWhenToWear':
        return <WhenToWearWhoopContent />;
      case 'whoopMissingData':
        return <DataMissingContent />;
      case 'whoopGettingStarted':
        return <WhatShouldIDoContent />;
      case 'whoopSyncFrequency':
        return <SyncFrequencyContent />;
      case 'whoopDataCollection':
        return <DataCollectionContent />;
      case 'whoopDataProtection':
        return <DataProtectionContent />;
      default:
        return null;
    }
  };

  const getArticleTitle = () => {
    switch (selectedContent) {
      case 'whoopWhenToWear':
        return 'WHOOP - When to Wear';
      case 'whoopMissingData':
        return 'WHOOP - Missing Data Troubleshooting';
      case 'whoopGettingStarted':
        return 'WHOOP - Getting Started Guide';
      case 'whoopSyncFrequency':
        return 'WHOOP - Data Syncing Guide';
      case 'whoopDataCollection':
        return 'WHOOP - Data Collection Information';
      case 'whoopDataProtection':
        return 'WHOOP - Data Protection Information';
      default:
        return '';
    }
  };

  return (
    <BaseHelpSwiper
      onClose={onClose}
      mainContent={
        <WhoopHelpPage
          onSelectContent={(content: ContentType) => {
            setSelectedContent(content);
          }}
        />
      }
      detailContent={getDetailContent()}
      detailTitle={getDetailTitle()}
      articleTitle={getArticleTitle()}
      showFeedback={true}
    />
  );
}
